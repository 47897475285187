import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";

import Auth from '../../utils/auth';
import { retrieveOffices, updateOffice, findOfficeByName, findByCompany, getByOfficeId } from "../../redux/actions/offices";
import datatableStyles from "../../utils/datatable-style";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../constants/Alert";
import { phoneNumber } from "../../utils/phonenumber";
import { retrieveCompanies } from "../../redux/actions/companies";
import OfficesService from "../../services/OfficeService";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logUserCompanyMismatch } from "../../utils/access";
import VideoPlayerModal from "../common/videoPlayerModal";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import TutorialService from "../../services/TutorialService";

const OfficeList = () => {
  const history = useHistory();

  useEffect(() => {
    dispatch(retrieveCompanies());
  }, []);

  const [show, setShow] = useState(false);

  const [currentVideo, setCurrentVideo] = useState(null)
  const tutorials = useSelector((state) => state?.tutorials) || null;
  const [officeList, setOfficeList] = useState([]);
  // const dataOffices = useSelector((state) => state.offices);
  const dataComapnies = useSelector((state) => state.companies.companies);
  const isLoading = useSelector((state) => state.loading.status);
  const sortedDataCompanies = dataComapnies.length > 0 && dataComapnies.sort((a, b) => (b.company_name > a.company_name ? -1 : 1))
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const loggedUser = Auth.getInstance().getUserData();

  //Make a state for search
  const [search, setSearch] = useState({
    limit: loggedUser.UserInRole?.UserRole?.name === "Company Admin" ? 100 : 10
  });

  //Store users data for listing
  const [dataOffices, setDataOffices] = useState([]);

  //Store count of total rows
  const [totalRows, setTotalRows] = useState(0)

  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount === 0) {
      dispatch(setLoadingStatus(false));
      setLoadCount(0);
    }
  }, [loadCount]);

  const fetchOfficeData = (search) => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    OfficesService.findByName(search).then((res) => {
      const dataOffices = res.data.data;
      for (let index = 0; index < dataOffices.length; index++) {
        const element = dataOffices[index];
        if (loggedUser.UserInRole?.UserRole?.name != "Boatdox Admin" && loggedUser.company_id != element.company_id) {
          logUserCompanyMismatch(element, 'Office List', history);
          break;
        }
      }
      const totalRecords = res.data.total;
      setTotalRows(totalRecords);
      setDataOffices(dataOffices)
      // dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    }).catch((error) => {
      dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
      console.log(error);
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    })
  }

  useEffect(() => {
    fetchOfficeData(search)
    // dispatch(findOfficeByName(search));
  }, [search]);

  const getOfficesByComapny = (company_id) => {
    if (company_id) {
      OfficesService.findByCompany(company_id)
        .then((res) => {
          let offices = res.data;
          offices.sort((a, b) => (b.office_name > a.office_name ? -1 : 1))
          setOfficeList(offices);
        })
        .catch((error) => {
          setOfficeList([]);
        })
      setSearch(search => {
        return {
          ...search,
          company_id: company_id
        }
      })
    } else {
      setSearch(search => {
        return {
          ...search,
          company_id: null,
        }
      })
    }
  }

  const getOfficeById = (office_id) => {
    if (office_id) {
      setSearch((state) => {
        return {
          ...state,
          office_id: office_id
        }
      })
    } else {
      setSearch((state) => {
        return {
          ...state,
          office_id: null
        }
      })
    }
  }

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(updateOffice(id, data))
              .then((response) => {
                toast.info("Delete successfully", {
                  autoClose: 2000,
                });
                fetchOfficeData(search)
              })
              .catch((e) => {
                toast.error("Error occurs, please try again.", {
                  autoClose: 2000
                })
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const columns = [
    ...(
      loggedUser.UserInRole.UserRole.name === 'Boatdox Admin'
        ? [{
          name: "Company Name",
          selector: "company_name",
          sortable: true,
          grow: 2,
          cell: row => (
            <div data-tag="allowRowEvents">
              <div>
                <Link to={{ pathname: `/companies/view`, state: { id: row.o_company?.id } }}>{row.o_company?.company_name}</Link>
              </div>
            </div>
          )
        }]
        : []
    ),
    {
      name: "Office Name",
      selector: "office_name",
      sortable: true,
      minWidth: "200px",
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>{row.office_name}</Link>
          </div>
        </div>
      ),
    },
    {
      name: "Employing Broker",
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row.employing_broker &&
              `${row.employing_broker.first_name} ${row.employing_broker.last_name}` || "N/A"
            }
          </div>
        </div>
      ),
    },
    { name: "Country", selector: "country", grow: 2, sortable: true },
    {
      name: "Office Phone",
      selector: "office_phone",
      sortable: true,
      grow: 2,
      cell: row => (phoneNumber(row.office_phone))
    },
    { name: "Timezone", selector: "timezone", grow: 2, sortable: true },
    {
      name: "",
      cell: (row) => (
        <div>
          <Link to={{ pathname: `${path}/edit`, state: { id: row.id } }}>
            <Tooltip title="Edit Office" placement="left">
              <img src="/icons/edit.svg" className="svg" alt="" />
            </Tooltip>
          </Link>
          <Tooltip title="Delete Office" placement="right">
            <img src="/icons/delete.svg" className="svg" onClick={() => onDelete(row.id)} alt="" />
          </Tooltip>
        </div>
      ),
    },
  ];

  //Function for handle row limit
  const handleRowsPerPage = (numberOfRows, page) => {
    setSearch((search) => {
      return {
        ...search,
        limit: numberOfRows,
        page: page
      }
    })
  }

  //Function for handle page no
  const handlePageChange = page => {
    setSearch((search) => {
      return {
        ...search,
        page: page
      }
    })
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    })
  };

  useEffect(() => {
    setCurrentVideo(tutorials?.offices);
  }, [tutorials]);

  const onCloseVideoPlayerModal = () => {
    setShow(() => false);
  }

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1 className="d-flex align-items-center gap-1">
          {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
            <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
          </Tooltip>}Offices</h1>

        <div className={`${loggedUser.UserInRole.UserRole.name == "Boatdox Admin" ? "uk-column-1-4  uk-margin-small" : "uk-column-1-2  uk-margin-small "} d-flex justify-content-between`}>
          <Link to={`${path}/add`}>
            <div className="uk-button uk-button-default add-deal ">
              <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span>{"   "}  Add
            </div>
          </Link>
          <div className=" d-flex flex-wrap">
            {
              loggedUser.UserInRole.UserRole.name == "Boatdox Admin" &&
              <div className="d-flex flex-wrap">
                <div className="max-w-100 m-1 sm-full-width">
                  <select className="uk-select"
                    defaultValue=""
                    onChange={(event) => {
                      getOfficesByComapny(event.target.value)
                      // setAddressFields(event.target.value);
                    }}
                  >
                    <option selected value="">Select Company</option>
                    {sortedDataCompanies.length > 0 && sortedDataCompanies.map((company) => {
                      return (
                        <option value={company.id} key={company.id}>
                          {`${company.company_name}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="max-w-100 m-1 sm-full-width">
                  <select className="uk-select w-100"
                    defaultValue=""
                    onChange={(event) => {
                      getOfficeById(event.target.value)
                      // setAddressFields(event.target.value);
                    }}
                  >
                    <option selected value="">Select Office</option>
                    {officeList.length > 0 && officeList.map((office) => {
                      return (
                        <option value={office.id} key={office.id}>
                          {`${office.office_name}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            }
            <div className="list-page-search-container m-1 sm-full-width">
              <form className="uk-search uk-search-default" style={{ width: "400px" }}>
                <DebounceInput
                  className="uk-search-input"
                  type="search"
                  placeholder="Search"
                  onChange={(e) => setSearch(state => {
                    return {
                      ...state,
                      name: e.target.value
                    }
                  })}
                  value={search ? search?.name : null}
                  debounceTimeout={500}
                  minLength={1}
                  style={{ width: "-webkit-fill-available" }}
                />
                {!search.name || search.name === "" ? (
                  <span className="bd-form-icon">
                    <i className="fa fa-search"></i>
                  </span>
                ) : (
                  <span className="bd-form-icon" onClick={() => setSearch(state => {
                    return {
                      ...state,
                      name: ""
                    }
                  })}>
                    <i className="fa fa-times"></i>
                  </span>
                )}
              </form>
            </div>
          </div>
        </div>

        {/* Offices List Table */}

        {!dataOffices.length && isLoading
          ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
          : <DataTable
            title="Offices Table"
            columns={columns}
            data={dataOffices}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            noHeader={true}
            fixedHeader={true}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataOffices?.length}
            paginationRowsPerPageOptions={loggedUser.UserInRole?.UserRole?.name === "Company Admin" ? [100] : [10, 20, 50, 100]}
            onChangeRowsPerPage={handleRowsPerPage}
            onChangePage={handlePageChange}
            onSort={handleSort}
            sortServer
            persistTableHead
            customStyles={datatableStyles}
          />
        }
      </div>
      {show && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </div>
  );
};

export default OfficeList;
