import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "react-moment";
import { getPrice } from "../../../../utils/currency";
import { addVesselImage, getAllTransactions, updateTransaction } from "../../../../redux/actions/transaction";
import SoldDateModel from "../../../common/SoldDateModel";
import UploadVesselImage from "../../../common/UploadVesselImage";
import { setLoadingStatus } from "../../../../redux/actions/loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import ChangeBrokerModal from "../../../common/ChangeBrokerModal";
import ChangeTransactionStatusModal from "../../../common/ChangeTransactionStatusModal";
import { CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN, LLC_OWNED, OWNER_OF_RECORD, TRUST_OWNED } from "../../../../utils/signer";
import { isParticipant } from "../../../../utils/permission";
import { useRouteMatch } from "react-router-dom";
import Auth from "../../../../utils/auth";

const ListItem = ({ deal, onDelete, user, onRefreshList, menuType }) => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const dataDeal = deal;
  const dataOffer = dataDeal.t_offers;
  const dataVessel = dataDeal.t_vessel;
  const dataBuyer = dataDeal.buyer;
  const dataBroker = dataDeal.broker;
  const dataSeller = dataDeal.seller;
  const dataTasks = dataDeal.t_task_master || [];
  const edocs = user?.UserInRole?.UserRole?.name == 'Participant'
    ? dataDeal.transaction_edoc?.filter((doc) => doc != null)
    : dataDeal.t_edocuments?.filter((doc) => doc != null);
  const coBroker = dataDeal.co_broker;

  const getFilteredDataTasks = (data, filter) => {
    return data?.filter((task) => {
      if (task.status !== filter) { return false };
      if (task.company_tasks?.create_type === 'global' && dataDeal.applied_filter_tag_id) {
        return !!task.tags?.find(tag => tag?.id == dataDeal.applied_filter_tag_id);
      } else {
        return true
      }
    });
  }

  const logged_user = Auth.getInstance().getUserData();

  const uploads = dataTasks?.filter((upload) => upload.task_type === "document");
  const uploadsNumCompleted = getFilteredDataTasks(uploads, 'received')?.length || 0;
  const uploadsNumNotStarted = getFilteredDataTasks(uploads, 'open')?.length || 0;
  const uploadsNumInProcess = getFilteredDataTasks(uploads, 'required')?.length || 0;

  const tasks = dataTasks?.filter((upload) => upload.task_type === "task");
  const taskNumCompleted = getFilteredDataTasks(tasks, 'completed')?.length || 0;
  const taskNumNotStarted = getFilteredDataTasks(tasks, 'not_started')?.length || 0;
  const taskNumInProcess = getFilteredDataTasks(tasks, 'in_process').length || 0;

  const totalEdocuments = edocs?.length || 0;
  const eDocNumCompleted = edocs?.filter((doc) => doc === "Completed")?.length || 0;

  const titles = {
    draft: "DRAFT",
    progress: "IN-PROCESS",
    cancelled: "CANCELLED",
    active: "ACTIVE",
    completed: "SOLD",
    rejected: "REJECTED",
    archived: "ARCHIVED"
  };

  const vesselName = `${dataVessel?.year || ""} ${dataVessel?.make || ""} ${dataVessel?.model || ""
    } ${dataVessel?.length || ""}`;
  const brokerName =
    `${dataBroker?.first_name || ""} ${dataBroker?.last_name || ""}` !== " "
      ? `${dataBroker?.first_name || ""} ${dataBroker?.last_name || ""}`
      : "N/A";
  const brokerCompanyName = `${dataBroker?.company_name || "N/A"}`;
  // const buyerName =
  // `${dataBuyer?.first_name || ""} ${dataBuyer?.last_name || ""}` !== " "
  //   ? [ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].includes(dataDeal.buyer_type)
  //     ? `${dataBuyer?.trust_signer_name || "N/A"}`
  //     : `${dataBuyer?.first_name || ""} ${dataBuyer?.last_name || ""}`
  //   : "N/A";

  const buyerName = [ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].includes(dataDeal.buyer_type)
    ? `${dataBuyer?.trust_signer_name || "N/A"}`
    : (`${dataBuyer?.first_name || ""} ${dataBuyer?.last_name || ""}` !== " ")
      ? `${dataBuyer?.first_name || ""} ${dataBuyer?.last_name || ""}`
      : "N/A";

  const sellerName =
    dataDeal.seller_type === OWNER_OF_RECORD ? "Owner Of Record" :
      `${dataSeller?.first_name || ""} ${dataSeller?.last_name || ""}` !== " "
        ? [TRUST_OWNED, CORPORATE_OWNED, LLC_OWNED].includes(dataDeal.seller_type)
          ? `${dataSeller?.first_name || ""}`
          : `${dataSeller?.first_name || ""} ${dataSeller?.last_name || ""}`
        : "N/A";

  const association = dataDeal?.association?.toUpperCase();
  const purchasePrice = `${dataOffer?.purchase_price ? getPrice(dataOffer?.purchase_price) : "N/A"
    }`;

  const [isOpen, setIsOpen] = useState(false);

  const [show, setShow] = useState(false);
  const [imageTransaction, setImageTransaction] = useState(null);
  const [showBrokerModal, setShowBrokerModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleImageUpload = (transaction) => {
    if (transaction) {
      setImageTransaction(transaction);
      setShow(true);
    }
  }

  const handleCloseModal = (isLoad = false) => {
    setShow(false);
    setImageTransaction(null);
    if (isLoad) {
      onRefreshList();
    }
  }

  const handleCloseBrokerModal = (isLoad = false) => {
    setShowBrokerModal(false);
    if (isLoad) {
      onRefreshList();
    }
  }

  const handleRemoveVesselImage = (transactionId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove vessel image.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          dispatch(addVesselImage(transactionId, { imageUrl: null })).then(
            response => {
              dispatch(setLoadingStatus(false));
              toast.success('Image removed successfully!');
              onRefreshList();
            }
          ).catch(error => {
            toast.error('Could not remove image!');
            dispatch(setLoadingStatus(false));
          })
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.error(error)
        }
      }
    })
  }

  const handleTransactionStatusChange = (status) => {
    if (status !== "completed" || logged_user.UserInRole?.UserRole?.name == 'Boatdox Admin') {
      setShowChangeStatusModal(true);
    }
  }

  const handleTransactionStatusChangeModalClose = (doRefresh = false) => {
    setShowChangeStatusModal(false);
    if (doRefresh) {
      onRefreshList();
    }
  }

  return (
    <>
      <tr>
        <td className={`stylus-td-${dataDeal.status || "draft"}`} onClick={() => handleTransactionStatusChange(dataDeal.status || "draft")}>
          <span className="primary">
            <span className="status-rotate">{titles[dataDeal?.status || "draft"]}</span>
          </span>
        </td>
        <td>
          <div className="uk-column-1-1">
            <span className="uk-text-emphasis title">
              {vesselName !== "   " ? vesselName : "N/A"}
            </span>
            <br />
            <i className="uk-text-emphasis vessel_title">
              {dataVessel?.vessel_name === "   " ? "" : dataVessel?.vessel_name}
            </i>
            <br />
            <div className="uk-column-2-2">
              <div className="uk-padding-small">
                <label className="uk-form-label uk-text-muted">Purchase Price</label>
                <div className="uk-text-emphasis">{purchasePrice}</div>
              </div>
            </div>
            <div className="uk-column-2-2">
              <div className="uk-padding-small">
                <label className="uk-form-label uk-text-muted">Offer Date: {dataDeal?.t_offers?.offer_date ? <Moment date={dataDeal?.t_offers?.offer_date} format="MM/DD/YYYY" /> : "N/A"}</label>
                <label className="uk-form-label uk-text-muted">Accept/Reject Date: {dataDeal?.t_offers?.accept_reject_date ? <Moment date={dataDeal?.t_offers?.accept_reject_date} format="MM/DD/YYYY" /> : "N/A"}</label>
                <label className="uk-form-label uk-text-muted">Closing Date: {dataDeal?.t_offers?.closing_date ? <Moment date={dataDeal?.t_offers?.closing_date} format="MM/DD/YYYY" /> : "N/A"}</label>
                {dataDeal?.status == 'completed' && <label className="uk-form-label uk-text-muted">Date Sold: {dataDeal?.sold_date ? <Moment date={dataDeal?.sold_date} format="MM/DD/YYYY" /> : "N/A"}</label>}
                {/* <span className="uk-text-emphasis">
                  <Moment date={dataDeal?.t_offers?.offer_date || "N/A"} format="MM/DD/YYYY" />
                </span> */}
                {/* <br /> */}
                {/* <span className="uk-text-emphasis">
                  (
                  <Moment fromNow ago date={dataDeal?.t_offers?.offer_date} />)
                </span> */}
              </div>
            </div>
            <div className="uk-column-1-2">
              <div className="uk-padding-small">
                <span className="uk-text-small uk-text-muted label"></span>
              </div>
              <div className="uk-padding-small"></div>
            </div>
          </div>
        </td>
        <td>
          {/*
        <img className="broker-img" src="/photos/1.png" />
         */}
          <div className="broker-img-right">
            {menuType === "invitation"
              ? <>
                <label className="uk-form-label uk-text-muted">Selling Broker</label>
                <span className="uk-text-emphasis">{coBroker?.selling_brokerage_broker || "N/A"}</span>
                <br />
                <br />
                <label className="uk-form-label uk-text-muted">Listing Broker</label>
                <span className="uk-text-emphasis">{coBroker?.listing_brokerage_broker || "N/A"}</span></>
              : <>
                <label className="uk-form-label uk-text-muted">Broker</label>
                {user?.UserInRole?.UserRole?.name == 'Company Admin'
                  ? <Tooltip title="Update Broker" placement="right">
                    <a onClick={() => setShowBrokerModal(true)}>
                      <span className="uk-text-emphasis text-primary">{brokerName}</span>
                    </a>
                  </Tooltip>
                  : isParticipant()
                    ? <span className="uk-text-emphasis">{`${brokerName} - ${brokerCompanyName}`}</span>
                    : <span className="uk-text-emphasis">{brokerName}</span>
                }
                {deal.employing_broker?.id != null &&
                  <>
                    <label className="uk-form-label uk-text-muted pt-2">Employing Broker</label>
                    <span className="uk-text-emphasis">{`${deal.employing_broker?.first_name} ${deal.employing_broker?.last_name}`}</span>
                  </>
                }
                <br />
                <br />
                <label className="uk-form-label uk-text-muted">Buyer</label>
                <span className="uk-text-emphasis">{buyerName}</span>
                <br />
                <br />
                <label className="uk-form-label uk-text-muted">Seller</label>
                <span className="uk-text-emphasis">{sellerName}</span></>}
          </div>
        </td>
        {/* <td>
        <span className="uk-text-small uk-text-muted label-full">Up Next</span>
        <br />
        <span className="uk-text-emphasis">
          {dataDeal.status === "draft" && nextEdocs.length === 0
            ? "Purchase & Sale Agreement"
            : nextEdocs.length > 0
            ? nextEdocs[0].title
            : null}
        </span>
        <br />
        <br />
        <label className="uk-text-small uk-text-muted label-full">Last Completed</label>
        <br />
        <span className="uk-text-emphasis">
          {completedEdocs.length > 0 ? completedEdocs[0].title : null}
        </span>
        <br />
      </td> */}
        {menuType !== "invitation" ? <>
          {/* Uploads */}
          <td>
            <label className="uk-form-label uk-text-muted">Open :  </label>
            <span className="uk-text-emphasis">{isFinite(uploadsNumNotStarted) ? uploadsNumNotStarted : "N/A"}</span>
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">Required : </label>
            <span className="uk-text-emphasis">{isFinite(uploadsNumInProcess) ? uploadsNumInProcess : "N/A"}</span>
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">Received : </label>
            <span className="uk-text-emphasis">{isFinite(uploadsNumCompleted) ? uploadsNumCompleted : "N/A"}</span>
          </td>

          {/* Tasks */}
          <td>
            <label className="uk-form-label uk-text-muted">Not Started :  </label>
            <span className="uk-text-emphasis">{isFinite(taskNumNotStarted) ? taskNumNotStarted : "N/A"}</span>
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">In Process : </label>
            <span className="uk-text-emphasis">{isFinite(taskNumInProcess) ? taskNumInProcess : "N/A"}</span>
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">Completed : </label>
            <span className="uk-text-emphasis">{isFinite(taskNumCompleted) ? taskNumCompleted : "N/A"}</span>
          </td>

          {/* EDocuments */}
          <td>
            <label className="uk-form-label uk-text-muted">Total :  </label>
            <span className="uk-text-emphasis">{isFinite(edocs?.length) ? edocs?.length : "N/A"}</span>
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">Completed : </label>
            <span className="uk-text-emphasis">{isFinite(eDocNumCompleted) ? eDocNumCompleted : "N/A"}</span>
          </td></> : <></>}


        <td className="actions" style={{ maxWidth: "300px" }}>
          <div style={{ width: "100px", display: '-webkit-box', flexDirection: 'row' }}>
            <div className="uk-text-emphasis"> <span className="uk-text-muted">UID:</span> <span style={{ color: '#004D89' }}>PS-{dataDeal.unique_transaction_id}</span></div>
          </div>
          <div style={{ display: '-webkit-box', flexDirection: 'row' }}>
            <div className="uk-text-emphasis"> <span className="uk-text-muted">CREATED:</span> <span style={{ color: '#004D89' }}><Moment date={dataDeal.createdAt || "N/A"} format="MM/DD/YYYY" /></span></div>
          </div>
          <div className="mb-4" style={{ display: '-webkit-box', flexDirection: 'row' }}>
            <div className="uk-text-emphasis"> <span className="uk-text-muted">UPDATED:</span> <span style={{ color: '#004D89' }}><Moment date={dataDeal.updatedAt || "N/A"} format="MM/DD/YYYY" /></span></div>
          </div>
          <div style={{ width: "100px", display: '-webkit-box', flexDirection: 'row' }}>
            {<Link
              to={`${path}/view/${dataDeal.id}/main`}
              style={{ display: "block", marginBottom: "20px" }}
            >
              <img src="/icons/edit.svg" className="svg" />
            </Link>}
            {/* <div className="uk-inline">
              <button className="uk-button uk-button-default list-status" style={{ marginRight: 0 }} type="button"></button>
              <div uk-dropdown="pos: bottom-right;" style={{ marginTop: 0, padding: 5 }}>
                <ul className="uk-list uk-list-divider">
                  <li className="" onClick={() => changeStatus("progress")}>
                    <a className="">In-Process</a>
                  </li>
                  <li className="" onClick={() => changeStatus("active")}>
                    <a className="">Active</a>
                  </li>
                  <li className="" >
                    <a className="" onClick={handleOpen}>Sold</a>
                    <SoldDateModel type="deal" data={dataDeal} isOpen={isOpen} onClose={handleClose} componentsId={dataDeal.id} user={user} search={search} />
                  </li>
                  <li className="" onClick={() => changeStatus("cancelled")}>
                    <a className="">Cancelled</a>
                  </li>
                  <li className="" onClick={() => changeStatus("rejected")}>
                    <a className="">Rejected</a>
                  </li>
                  <li className="" onClick={() => changeStatus("archived")}>
                    <a className="">Archived</a>
                  </li>
                </ul>
              </div>
            </div> */}
            {
              (user && ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) && menuType !== "invitation" &&
              <a onClick={() => onDelete(dataDeal.id)} style={{ display: "block", marginBottom: "20px" }}>
                <img src="/icons/delete.svg" className="svg" style={{ marginLeft: "3px" }} />
              </a>
            }
          </div>
          {menuType !== "invitation" && <div className="d-flex justify-content-start">
            {dataDeal.image_url
              ?
              <div className="transaction-vessel-img">
                <img className={`stylus-border-${dataDeal.status || "draft"}`} src={`https://${process.env.REACT_APP_UPLOADS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}vessel-image/${dataDeal.image_url}`} alt="Vessel Img" height={'100px'} width={'100px'} />
                <a className="edit-vessel-img" onClick={() => handleImageUpload(dataDeal)}>
                  <i style={{ marginRight: '12px', color: '#fff' }} className="vessel-img-button fa fa-edit fa-lg"></i>
                </a>
                <a className="delete-vessel-img" onClick={() => handleRemoveVesselImage(dataDeal.id)}>
                  <i style={{ marginLeft: '12px', color: '#fff' }} className="vessel-img-button fa fa-trash fa-lg" ></i>
                </a>
              </div>
              : <div className="pt-4"><button className="uk-button uk-button-primary" type="button" onClick={() => handleImageUpload(dataDeal)}>Add Image</button></div>
            }
          </div>}
        </td>
      </tr>
      {show && <UploadVesselImage show={show} onClose={handleCloseModal} transaction={imageTransaction} imgUrl={dataDeal.image_url} uniqueId={imageTransaction?.unique_transaction_id && `PS-${imageTransaction.unique_transaction_id?.split('-')[1] || ""}`} companyUId={imageTransaction?.unique_transaction_id && `${imageTransaction.unique_transaction_id?.split('-')[0] || ""}`} />}
      <ChangeBrokerModal show={showBrokerModal} broker={dataBroker} user={user} transactionId={dataDeal.id} onClose={handleCloseBrokerModal} coBroker={coBroker} />
      <ChangeTransactionStatusModal show={showChangeStatusModal} transaction={dataDeal} onClose={handleTransactionStatusChangeModalClose} />
    </>
  );
};

export default ListItem;
