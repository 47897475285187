import { createTheme, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import EDocService from "../../services/EDocService";
import TransactionService from "../../services/TransactionService";
import Auth from "../../utils/auth";
import PDfEditorContainer from "./PDFContainer";
import "./index.css";
import { uploadPdf } from "../../utils/s3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDataSideBarListing from '../listing_agreement/View/layouts/FormDataSideBar';
import FormDataSideBarDeal from '../deals/View/layouts/FormDataSideBar';
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import { PDFDocument, PDFName, createPDFAcroFields, setFontAndSize, rgb } from "pdf-lib";
import { getPdfPageCountFromUrl } from "../../utils/functions";
import { getVesselOrToy } from "../../redux/actions/vessels_toys";
import { upsertTransaction } from "../../redux/actions/transaction";
import { getPlainTransactionData } from "../../utils/stepFilter";
import { getTransactionClient } from "../../redux/actions/clients";
import moment from "moment";
import { getFigure, getPrice } from "../../utils/currency";
import { getTotals } from "../deals/View/layouts/forms/panel_seller_closing_statement/TabFormTransactionDetails";
import { removeAllPdfEditorField, setPdfEditorField, updatePdfEditorField } from "../../redux/actions/pdfEditor";
import { priceFields } from "../../utils/AppConstants";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  pdf_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    position: "relative",
    height: "700px",
    overflow: "scroll",
  },
});

// style for section of add signature/initials/date 
const style = {
  border: '1px solid gray',
  backgroundColor: 'white',
  padding: '3px 10px',
  color: '#212529 !important',
  marginBottom: '0.5rem',
  cursor: 'move',
  float: 'left',
  width: '100%',
  opacity: 'transparent'
};

// theme for section of add signature/initials/date 
const theme = createTheme({
  components: {
    // Name of the component
    MuiSelect: {
      styleOverrides: {
        // Name of the slot
        select: {
          // Some CSS
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
    },
  },
});
const getPdfEditorFields = (obj, prefix) => {
  if (prefix) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [`${prefix}_${key}`, value])
    );
  }
}


const EditCustomEditor = (props) => {
  const formData = useRef([]);
  const updatedValuesRef = useRef({});
  const updatedPdfUrl = useRef({});
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const propData = props.location.state;

  const [editorPdf, setEditorPdf] = useState(propData.file_url ?? "");
  const [timeStamp, setTimeStamp] = useState(new Date().getTime() + Math.random(10));
  const [refresh, setRefresh] = useState(false);
  // for merge pdf
  const [mergedPdfUrl, setMergedPdfUrl] = useState(propData?.is_pdf_merge ? propData?.file_url : null);

  // state for manage files for merge
  const [files, setFiles] = useState([]);

  // state for manage confirmation of merge and handle files dependancy
  const [isConfirmForMerge, setIsConfirmForMerge] = useState(false);

  // ref of left side side bar of forms and draggable fields
  let leftSideBarRef = useRef({});

  // ref of added pdf for merge 
  let mergeRef = useRef();

  // state to manage manually added text fields
  const [textFields, setTextFields] = useState([]);

  // state to manage manually added checkbox fields
  const [checkboxFields, setCheckboxFields] = useState([]);
  const [logoFields, setLogoFields] = useState([]);

  // state to manage manually added signature/initials/date fields
  const [signatureFields, setSignatureFields] = useState([]);

  // state to manage assigner of signature/initial/date
  const [signatureAssigner, setSignatureAssigner] = useState("");

  // state to manage roles for manually adding signature/initials/date
  const [signatureRoles, setSignatureRoles] = useState([]);

  // for main template page count
  const [templatePageCount, setTemplatePageCount] = useState(null);
  // const [templatePageCount, setTemplatePageCount] = useState(0);

  // Variable for storing current transaction
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  //Store current transaction
  const dataDeal = dataTransaction || {};

  //Store current transaction dataTerms
  const dataTerms = dataDeal?.t_terms || {};

  //Variable for storing current buyer of transaction
  const dataClient = useSelector((state) => state.clients.client);

  // Variable for storing current trade in vessel of transaction
  const dataTradeInVessel = dataDeal?.t_trade_in_vessel || {};

  // Variable for storing current offer of transaction
  const dataOffer = dataDeal.t_offers || {};

  // Local state for buyer data
  const [buyerClient, setBuyerClient] = useState(null);

  // Local state for seller data
  const [sellerClient, setSellerClient] = useState(null);

  // Local state for coBuyer data
  const [coBuyerClient, setCoBuyerClient] = useState(null);

  // Local state for coSeller data
  const [coSellerClient, setCoSellerClient] = useState(null);

  //Variable for storing current exclusions of transaction
  const dataExclusions = dataDeal.t_exclusion || [];

  // Variable for storing current broker of transaction
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

  // Variable for storing current co broker of transaction
  const dataCoBroker = dataDeal?.co_broker || {};

  // Variable for store the current value of vessel
  const dataVessel = useSelector((state) => state.vessel_toys.current);

  // store dragged form fields
  const [formFields, setFormFields] = useState([]);

  // store dragged and updated form fields
  const [updatedFormFields, setUpdatedFormFields] = useState([]);

  // object for track changes - used in undo redo functionality
  const [fieldTrackObj, setFieldTrackObj] = useState({});

  // array for track changes - used in undo redo functionality
  const [fieldTrackArr, setFieldTrackArr] = useState([]);

  // This key will help force re-render
  const [reloadKey, setReloadKey] = useState(0);

  // state for handle edit and dragging mode of individual form fields
  const [formFieldsEdit, setFormFieldsEdit] = useState({ is_vessel_edit: false, is_buyer_edit: false, is_cobuyer_edit: false, is_seller_edit: false, is_coseller_edit: false, is_offer_edit: false, is_trade_in_vessel_edit: false, is_cobroker_edit: false, is_terms_edit: false })

  // manage vessel fields for dragging
  const [vesselFields, setVesselFields] = useState([]);

  // manage buyer fields for dragging
  const [buyerFields, setBuyerFields] = useState([]);

  // manage coBuyer fields for dragging
  const [coBuyerFields, setCoBuyerFields] = useState([]);

  // manage seller fields for dragging
  const [sellerFields, setSellerFields] = useState([]);

  // manage coBuyer fields for dragging
  const [coSellerFields, setCoSellerFields] = useState([]);

  // manage trade in vessel fields for dragging
  const [tradeInVesselFields, setTradeInVesselFields] = useState([]);

  // manage offer fields for dragging
  const [offerFields, setOfferFields] = useState([]);

  // manage exclusion fields for dragging
  const [exclusionFields, setExclusionFields] = useState([]);

  // manage term fields for dragging
  const [termFields, setTermFields] = useState([]);

  // manage coBroker fields for dragging
  const [coBrokerFields, setCoBrokerFields] = useState([[], []]);

  // ybaa amendment to psa
  const dataAddendum = dataDeal.t_purchase_addendum || [];
  const [ybaaAmendmentField, setYbaaAmmendmentField] = useState([])
  const ybaaAmmendment = dataAddendum?.find(el => el.t_edocument_id === propData?.documentId);

  // acceptance of vessel
  const [acceptanceOfVesselField, setAcceptanceOfVesselField] = useState([])

  // purchase and sales agreement exclusion
  const [agreementExclusion, setAgreementExclusion] = useState([])

  // purchase and sales agreement ammendmunt
  const [agreementAmmendmuntField, setAgreementAmmendmuntField] = useState([])
  const agreementAmmendmunt = dataAddendum?.find(el => el.t_edocument_id === propData?.documentId);

  // Buyer closing 
  const [buyerClosing, setBuyerClosing] = useState([])
  const dataBuyerClosing = dataDeal.t_buyer_closing;

  const pdfEditorFields = useSelector((state) => state?.pdfEditorReducer);

  // seller closing 
  const [sellerClosing, setSellerClosing] = useState([])
  const dataSellerClosing = dataDeal.t_seller_closing || {};
  const dataSellerPayoff = dataDeal.t_seller_payoff || {};
  const dataDetails = dataDeal.t_details || [];
  const dataCobroker = dataDeal.co_broker || {};
  const dataVesselAcceptance = dataDeal.t_acceptance || [];

  // store modal position which opens
  const [modalPosition, setModalPosition] = useState({ x: null, y: null });
  //state to identify save button clicked or not
  const [modalLoading, setModalLoading] = useState(false);

  // state for store template's all fields
  const [templateFields, setTemplateFields] = useState(propData?.form_fields || []);

  // state for store all forms fields
  // const [allFormFields, setAllFormFields] = useState({});

  // resetModalPosition
  const resetModal = () => setModalPosition({ x: 0, y: 0, id: null });

  // for refresh/reload screen and get pdf url with new edited values
  useEffect(() => {
    const handlePageLoad = () => {
      // Use the PerformancteNavigationTiming API to detect if the page was reloaded
      const [navigationEntry] = performance.getEntriesByType('navigation');

      // Check if the page was reloaded manually
      if (navigationEntry?.type === 'reload') {
        // Page was manually refreshed, call refresh function here
        refreshContent(true);        // for reload whole doc 
      }
    };
    // Call the function on page load
    handlePageLoad();
  }, []);

  useEffect(() => {
    if (dataDeal?.transaction_vessel_id) {
      dispatch(getVesselOrToy(dataDeal?.transaction_vessel_id));
    }
    if (dataDeal?.buyer_contact_id) {
      dispatch(getTransactionClient(dataDeal?.buyer_contact_id));
    }
    if (dataDeal?.co_buyer_contact_id) {
      dispatch(getTransactionClient(dataDeal?.co_buyer_contact_id));
    }
    if (dataDeal?.seller_contact_id) {
      dispatch(getTransactionClient(dataDeal?.seller_contact_id));
    }
    if (dataDeal?.co_seller_contact_id) {
      dispatch(getTransactionClient(dataDeal?.co_seller_contact_id));
    }
  }, [dataTransaction, dispatch, refresh]);

  // UseEffect to handle assigning buyer, co-buyer, seller and co-seller data
  useEffect(() => {
    if (dataDeal?.buyer_contact_id && dataClient?.id === dataDeal?.buyer_contact_id) {
      setBuyerClient(dataClient); // Assign buyer client locally
    }
    if (dataDeal?.co_buyer_contact_id && dataClient?.id === dataDeal?.co_buyer_contact_id) {
      setCoBuyerClient(dataClient); // Assign co-buyer client locally
    }
    if (dataDeal?.seller_contact_id && dataClient?.id === dataDeal?.seller_contact_id) {
      setSellerClient(dataClient); // Assign buyer client locally
    }
    if (dataDeal?.co_seller_contact_id && dataClient?.id === dataDeal?.co_seller_contact_id) {
      setCoSellerClient(dataClient); // Assign co-buyer client locally
    }
  }, [dataClient, dataDeal, refresh]);

  // calling for new pdf url with updated fields
  // useEffect(async () => {
  //   const docResponse = await EDocService.getEditorDocumentPdf(propData?.documentId)?.data;
  //   if (docResponse?.pdf_url) {
  //     setEditorPdf(docResponse.pdf_url);
  //   }
  // }, [refresh])

  // for make vessel fields updated
  useEffect(() => {
    if (dataVessel) {

      dispatch(setPdfEditorField(getPdfEditorFields(dataVessel, "vessel")));
      setVesselFields(() =>
      (
        [
          { fieldId: uuidv4(), fieldName: "HIN *", fieldValue: pdfEditorFields?.vessel_hull_number ?? dataVessel?.hull_number },
          { fieldId: uuidv4(), fieldName: "Manufacturer/Builder *", fieldValue: pdfEditorFields?.vessel_make ?? dataVessel?.make },
          { fieldId: uuidv4(), fieldName: "Stock", fieldValue: pdfEditorFields?.vessel_stock_number ?? dataVessel?.stock_number },
          { fieldId: uuidv4(), fieldName: "Year *", fieldValue: pdfEditorFields?.vessel_year ?? dataVessel?.year },
          { fieldId: uuidv4(), fieldName: "Model", fieldValue: pdfEditorFields?.vessel_model ?? dataVessel?.model },
          { fieldId: uuidv4(), fieldName: "Length *", fieldValue: `${pdfEditorFields?.vessel_length ?? dataVessel?.length}` },
          { fieldId: uuidv4(), fieldName: "Name", fieldValue: pdfEditorFields?.vessel_vessel_name ?? dataVessel?.vessel_name },
          // { fieldId: uuidv4(), fieldName: "Gross Price", fieldValue: `${dataDeal?.t_offers?.total_purchase_price}` },
          { fieldId: uuidv4(), fieldName: "Category", fieldValue: pdfEditorFields?.vessel_boat_category ?? dataVessel?.boat_category },
          { fieldId: uuidv4(), fieldName: "USCG/Official/Doc#", fieldValue: pdfEditorFields?.vessel_uscg ?? dataVessel?.uscg ?? pdfEditorFields?.vessel_doc_no ?? dataVessel?.doc_no },
          { fieldId: uuidv4(), fieldName: "Reg No", fieldValue: pdfEditorFields?.vessel_reg_no ?? dataVessel?.reg_no },
          { fieldId: uuidv4(), fieldName: "Title No", fieldValue: pdfEditorFields?.vessel_title_no ?? dataVessel?.title_no },
        ]
      )
      )
    }

    if (buyerClient) {

      dispatch(setPdfEditorField(getPdfEditorFields(buyerClient ?? {}, "buyer")));

      setBuyerFields(() => {
        if (dataDeal?.buyer_type === "LEGAL_OWNER" || dataDeal?.buyer_type === "LEGAL_AND_OR_ASSIGN") {
          return [
            { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.buyer_first_name ?? buyerClient?.first_name },
            { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.buyer_middle_name ?? buyerClient?.middle_name },
            { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.buyer_last_name ?? buyerClient?.last_name },
            { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.buyer_email ?? buyerClient?.email },
            { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.buyer_mobile_phone ?? buyerClient?.mobile_phone },
            { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.buyer_nationality ?? buyerClient?.nationality },
            { fieldId: uuidv4(), fieldName: "Address *", fieldValue: pdfEditorFields?.buyer_address_1 ?? buyerClient?.address_1 },
            { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.buyer_address_2 ?? buyerClient?.address_2 },
            { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.buyer_address_3 ?? buyerClient?.address_3 },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.buyer_country ?? buyerClient?.country },
            { fieldId: uuidv4(), fieldName: "City *", fieldValue: pdfEditorFields?.buyer_city ?? buyerClient?.city },
            { fieldId: uuidv4(), fieldName: "State *", fieldValue: pdfEditorFields?.buyer_state ?? buyerClient?.state },
            { fieldId: uuidv4(), fieldName: "Postal Code *", fieldValue: pdfEditorFields?.buyer_postal_code ?? buyerClient?.postal_code },
          ]
        } else if (dataDeal?.buyer_type === "CORPORATE_OWNED" || dataDeal?.buyer_type === "ENTITY_AND_OR_ASSIGN") {
          return [
            { fieldId: uuidv4(), fieldName: "Corporation/LLC/Trust Name *", fieldValue: pdfEditorFields?.buyer_trust_name ?? buyerClient?.trust_name },
            { fieldId: uuidv4(), fieldName: "Tax Id", fieldValue: pdfEditorFields?.buyer_trust_tax_id ?? buyerClient?.trust_tax_id },
            { fieldId: uuidv4(), fieldName: "Address *", fieldValue: pdfEditorFields?.buyer_trust_address ?? buyerClient?.trust_address },
            { fieldId: uuidv4(), fieldName: "Unit/Apt", fieldValue: pdfEditorFields?.buyer_trust_unit_apt ?? buyerClient?.trust_unit_apt },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.buyer_trust_country ?? buyerClient?.trust_country },
            { fieldId: uuidv4(), fieldName: "City *", fieldValue: pdfEditorFields?.buyer_trust_city ?? buyerClient?.trust_city },
            { fieldId: uuidv4(), fieldName: "State *", fieldValue: pdfEditorFields?.buyer_trust_state ?? buyerClient?.trust_state },
            { fieldId: uuidv4(), fieldName: "Zip", fieldValue: pdfEditorFields?.buyer_trust_zip ?? buyerClient?.trust_zip },
            { fieldId: uuidv4(), fieldName: "Jurisdiction Of Owner", fieldValue: pdfEditorFields?.buyer_trust_jurisdiction_of_owner ?? buyerClient?.trust_jurisdiction_of_owner },
            { fieldId: uuidv4(), fieldName: "Signer Name *", fieldValue: pdfEditorFields?.buyer_trust_signer_name ?? buyerClient?.trust_signer_name },
            { fieldId: uuidv4(), fieldName: "Signer Title *", fieldValue: pdfEditorFields?.buyer_trust_signer_title ?? buyerClient?.trust_signer_title },
            { fieldId: uuidv4(), fieldName: "Signer Phone", fieldValue: pdfEditorFields?.buyer_trust_signer_phone ?? buyerClient?.trust_signer_phone },
            { fieldId: uuidv4(), fieldName: "Signer Email *", fieldValue: pdfEditorFields?.buyer_trust_signer_email ?? buyerClient?.trust_signer_email },
          ]
        }
      }
      )
    }

    if (buyerClient || coBuyerClient) {

      dispatch(setPdfEditorField(getPdfEditorFields(coBuyerClient ?? {}, "cobuyer")));

      setCoBuyerFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.cobuyer_first_name ?? coBuyerClient?.first_name },
          { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.cobuyer_middle_name ?? coBuyerClient?.middle_name },
          { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.cobuyer_last_name ?? coBuyerClient?.last_name },
          { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobuyer_email ?? coBuyerClient?.email },
          { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.cobuyer_mobile_phone ?? coBuyerClient?.mobile_phone },
          { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.cobuyer_nationality ?? coBuyerClient?.nationality },
          { fieldId: uuidv4(), fieldName: "Address", fieldValue: pdfEditorFields?.cobuyer_address_1 ?? coBuyerClient?.address_1 },
          { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.cobuyer_address_2 ?? coBuyerClient?.address_2 },
          { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.cobuyer_address_3 ?? coBuyerClient?.address_3 },
          { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.cobuyer_country ?? coBuyerClient?.country },
          { fieldId: uuidv4(), fieldName: "City", fieldValue: pdfEditorFields?.cobuyer_city ?? coBuyerClient?.city },
          { fieldId: uuidv4(), fieldName: "State", fieldValue: pdfEditorFields?.cobuyer_state ?? coBuyerClient?.state },
          { fieldId: uuidv4(), fieldName: "Postal Code", fieldValue: pdfEditorFields?.cobuyer_postal_code ?? coBuyerClient?.postal_code },
        ]
      }
      )
    }

    if (sellerClient) {

      dispatch(setPdfEditorField(getPdfEditorFields(sellerClient ?? {}, "seller")));

      setSellerFields(() => {
        if (dataDeal?.seller_type === "OWNER_OF_RECORD") {
          return [
            { fieldId: uuidv4(), fieldName: "Email", fieldValue: sellerClient?.email },
          ]
        } else if (dataDeal?.seller_type === "CORPORATE_OWNED" || dataDeal?.seller_type === "LLC_OWNED" || dataDeal?.seller_type === "TRUST_OWNED") {
          return [
            { fieldId: uuidv4(), fieldName: "Corporation/LLC/Trust Name *", fieldValue: sellerClient?.trust_name },
            { fieldId: uuidv4(), fieldName: "Tax Id", fieldValue: pdfEditorFields?.seller_trust_tax_id ?? sellerClient?.trust_tax_id },
            { fieldId: uuidv4(), fieldName: "Address *", fieldValue: pdfEditorFields?.seller_trust_address ?? sellerClient?.trust_address },
            { fieldId: uuidv4(), fieldName: "Unit/Apt", fieldValue: pdfEditorFields?.seller_trust_unit_apt ?? sellerClient?.trust_unit_apt },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.seller_trust_country ?? sellerClient?.trust_country },
            { fieldId: uuidv4(), fieldName: "City *", fieldValue: pdfEditorFields?.seller_trust_city ?? sellerClient?.trust_city },
            { fieldId: uuidv4(), fieldName: "State *", fieldValue: pdfEditorFields?.seller_trust_state ?? sellerClient?.trust_state },
            { fieldId: uuidv4(), fieldName: "Zip", fieldValue: pdfEditorFields?.seller_trust_zip ?? sellerClient?.trust_zip },
            { fieldId: uuidv4(), fieldName: "Jurisdiction Of Owner", fieldValue: pdfEditorFields?.seller_trust_jurisdiction_of_owner ?? sellerClient?.trust_jurisdiction_of_owner },
            { fieldId: uuidv4(), fieldName: "Signer Name *", fieldValue: pdfEditorFields?.seller_trust_signer_name ?? sellerClient?.trust_signer_name },
            { fieldId: uuidv4(), fieldName: "Signer Title *", fieldValue: pdfEditorFields?.seller_trust_signer_title ?? sellerClient?.trust_signer_title },
            { fieldId: uuidv4(), fieldName: "Signer Phone", fieldValue: pdfEditorFields?.seller_trust_signer_phone ?? sellerClient?.trust_signer_phone },
            { fieldId: uuidv4(), fieldName: "Signer Email", fieldValue: pdfEditorFields?.seller_trust_signer_email ?? sellerClient?.trust_signer_email },
          ]
        } else if (dataDeal?.seller_type === "LEGAL_OWNER" || dataDeal?.seller_type === "JOINT_OWNER") {
          return [
            { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.seller_first_name ?? sellerClient?.first_name },
            { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.seller_middle_name ?? sellerClient?.middle_name },
            { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.seller_last_name ?? sellerClient?.last_name },
            { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.seller_email ?? sellerClient?.email },
            { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.seller_mobile_phone ?? sellerClient?.mobile_phone },
            { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.seller_nationality ?? sellerClient?.nationality },
            { fieldId: uuidv4(), fieldName: "Address", fieldValue: pdfEditorFields?.seller_address_1 ?? sellerClient?.address_1 },
            { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.seller_address_2 ?? sellerClient?.address_2 },
            { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.seller_address_3 ?? sellerClient?.address_3 },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.seller_country ?? sellerClient?.country },
            { fieldId: uuidv4(), fieldName: "City", fieldValue: pdfEditorFields?.seller_city ?? sellerClient?.city },
            { fieldId: uuidv4(), fieldName: "State", fieldValue: pdfEditorFields?.seller_state ?? sellerClient?.state },
            { fieldId: uuidv4(), fieldName: "Postal Code", fieldValue: pdfEditorFields?.seller_postal_code ?? sellerClient?.postal_code },
          ]
        }
      })
    }

    if (sellerClient || coSellerClient) {


      dispatch(setPdfEditorField(getPdfEditorFields(coSellerClient ?? {}, "coseller")));

      setCoSellerFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.coseller_first_name ?? coSellerClient?.first_name },
          { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.coseller_middle_name ?? coSellerClient?.middle_name },
          { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.coseller_last_name ?? coSellerClient?.last_name },
          { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.coseller_email ?? coSellerClient?.email },
          { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.coseller_mobile_phone ?? coSellerClient?.mobile_phone },
          { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.coseller_nationality ?? coSellerClient?.nationality },
          { fieldId: uuidv4(), fieldName: "Address", fieldValue: pdfEditorFields?.coseller_address_1 ?? coSellerClient?.address_1 },
          { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.coseller_address_2 ?? coSellerClient?.address_2 },
          { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.coseller_address_3 ?? coSellerClient?.address_3 },
          { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.coseller_country ?? coSellerClient?.country },
          { fieldId: uuidv4(), fieldName: "City", fieldValue: pdfEditorFields?.coseller_city ?? coSellerClient?.city },
          { fieldId: uuidv4(), fieldName: "State", fieldValue: pdfEditorFields?.coseller_state ?? coSellerClient?.state },
          { fieldId: uuidv4(), fieldName: "Postal Code", fieldValue: pdfEditorFields?.coseller_postal_code ?? coSellerClient?.postal_code },
        ]
      }
      )
    }

    // if dataTradeInVessel have value then only fill fields
    if (dataTradeInVessel && (typeof dataTradeInVessel === "object" && Object.keys(dataTradeInVessel).length > 0)) {

      dispatch(setPdfEditorField(getPdfEditorFields(dataTradeInVessel, "tradeinvessel")));

      setTradeInVesselFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "HIN", fieldValue: pdfEditorFields?.tradeinvessel_hull_number ?? dataTradeInVessel?.hull_number },
          { fieldId: uuidv4(), fieldName: "Name", fieldValue: pdfEditorFields?.tradeinvessel_vessel_name ?? dataTradeInVessel?.vessel_name },
          { fieldId: uuidv4(), fieldName: "Stock", fieldValue: pdfEditorFields?.tradeinvessel_stock ?? dataTradeInVessel?.stock },
          { fieldId: uuidv4(), fieldName: "Trade In Value *", fieldValue: pdfEditorFields?.tradeinvessel_allowance ?? dataTradeInVessel?.allowance },
          { fieldId: uuidv4(), fieldName: "Length *", fieldValue: ` pdfEditorFields?.tradeinvessel_length??${dataTradeInVessel?.length}` },
          { fieldId: uuidv4(), fieldName: "Category *", fieldValue: pdfEditorFields?.tradeinvessel_boat_category ?? dataTradeInVessel?.boat_category },
          { fieldId: uuidv4(), fieldName: "Manufacturer/Builder *", fieldValue: pdfEditorFields?.tradeinvessel_make ?? dataTradeInVessel?.make },
          { fieldId: uuidv4(), fieldName: "Model *", fieldValue: pdfEditorFields?.tradeinvessel_model ?? dataTradeInVessel?.model },
          { fieldId: uuidv4(), fieldName: "Year *", fieldValue: pdfEditorFields?.tradeinvessel_year ?? dataTradeInVessel?.year },
          { fieldId: uuidv4(), fieldName: "USCG/Official/Doc#", fieldValue: pdfEditorFields?.tradeinvessel_doc_no ?? dataTradeInVessel?.doc_no },
          { fieldId: uuidv4(), fieldName: "Reg No", fieldValue: pdfEditorFields?.tradeinvessel_reg_no ?? dataTradeInVessel?.reg_no },
          { fieldId: uuidv4(), fieldName: "Title No", fieldValue: pdfEditorFields?.tradeinvessel_title_no ?? dataTradeInVessel?.title_no },
          { fieldId: uuidv4(), fieldName: "Delivery Location", fieldValue: pdfEditorFields?.tradeinvessel_delivery_location ?? dataTradeInVessel?.delivery_location },
        ]
      })
    }

    if (dataOffer) {

      dispatch(setPdfEditorField(getPdfEditorFields({
        ...dataOffer, ...{
          ["purchase_price"]: getPrice(dataOffer?.purchase_price),
          ["purchase_price_additional_units"]: getPrice(dataOffer?.purchase_price_additional_units),
          ["less_deposit"]: getPrice(dataOffer?.less_deposit),
          ["less_trade_allowance"]: getPrice(dataOffer?.less_trade_allowance),
          ["total_purchase_price"]: getPrice(dataOffer?.total_purchase_price)
        }
      }, "offer")));

      setOfferFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "Offer Date *", fieldValue: dataOffer?.offer_date ? moment(dataOffer?.offer_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Offer Expiration Date *", fieldValue: dataOffer?.offer_expiration_date ? moment(dataOffer?.offer_expiration_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Accept/Reject Date *", fieldValue: dataOffer?.accept_reject_date ? moment(dataOffer?.accept_reject_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Closing Date *", fieldValue: dataOffer?.closing_date ? moment(dataOffer?.closing_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Deposit Due In (# of Days)", fieldValue: dataOffer?.deposit_due_in },
          { fieldId: uuidv4(), fieldName: "Purchase Price (Vessel)", fieldValue: getPrice(dataOffer?.purchase_price) },
          { fieldId: uuidv4(), fieldName: "Purchase Price (Addt'l Units)", fieldValue: getPrice(dataOffer?.purchase_price_additional_units) },
          { fieldId: uuidv4(), fieldName: "Less Deposit *", fieldValue: getPrice(dataOffer?.less_deposit) },
          { fieldId: uuidv4(), fieldName: "Less Trade Allowance", fieldValue: getPrice(dataOffer?.less_trade_allowance) },
          { fieldId: uuidv4(), fieldName: "Total Purchase Price *", fieldValue: getPrice(dataOffer?.total_purchase_price) },
          { fieldId: uuidv4(), fieldName: "Balance", fieldValue: getPrice((dataOffer?.purchase_price || 0) + (dataOffer?.purchase_price_additional_units || 0) - (dataOffer?.less_deposit || 0) - (dataOffer?.less_trade_allowance || 0)) },
        ]
      })
    }

    if (dataExclusions && dataExclusions.length > 0) {

      dispatch(setPdfEditorField(getPdfEditorFields(dataExclusions, "exclusion")));

      setExclusionFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "Exclusion", fieldValue: dataExclusions[0]?.exclusion },
        ]
      })
    }

    if (dataTerms) {

      dispatch(setPdfEditorField(getPdfEditorFields(dataTerms, "terms")));

      setTermFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "Financing Date", fieldValue: dataTerms?.financing_date ? moment(dataTerms?.financing_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Trial Run Date", fieldValue: dataTerms?.trial_run_date ? moment(dataTerms?.trial_run_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Marine Survey Date", fieldValue: dataTerms?.marine_survey_date ? moment(dataTerms?.marine_survey_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Mechanical Inspection Date", fieldValue: dataTerms?.mechanical_inspection_date ? moment(dataTerms?.mechanical_inspection_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Insurance Date", fieldValue: dataTerms?.insurance_date ? moment(dataTerms?.insurance_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Dispute Location City", fieldValue: dataTerms?.dispute_location_city },
          { fieldId: uuidv4(), fieldName: "Dispute Location Law", fieldValue: dataTerms?.dispute_location_law },
          // { fieldId: uuidv4(), fieldName: "Terms/Provisions Preview", fieldValue: dataTerms?.additional_provisions },
          { fieldId: uuidv4(), fieldName: "Additional Terms/Provisions", fieldValue: dataTerms?.custom_additional_terms_provisions },
        ]
      })
    }

    if (dataCoBroker) {

      dispatch(setPdfEditorField(getPdfEditorFields(dataCoBroker, "cobroker")));

      setCoBrokerFields((prev) => {
        if (dataCoBroker?.broker_type === "selling") {
          return [
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: dataCoBroker?.selling_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: dataCoBroker?.selling_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + dataCoBroker?.selling_broker_phone_number }
            ],
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: dataCoBroker?.listing_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: dataCoBroker?.listing_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + dataCoBroker?.listing_broker_phone_number }
            ]
          ]
        }
        if (dataCoBroker?.broker_type === "listing") {
          return [
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: dataCoBroker?.listing_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: dataCoBroker?.listing_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + dataCoBroker?.listing_broker_phone_number }
            ],
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: dataCoBroker?.selling_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: dataCoBroker?.selling_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + dataCoBroker?.selling_broker_phone_number }
            ]
          ]
        }
        if (dataCoBroker?.broker_type === "both") {
          return [
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: dataCoBroker?.selling_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: dataCoBroker?.selling_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + dataCoBroker?.selling_broker_phone_number }
            ],
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: dataCoBroker?.listing_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: dataCoBroker?.listing_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + dataCoBroker?.listing_broker_phone_number }
            ]
          ]
        }
        return prev;
      })
    }
    if (ybaaAmmendment) {

      dispatch(setPdfEditorField(getPdfEditorFields(ybaaAmmendment, "ybaaammendment")));

      setYbaaAmmendmentField(() =>
      (
        [
          { fieldId: uuidv4(), fieldName: "Addendum No.", fieldValue: ybaaAmmendment?.addendum_no },
          { fieldId: uuidv4(), fieldName: "Ammendment", fieldValue: ybaaAmmendment?.ammendment },
        ]
      )
      )
    }
    if (propData?.documentName === "IYBA Acceptance of Vessel") {
      let vesselData;
      (async () => {
        const { data } = await TransactionService.getAcceptanceDoc(propData?.documentId);
        vesselData = data?.data
        setAcceptanceOfVesselField(
          () => {
            if (vesselData?.status === 0) return []
            if (vesselData?.status === 1) return [
              { fieldId: uuidv4(), fieldName: "Days To Complete Condition", fieldValue: vesselData?.days_to_complete_conditions ?? " " },
              { fieldId: uuidv4(), fieldName: "Condition 1", fieldValue: vesselData?.condition_1 ?? "" },
              { fieldId: uuidv4(), fieldName: "Condition 2", fieldValue: vesselData?.condition_2 ?? "" },
            ]
            if (vesselData?.status === 2) return [
              { fieldId: uuidv4(), fieldName: "Adjustment Amount $ ", fieldValue: vesselData?.adjustment_amount ?? " " },
              { fieldId: uuidv4(), fieldName: "Conditions", fieldValue: vesselData?.condition ?? "" },
            ]
            if (vesselData?.status === 3) return [
              { fieldId: uuidv4(), fieldName: "Reason for Rejection ", fieldValue: vesselData?.reason_for_rejection ?? " " },
              { fieldId: uuidv4(), fieldName: "Additional Terms", fieldValue: vesselData?.additional_terms ?? " " },
            ]
          }
        )
      })()

    }
    if (propData?.documentName === "Purchase & Sale Agreement Exclusion") {
      (async () => {
        const { data } = await TransactionService.getExclusionDoc(propData?.documentId);
        const exclusion = data?.data?.exclusion || "";
        setAgreementExclusion(
          () => (
            exclusion.split("/:/").map((str, index) => {
              return { fieldId: uuidv4(), fieldName: `exclusion${index + 1}`, fieldValue: str }
            })
          ))
      })()

    }
    if (agreementAmmendmunt) {

      dispatch(setPdfEditorField(getPdfEditorFields(agreementAmmendmunt, "agreementammendmunt")));

      setAgreementAmmendmuntField(() =>
      (
        [
          { fieldId: uuidv4(), fieldName: "Addendum No.", fieldValue: agreementAmmendmunt?.addendum_no },
          { fieldId: uuidv4(), fieldName: "Ammendment", fieldValue: agreementAmmendmunt?.ammendment },
        ]
      )
      )
    }
    if (dataBuyerClosing) {

      // store buyerclosing fields in allFormFields state with buyerclosing prefix
      if (propData?.task_type === "purchase-&-sale-agreement") {

        dispatch(setPdfEditorField(getPdfEditorFields(dataBuyerClosing, "buyerclosing")));

      }


      setBuyerClosing(() => (
        [
          { fieldId: uuidv4(), fieldName: "Sales Price (Total)", fieldValue: getPrice(dataBuyerClosing["sales_price_total"]) },
          { fieldId: uuidv4(), fieldName: "Sales Tax (vessel)", fieldValue: getPrice(dataBuyerClosing["sales_tax_vessel"]) ?? "$0" },
          { fieldId: uuidv4(), fieldName: "Sub-Total", fieldValue: getPrice(dataBuyerClosing["sub_total"]) },
          { fieldId: uuidv4(), fieldName: "Balance Due", fieldValue: getPrice(dataBuyerClosing["balance_due"]) },
        ]
      ))
    }
    if (propData?.documentName === "Seller Closing Statement") {
      dispatch(setPdfEditorField(getPdfEditorFields(dataVesselAcceptance, "vesselacceptance")));


      setSellerClosing(() => {
        const purchase_total = (dataOffer.purchase_price || 0) + (dataOffer.purchase_price_additional_units || 0);
        const total = purchase_total || 0;
        const sales_commission = dataCobroker?.gross_commission_amount
        const survey_allowance = dataSellerClosing?.survey_allowance || dataVesselAcceptance?.adjustment_amount
        const holdback_escrow = dataSellerClosing?.holdback_escrow
        const gross_proceeds = total - (sales_commission ?? 0) - (survey_allowance ?? 0) - (holdback_escrow ?? 0);
        // balance due seller
        const details = dataDetails.filter((d) => d.document_type === "seller");
        const { expenseTotal, creditTotal, depositTotal, disbursementTotal } = getTotals(details);
        const payoff = dataSellerPayoff.payoff || 0;
        const subtotal = (gross_proceeds ?? 0) - (payoff ?? 0) - (expenseTotal ?? 0);
        const net_proceeds = (subtotal ?? 0) + (creditTotal ?? 0) + (depositTotal ?? 0);
        return [
          { fieldId: uuidv4(), fieldName: "Contract Sales Price", fieldValue: getPrice(purchase_total) },
          { fieldId: uuidv4(), fieldName: "Gross Sales Commission ", fieldValue: getPrice(dataCobroker?.gross_commission_amount) ?? "$0" },
          { fieldId: uuidv4(), fieldName: "Gross Proceeds", fieldValue: getPrice(gross_proceeds) },
          { fieldId: uuidv4(), fieldName: "Balance Due Seller", fieldValue: getPrice(net_proceeds) },
        ]
      })
    }

    return () => {
      dispatch(removeAllPdfEditorField());
    }

  }, [dataTransaction, refresh]);

  useEffect(() => {
    if (dataVessel) {

      setVesselFields(() =>
      (
        [
          { fieldId: uuidv4(), fieldName: "HIN *", fieldValue: pdfEditorFields?.vessel_hull_number ?? dataVessel?.hull_number },
          { fieldId: uuidv4(), fieldName: "Manufacturer/Builder *", fieldValue: pdfEditorFields?.vessel_make ?? dataVessel?.make },
          { fieldId: uuidv4(), fieldName: "Stock", fieldValue: pdfEditorFields?.vessel_stock_number ?? dataVessel?.stock_number },
          { fieldId: uuidv4(), fieldName: "Year *", fieldValue: pdfEditorFields?.vessel_year ?? dataVessel?.year },
          { fieldId: uuidv4(), fieldName: "Model", fieldValue: pdfEditorFields?.vessel_model ?? dataVessel?.model },
          { fieldId: uuidv4(), fieldName: "Length *", fieldValue: `${pdfEditorFields?.vessel_length ?? dataVessel?.length}` },
          { fieldId: uuidv4(), fieldName: "Name", fieldValue: pdfEditorFields?.vessel_vessel_name ?? dataVessel?.vessel_name },
          // { fieldId: uuidv4(), fieldName: "Gross Price", fieldValue: `${dataDeal?.t_offers?.total_purchase_price}` },
          { fieldId: uuidv4(), fieldName: "Category", fieldValue: pdfEditorFields?.vessel_boat_category ?? dataVessel?.boat_category },
          { fieldId: uuidv4(), fieldName: "USCG/Official/Doc#", fieldValue: pdfEditorFields?.vessel_uscg ?? dataVessel?.uscg ?? pdfEditorFields?.vessel_doc_no ?? dataVessel?.doc_no },
          { fieldId: uuidv4(), fieldName: "Reg No", fieldValue: pdfEditorFields?.vessel_reg_no ?? dataVessel?.reg_no },
          { fieldId: uuidv4(), fieldName: "Title No", fieldValue: pdfEditorFields?.vessel_title_no ?? dataVessel?.title_no },
        ]
      )
      )
    }

    if (buyerClient) {

      setBuyerFields(() => {
        if (dataDeal?.buyer_type === "LEGAL_OWNER" || dataDeal?.buyer_type === "LEGAL_AND_OR_ASSIGN") {
          return [
            { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.buyer_first_name ?? buyerClient?.first_name },
            { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.buyer_middle_name ?? buyerClient?.middle_name },
            { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.buyer_last_name ?? buyerClient?.last_name },
            { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.buyer_email ?? buyerClient?.email },
            { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.buyer_mobile_phone ?? buyerClient?.mobile_phone },
            { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.buyer_nationality ?? buyerClient?.nationality },
            { fieldId: uuidv4(), fieldName: "Address *", fieldValue: pdfEditorFields?.buyer_address_1 ?? buyerClient?.address_1 },
            { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.buyer_address_2 ?? buyerClient?.address_2 },
            { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.buyer_address_3 ?? buyerClient?.address_3 },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.buyer_country ?? buyerClient?.country },
            { fieldId: uuidv4(), fieldName: "City *", fieldValue: pdfEditorFields?.buyer_city ?? buyerClient?.city },
            { fieldId: uuidv4(), fieldName: "State *", fieldValue: pdfEditorFields?.buyer_state ?? buyerClient?.state },
            { fieldId: uuidv4(), fieldName: "Postal Code *", fieldValue: pdfEditorFields?.buyer_postal_code ?? buyerClient?.postal_code },
          ]
        } else if (dataDeal?.buyer_type === "CORPORATE_OWNED" || dataDeal?.buyer_type === "ENTITY_AND_OR_ASSIGN") {
          return [
            { fieldId: uuidv4(), fieldName: "Corporation/LLC/Trust Name *", fieldValue: pdfEditorFields?.buyer_trust_name ?? buyerClient?.trust_name },
            { fieldId: uuidv4(), fieldName: "Tax Id", fieldValue: pdfEditorFields?.buyer_trust_tax_id ?? buyerClient?.trust_tax_id },
            { fieldId: uuidv4(), fieldName: "Address *", fieldValue: pdfEditorFields?.buyer_trust_address ?? buyerClient?.trust_address },
            { fieldId: uuidv4(), fieldName: "Unit/Apt", fieldValue: pdfEditorFields?.buyer_trust_unit_apt ?? buyerClient?.trust_unit_apt },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.buyer_trust_country ?? buyerClient?.trust_country },
            { fieldId: uuidv4(), fieldName: "City *", fieldValue: pdfEditorFields?.buyer_trust_city ?? buyerClient?.trust_city },
            { fieldId: uuidv4(), fieldName: "State *", fieldValue: pdfEditorFields?.buyer_trust_state ?? buyerClient?.trust_state },
            { fieldId: uuidv4(), fieldName: "Zip", fieldValue: pdfEditorFields?.buyer_trust_zip ?? buyerClient?.trust_zip },
            { fieldId: uuidv4(), fieldName: "Jurisdiction Of Owner", fieldValue: pdfEditorFields?.buyer_trust_jurisdiction_of_owner ?? buyerClient?.trust_jurisdiction_of_owner },
            { fieldId: uuidv4(), fieldName: "Signer Name *", fieldValue: pdfEditorFields?.buyer_trust_signer_name ?? buyerClient?.trust_signer_name },
            { fieldId: uuidv4(), fieldName: "Signer Title *", fieldValue: pdfEditorFields?.buyer_trust_signer_title ?? buyerClient?.trust_signer_title },
            { fieldId: uuidv4(), fieldName: "Signer Phone", fieldValue: pdfEditorFields?.buyer_trust_signer_phone ?? buyerClient?.trust_signer_phone },
            { fieldId: uuidv4(), fieldName: "Signer Email *", fieldValue: pdfEditorFields?.buyer_trust_signer_email ?? buyerClient?.trust_signer_email },
          ]
        }
      }
      )
    }

    if (buyerClient || coBuyerClient) {

      setCoBuyerFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.cobuyer_first_name ?? coBuyerClient?.first_name },
          { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.cobuyer_middle_name ?? coBuyerClient?.middle_name },
          { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.cobuyer_last_name ?? coBuyerClient?.last_name },
          { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobuyer_email ?? coBuyerClient?.email },
          { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.cobuyer_mobile_phone ?? coBuyerClient?.mobile_phone },
          { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.cobuyer_nationality ?? coBuyerClient?.nationality },
          { fieldId: uuidv4(), fieldName: "Address", fieldValue: pdfEditorFields?.cobuyer_address_1 ?? coBuyerClient?.address_1 },
          { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.cobuyer_address_2 ?? coBuyerClient?.address_2 },
          { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.cobuyer_address_3 ?? coBuyerClient?.address_3 },
          { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.cobuyer_country ?? coBuyerClient?.country },
          { fieldId: uuidv4(), fieldName: "City", fieldValue: pdfEditorFields?.cobuyer_city ?? coBuyerClient?.city },
          { fieldId: uuidv4(), fieldName: "State", fieldValue: pdfEditorFields?.cobuyer_state ?? coBuyerClient?.state },
          { fieldId: uuidv4(), fieldName: "Postal Code", fieldValue: pdfEditorFields?.cobuyer_postal_code ?? coBuyerClient?.postal_code },
        ]
      }
      )
    }

    if (sellerClient) {

      setSellerFields(() => {
        if (dataDeal?.seller_type === "OWNER_OF_RECORD") {
          return [
            { fieldId: uuidv4(), fieldName: "Email", fieldValue: sellerClient?.email },
          ]
        } else if (dataDeal?.seller_type === "CORPORATE_OWNED" || dataDeal?.seller_type === "LLC_OWNED" || dataDeal?.seller_type === "TRUST_OWNED") {
          return [
            { fieldId: uuidv4(), fieldName: "Corporation/LLC/Trust Name *", fieldValue: sellerClient?.trust_name },
            { fieldId: uuidv4(), fieldName: "Tax Id", fieldValue: pdfEditorFields?.seller_trust_tax_id ?? sellerClient?.trust_tax_id },
            { fieldId: uuidv4(), fieldName: "Address *", fieldValue: pdfEditorFields?.seller_trust_address ?? sellerClient?.trust_address },
            { fieldId: uuidv4(), fieldName: "Unit/Apt", fieldValue: pdfEditorFields?.seller_trust_unit_apt ?? sellerClient?.trust_unit_apt },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.seller_trust_country ?? sellerClient?.trust_country },
            { fieldId: uuidv4(), fieldName: "City *", fieldValue: pdfEditorFields?.seller_trust_city ?? sellerClient?.trust_city },
            { fieldId: uuidv4(), fieldName: "State *", fieldValue: pdfEditorFields?.seller_trust_state ?? sellerClient?.trust_state },
            { fieldId: uuidv4(), fieldName: "Zip", fieldValue: pdfEditorFields?.seller_trust_zip ?? sellerClient?.trust_zip },
            { fieldId: uuidv4(), fieldName: "Jurisdiction Of Owner", fieldValue: pdfEditorFields?.seller_trust_jurisdiction_of_owner ?? sellerClient?.trust_jurisdiction_of_owner },
            { fieldId: uuidv4(), fieldName: "Signer Name *", fieldValue: pdfEditorFields?.seller_trust_signer_name ?? sellerClient?.trust_signer_name },
            { fieldId: uuidv4(), fieldName: "Signer Title *", fieldValue: pdfEditorFields?.seller_trust_signer_title ?? sellerClient?.trust_signer_title },
            { fieldId: uuidv4(), fieldName: "Signer Phone", fieldValue: pdfEditorFields?.seller_trust_signer_phone ?? sellerClient?.trust_signer_phone },
            { fieldId: uuidv4(), fieldName: "Signer Email", fieldValue: pdfEditorFields?.seller_trust_signer_email ?? sellerClient?.trust_signer_email },
          ]
        } else if (dataDeal?.seller_type === "LEGAL_OWNER" || dataDeal?.seller_type === "JOINT_OWNER") {
          return [
            { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.seller_first_name ?? sellerClient?.first_name },
            { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.seller_middle_name ?? sellerClient?.middle_name },
            { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.seller_last_name ?? sellerClient?.last_name },
            { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.seller_email ?? sellerClient?.email },
            { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.seller_mobile_phone ?? sellerClient?.mobile_phone },
            { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.seller_nationality ?? sellerClient?.nationality },
            { fieldId: uuidv4(), fieldName: "Address", fieldValue: pdfEditorFields?.seller_address_1 ?? sellerClient?.address_1 },
            { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.seller_address_2 ?? sellerClient?.address_2 },
            { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.seller_address_3 ?? sellerClient?.address_3 },
            { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.seller_country ?? sellerClient?.country },
            { fieldId: uuidv4(), fieldName: "City", fieldValue: pdfEditorFields?.seller_city ?? sellerClient?.city },
            { fieldId: uuidv4(), fieldName: "State", fieldValue: pdfEditorFields?.seller_state ?? sellerClient?.state },
            { fieldId: uuidv4(), fieldName: "Postal Code", fieldValue: pdfEditorFields?.seller_postal_code ?? sellerClient?.postal_code },
          ]
        }
      })
    }
    if (coSellerClient || sellerClient) {

      setCoSellerFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "First Name *", fieldValue: pdfEditorFields?.coseller_first_name ?? coSellerClient?.first_name },
          { fieldId: uuidv4(), fieldName: "Middle Name", fieldValue: pdfEditorFields?.coseller_middle_name ?? coSellerClient?.middle_name },
          { fieldId: uuidv4(), fieldName: "Last Name *", fieldValue: pdfEditorFields?.coseller_last_name ?? coSellerClient?.last_name },
          { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.coseller_email ?? coSellerClient?.email },
          { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: pdfEditorFields?.coseller_mobile_phone ?? coSellerClient?.mobile_phone },
          { fieldId: uuidv4(), fieldName: "Citizen Of", fieldValue: pdfEditorFields?.coseller_nationality ?? coSellerClient?.nationality },
          { fieldId: uuidv4(), fieldName: "Address", fieldValue: pdfEditorFields?.coseller_address_1 ?? coSellerClient?.address_1 },
          { fieldId: uuidv4(), fieldName: "Address 2", fieldValue: pdfEditorFields?.coseller_address_2 ?? coSellerClient?.address_2 },
          { fieldId: uuidv4(), fieldName: "Address 3", fieldValue: pdfEditorFields?.coseller_address_3 ?? coSellerClient?.address_3 },
          { fieldId: uuidv4(), fieldName: "Country", fieldValue: pdfEditorFields?.coseller_country ?? coSellerClient?.country },
          { fieldId: uuidv4(), fieldName: "City", fieldValue: pdfEditorFields?.coseller_city ?? coSellerClient?.city },
          { fieldId: uuidv4(), fieldName: "State", fieldValue: pdfEditorFields?.coseller_state ?? coSellerClient?.state },
          { fieldId: uuidv4(), fieldName: "Postal Code", fieldValue: pdfEditorFields?.coseller_postal_code ?? coSellerClient?.postal_code },
        ]
      }
      )
    }

    // if dataTradeInVessel have value then only fill fields
    if (dataTradeInVessel && (typeof dataTradeInVessel === "object" && Object.keys(dataTradeInVessel).length > 0)) {

      setTradeInVesselFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "HIN", fieldValue: pdfEditorFields?.tradeinvessel_hull_number ?? dataTradeInVessel?.hull_number },
          { fieldId: uuidv4(), fieldName: "Name", fieldValue: pdfEditorFields?.tradeinvessel_vessel_name ?? dataTradeInVessel?.vessel_name },
          { fieldId: uuidv4(), fieldName: "Stock", fieldValue: pdfEditorFields?.tradeinvessel_stock ?? dataTradeInVessel?.stock },
          { fieldId: uuidv4(), fieldName: "Trade In Value *", fieldValue: pdfEditorFields?.tradeinvessel_allowance ?? dataTradeInVessel?.allowance },
          { fieldId: uuidv4(), fieldName: "Length *", fieldValue: ` pdfEditorFields?.tradeinvessel_length??${dataTradeInVessel?.length}` },
          { fieldId: uuidv4(), fieldName: "Category *", fieldValue: pdfEditorFields?.tradeinvessel_boat_category ?? dataTradeInVessel?.boat_category },
          { fieldId: uuidv4(), fieldName: "Manufacturer/Builder *", fieldValue: pdfEditorFields?.tradeinvessel_make ?? dataTradeInVessel?.make },
          { fieldId: uuidv4(), fieldName: "Model *", fieldValue: pdfEditorFields?.tradeinvessel_model ?? dataTradeInVessel?.model },
          { fieldId: uuidv4(), fieldName: "Year *", fieldValue: pdfEditorFields?.tradeinvessel_year ?? dataTradeInVessel?.year },
          { fieldId: uuidv4(), fieldName: "USCG/Official/Doc#", fieldValue: pdfEditorFields?.tradeinvessel_doc_no ?? dataTradeInVessel?.doc_no },
          { fieldId: uuidv4(), fieldName: "Reg No", fieldValue: pdfEditorFields?.tradeinvessel_reg_no ?? dataTradeInVessel?.reg_no },
          { fieldId: uuidv4(), fieldName: "Title No", fieldValue: pdfEditorFields?.tradeinvessel_title_no ?? dataTradeInVessel?.title_no },
          { fieldId: uuidv4(), fieldName: "Delivery Location", fieldValue: pdfEditorFields?.tradeinvessel_delivery_location ?? dataTradeInVessel?.delivery_location },
        ]
      })
    }

    if (dataOffer) {

      setOfferFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "Offer Date *", fieldValue: pdfEditorFields?.offer_offer_date ?? dataOffer?.offer_date ? moment(pdfEditorFields?.offer_offer_date ?? dataOffer?.offer_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Offer Expiration Date *", fieldValue: pdfEditorFields?.offer_offer_expiration_date ?? dataOffer?.offer_expiration_date ? moment(pdfEditorFields?.offer_offer_expiration_date ?? dataOffer?.offer_expiration_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Accept/Reject Date *", fieldValue: pdfEditorFields?.offer_accept_reject_date ?? dataOffer?.accept_reject_date ? moment(pdfEditorFields?.offer_accept_reject_date ?? dataOffer?.accept_reject_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Closing Date *", fieldValue: pdfEditorFields?.offer_closing_date ?? dataOffer?.closing_date ? moment(pdfEditorFields?.offer_closing_date ?? dataOffer?.closing_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Deposit Due In (# of Days)", fieldValue: pdfEditorFields?.offer_deposit_due_in ?? dataOffer?.deposit_due_in },
          { fieldId: uuidv4(), fieldName: "Purchase Price (Vessel)", fieldValue: pdfEditorFields?.offer_purchase_price ?? dataOffer?.purchase_price },
          { fieldId: uuidv4(), fieldName: "Purchase Price (Addt'l Units)", fieldValue: pdfEditorFields?.offer_purchase_price_additional_units ?? dataOffer?.purchase_price_additional_units },
          { fieldId: uuidv4(), fieldName: "Less Deposit *", fieldValue: pdfEditorFields?.offer_less_deposit ?? dataOffer?.less_deposit },
          { fieldId: uuidv4(), fieldName: "Less Trade Allowance", fieldValue: pdfEditorFields?.offer_less_trade_allowance ?? dataOffer?.less_trade_allowance },
          { fieldId: uuidv4(), fieldName: "Total Purchase Price *", fieldValue: pdfEditorFields?.offer_total_purchase_price ?? dataOffer?.total_purchase_price },
          { fieldId: uuidv4(), fieldName: "Balance", fieldValue: (pdfEditorFields?.offer_purchase_price ?? dataOffer?.purchase_price ?? 0) + (pdfEditorFields?.offer_purchase_price_additional_units ?? dataOffer?.purchase_price_additional_units ?? 0) - (pdfEditorFields?.offer_less_deposit ?? dataOffer?.less_deposit ?? 0) - (pdfEditorFields?.offer_less_trade_allowance ?? dataOffer?.less_trade_allowance ?? 0) },
        ]
      })
    }

    if (dataTerms) {

      setTermFields(() => {
        return [
          { fieldId: uuidv4(), fieldName: "Financing Date", fieldValue: pdfEditorFields?.terms_financing_date ?? dataTerms?.financing_date ? moment(pdfEditorFields?.terms_financing_date ?? dataTerms?.financing_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Trial Run Date", fieldValue: pdfEditorFields?.terms_trial_run_date ?? dataTerms?.trial_run_date ? moment(pdfEditorFields?.terms_trial_run_date ?? dataTerms?.trial_run_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Marine Survey Date", fieldValue: pdfEditorFields?.terms_marine_survey_date ?? dataTerms?.marine_survey_date ? moment(pdfEditorFields?.terms_marine_survey_date ?? dataTerms?.marine_survey_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Mechanical Inspection Date", fieldValue: pdfEditorFields?.terms_mechanical_inspection_date ?? dataTerms?.mechanical_inspection_date ? moment(pdfEditorFields?.terms_mechanical_inspection_date ?? dataTerms?.mechanical_inspection_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Insurance Date", fieldValue: pdfEditorFields?.terms_insurance_date ?? dataTerms?.insurance_date ? moment(pdfEditorFields?.terms_insurance_date ?? dataTerms?.insurance_date).format("MM-DD-YYYY") : null },
          { fieldId: uuidv4(), fieldName: "Dispute Location City", fieldValue: pdfEditorFields?.terms_dispute_location_city ?? dataTerms?.dispute_location_city },
          { fieldId: uuidv4(), fieldName: "Dispute Location Law", fieldValue: pdfEditorFields?.terms_dispute_location_law ?? dataTerms?.dispute_location_law },
          // { fieldId: uuidv4(), fieldName: "Terms/Provisions Preview", fieldValue: pdfEditorFields?.terms_additional_provisions??dataTerms?.additional_provisions },
          { fieldId: uuidv4(), fieldName: "Additional Terms/Provisions", fieldValue: pdfEditorFields?.terms_custom_additional_terms_provisions ?? dataTerms?.custom_additional_terms_provisions },
        ]
      })
    }

    if (dataCoBroker) {

      setCoBrokerFields((prev) => {
        if (dataCoBroker?.broker_type === "selling") {
          return [
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: pdfEditorFields?.cobroker_selling_brokerage_broker ?? dataCoBroker?.selling_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobroker_selling_broker_email ?? dataCoBroker?.selling_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + pdfEditorFields?.cobroker_selling_broker_phone_number ?? dataCoBroker?.selling_broker_phone_number }
            ],
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: pdfEditorFields?.cobroker_listing_brokerage_broker ?? dataCoBroker?.listing_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobroker_listing_broker_email ?? dataCoBroker?.listing_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + pdfEditorFields?.cobroker_listing_broker_phone_number ?? dataCoBroker?.listing_broker_phone_number }
            ]
          ]
        }
        if (dataCoBroker?.broker_type === "listing") {
          return [
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: pdfEditorFields?.cobroker_listing_brokerage_broker ?? dataCoBroker?.listing_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobroker_listing_broker_email ?? dataCoBroker?.listing_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + pdfEditorFields?.cobroker_listing_broker_phone_number ?? dataCoBroker?.listing_broker_phone_number }
            ],
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: pdfEditorFields?.cobroker_selling_brokerage_broker ?? dataCoBroker?.selling_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobroker_selling_broker_email ?? dataCoBroker?.selling_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + pdfEditorFields?.cobroker_selling_broker_phone_number ?? dataCoBroker?.selling_broker_phone_number }
            ]
          ]
        }
        if (dataCoBroker?.broker_type === "both") {
          return [
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: pdfEditorFields?.cobroker_selling_brokerage_broker ?? dataCoBroker?.selling_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobroker_selling_broker_email ?? dataCoBroker?.selling_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + pdfEditorFields?.cobroker_selling_broker_phone_number ?? dataCoBroker?.selling_broker_phone_number }
            ],
            [
              { fieldId: uuidv4(), fieldName: "Broker *", fieldValue: pdfEditorFields?.cobroker_listing_brokerage_broker ?? dataCoBroker?.listing_brokerage_broker },
              { fieldId: uuidv4(), fieldName: "Email *", fieldValue: pdfEditorFields?.cobroker_listing_broker_email ?? dataCoBroker?.listing_broker_email },
              { fieldId: uuidv4(), fieldName: "Mobile Phone *", fieldValue: "+1" + pdfEditorFields?.cobroker_listing_broker_phone_number ?? dataCoBroker?.listing_broker_phone_number }
            ]
          ]
        }
        return prev;
      })
    }

  }, [pdfEditorFields])
  // useEffect for make data updated of formFields which is dragged on pdf and formData is changing
  useEffect(() => {
    // if form fields put on pdf then only have to run this and changes will be taken
    if (formFields?.length > 0) {
      setFormFields((prev) => {
        return prev?.map((formField) => {
          const formFieldType = formField?.formName;
          const fieldName = formField?.name;
          const possibleFields = {
            vesselFields: vesselFields,
            termFields: termFields,
            buyerFields: buyerFields,
            coBuyerFields: coBuyerFields,
            sellerFields: sellerFields,
            coSellerFields: coSellerFields,
            tradeInVesselFields: tradeInVesselFields,
            offerFields: offerFields,
            exclusionFields: exclusionFields,
            coBrokerDealSideFields: coBrokerFields[0],
            coBrokerOtherSideFields: coBrokerFields[1],
            ybaaAmendmentField: ybaaAmendmentField,
            acceptanceOfVesselField: acceptanceOfVesselField,
            agreementExclusion: agreementExclusion,
            agreementAmmendmuntField: agreementAmmendmuntField,
            buyerClosing: buyerClosing,
            sellerClosing: sellerClosing,
          };
          // find updated field with updated value
          const updatedField = possibleFields[formFieldType]?.find((uField) => uField?.fieldName === fieldName) || {};
          if (updatedField && Object.keys(updatedField).length > 0) { return { ...formField, text: updatedField?.fieldValue } } return formField;
        });
      });
    }
  }, [vesselFields, termFields, buyerFields, coBuyerFields, sellerFields, coSellerFields, tradeInVesselFields, offerFields, exclusionFields, coBrokerFields[0], coBrokerFields[1]]);

  const isCustomDocument = propData?.task_type == 'custom-esign-transaction-template' || null;

  // by this useEffect it will get page count of main template Doc
  useEffect(() => {
    // if (propData?.haveEditVersions) {
    setTemplatePages();
    // }
  }, [propData?.templateLink]);

  // by this useEffect it will get roles of main template Doc and set for add signature/initials/date
  useEffect(() => {
    if (propData?.roles) {
      setSignatureRoles(() => {
        let allRoles = [{ role: "Seller", bgColor: "#e5befc" },
        { role: "Owner", bgColor: "#e5befc" },
        { role: "Co-Seller", bgColor: "#ffe175" },
        { role: "Co-Owner", bgColor: "#ffe175" },
        { role: "Buyer", bgColor: "#8ae2f4" },
        { role: "Co-Buyer", bgColor: "#f59600" },
        { role: "Selling-Broker", bgColor: "#74dd90" },
        { role: "Listing-Broker", bgColor: "#0063a6" },
        ]
        setSignatureAssigner(isCustomDocument ? propData.type === "deals" ? allRoles?.[0]?.role : allRoles?.[1]?.role : allRoles.filter(element => propData?.roles.includes(element.role))[0]?.role);
        return (isCustomDocument ? propData.type === "deals" ? allRoles?.filter((role) => !["Owner", "Co-Owner"]?.includes(role?.role)) : allRoles?.filter((role) => !["Seller", "Co-Seller"]?.includes(role?.role)) : allRoles.filter(element => propData?.roles.includes(element.role)));
      });
    }
  }, [propData?.roles]);

  // fill manually added signature/initials/date fields 
  useEffect(() => {
    if (propData?.updated_elements) {
      setSignatureFields((prev) => [...prev, ...propData?.updated_elements.map((element) => {
        let bgColor = element?.assigner ? getSignatureBgColor(element?.assigner) : "#ffffff";
        return {
          ...element,
          bg_color: bgColor,
          page_number: element.page_number + 1
        }
      })]);
    }
  }, [propData?.updated_elements]);

  // function to get role wise background color for signature/initials/date
  const getSignatureBgColor = (signatureAssigner) => {
    switch (signatureAssigner) {
      case "Seller":
        return "#e5befc";
      case "Owner":
        return "#e5befc";
      case "Co-Seller":
        return "#ffe175";
      case "Co-Owner":
        return "#ffe175";
      case "Buyer":
        return "#8ae2f4";
      case "Co-Buyer":
        return "#f59600";
      case "Selling-Broker":
        return "#74dd90";
      case "Listing-Broker":
        return "#0063a6";
      default:
        return "#ffffff";
    }
  }

  // function for set template pages with wait
  const setTemplatePages = async () => {
    if (!!propData?.templateLink && propData?.file_url) {
      const pdfPageCount = await getPdfPageCountFromUrl(propData?.templateLink)
      setTemplatePageCount(pdfPageCount);
    }
  }

  const logged_user = Auth.getInstance().getUserData();

  const onPreviousPage = () => {
    history.push(`/${propData.type}/view/${propData.transactionId}/main`);
  };

  /**
   * Saves the updated pdf version in database with confirmation
   * @param {*} documentData
   */
  const onSaveDocumentVersion = async (documentData) => {
    //Open a Warning pop-up
    Swal.fire({
      title: 'Attention!',
      html: `YOU ARE SWITCHING TO DIRECT PDF EDITS <br/> <br/>All future updates to this document must be made from here - the PDF Editor ONLY!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'Save',
      // cancelButtonColor: "#d33"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          // EDocService.updateEditedDocumentPdf(apiBody).then(
          //     response => {
          //         dispatch(setLoadingStatus(false));
          //         toast.success('Your PDF updated successfully.')
          //         onPreviousPage();
          //     }
          // ).catch(
          //     error => {
          //         dispatch(setLoadingStatus(false));
          //         toast.error('Cannot update PDF right now!');
          //         console.log(error)
          //     });
          // alert("sdfbjhurdeb jfge");

          uploadPdf(documentData, "pdf-documents", propData.uniqueId, propData.companyUId)
            .then((response) => {

              let apiBody = {
                file_name: response,
                transaction_edocument_id: propData.documentId,
                is_current: true,
                transaction_id: propData.transactionId,
                activity_title: propData.documentName,
                activity_task_type: `pdf_edited`,
                activity_action_by: logged_user.id,
                uniqueId: propData.uniqueId,
                companyUId: propData.companyUId,
                is_elements_edit: signatureFields.length > 0 ? true : false,
                updated_elements: [...signatureFields?.map((element) => {
                  let payloadObj = { ...element } // Make a copy of the original object
                  delete payloadObj?.bg_color;      // Delete 'bg_color' from the new object
                  return { ...payloadObj, page_number: (element?.page_number - 1) };
                })],
                is_pdf_merge: propData?.is_pdf_merge ? true : (mergedPdfUrl ? true : false)
              };
              if (isCustomDocument) {
                apiBody = {
                  ...apiBody,
                  roles: [...new Set(signatureFields?.map((assigner) => assigner?.assigner))]
                }
              }
              EDocService.updateEditedDocumentPdf(apiBody).then((response) => {
                dispatch(setLoadingStatus(false));
                toast.success("Your PDF updated successfully.");
                onPreviousPage();
              });
              dispatch(setLoadingStatus(false));
            })
            .catch((error) => {
              dispatch(setLoadingStatus(false));
              toast.error("Cannot update PDF right now!");
              console.log(error);
            });
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.log(error);
        }
      }
    });
  };
  const singleSaveDocumentVersion = async (documentData) => {
    //Open a Warning pop-up
    Swal.fire({
      title: 'Attention!',
      html: `YOU ARE SWITCHING TO DIRECT PDF EDITS <br/> <br/>All future updates to this document must be made from here - the PDF Editor ONLY!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'Save',
      // cancelButtonColor: "#d33"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          uploadPdf(documentData, "pdf-documents", propData.uniqueId, propData.companyUId)
            .then((response) => {

              let apiBody = {
                file_name: response,
                transaction_edocument_id: propData.documentId,
                is_current: true,
                transaction_id: propData.transactionId,
                activity_title: propData.documentName,
                activity_task_type: `pdf_edited`,
                activity_action_by: logged_user.id,
                uniqueId: propData.uniqueId,
                companyUId: propData.companyUId,
                is_elements_edit: signatureFields.length > 0 ? true : false,
                updated_elements: [...signatureFields?.map((element) => {
                  let payloadObj = { ...element } // Make a copy of the original object
                  delete payloadObj?.bg_color;      // Delete 'bg_color' from the new object
                  return { ...payloadObj, page_number: (element?.page_number - 1) };
                })],
                is_pdf_merge: propData?.is_pdf_merge ? true : (mergedPdfUrl ? true : false)
              };
              if (isCustomDocument) {
                apiBody = {
                  ...apiBody,
                  roles: [...new Set(signatureFields?.map((assigner) => assigner?.assigner))]
                }
              }
              EDocService.updateEditedDocumentPdf(apiBody).then((response) => {
                dispatch(setLoadingStatus(false));
                toast.success("Your PDF updated successfully.");
              });
              dispatch(setLoadingStatus(false));
            })
            .catch((error) => {
              dispatch(setLoadingStatus(false));
              toast.error("Cannot update PDF right now!");
              console.log(error);
            });
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.log(error);
        }
      }
    });
  };

  const onMergeDocument = async (mergedPdfUrl, isCustomDoc = false) => {
    if (!isCustomDoc) {
      //Open a Warning pop-up
      Swal.fire({
        title: 'Attention!',
        html: `Please complete any "PDF Edits" before adding additional file as no further changes will be allowed after merging files !`,
        icon: "warning",
        showCancelButton: true,
        // cancelButtonColor: "#d33",
        cancelButtonText: "Go Back",
        confirmButtonColor: "#3085d6",
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          setMergedPdfUrl(mergedPdfUrl);
        } else {
          setIsConfirmForMerge(true); // do true when confirmation declines
          setFiles((prev) => [...prev.slice(0, -1)]); // pop last file in don't merge
          mergeRef.current.value = null;
        }
      }).catch((err) => console.log(err));
    } else { setMergedPdfUrl(mergedPdfUrl); } // in custom doc no need for pop up because addition will remain same after merge
  };

  // function for set new edited pdf url to render with deleted page
  const onDeleteDocumentPage = async (editedPdfUrl, pageNumberToDelete, isMainDoc = false) => {
    //Open a Warning pop-up
    Swal.fire({
      title: 'Attention!',
      html: `This will permanently delete the selected page.<br>Are you sure?`,
      icon: "warning",
      showCancelButton: true,
      // cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        if (!isMainDoc) {
          setMergedPdfUrl(editedPdfUrl);
        } else {
          getPdfPageCountFromUrl(editorPdf).then((docPageCount) => {
            // if document is main in doc then only it will render and editable
            ((templatePageCount === docPageCount) && (!(propData?.is_pdf_merge))) ? setMergedPdfUrl(null) : setMergedPdfUrl(editedPdfUrl);
          }
          );
        }

        // set fields for deleted page
        setTextFields((prev) => {
          let settledTextFields = [...prev.filter((tField) => tField?.pageNumber !== pageNumberToDelete)].map((elem) => {
            if (elem?.pageNumber > pageNumberToDelete) {
              return { ...elem, pageNumber: elem.pageNumber - 1 };
            }
            return elem;
          })
          return settledTextFields;
        });

        setCheckboxFields((prev) => {
          let settledCheckFields = [...prev.filter((cField) => cField?.pageNumber !== pageNumberToDelete)].map((elem) => {
            if (elem?.pageNumber > pageNumberToDelete) {
              return { ...elem, pageNumber: elem.pageNumber - 1 };
            }
            return elem;
          })
          return settledCheckFields;
        });

        setLogoFields((prev) => {
          let settledLogoFields = [...prev.filter((logoField) => logoField?.pageNumber !== pageNumberToDelete)].map((elem) => {
            if (elem?.pageNumber > pageNumberToDelete) {
              return { ...elem, pageNumber: elem.pageNumber - 1 };
            }
            return elem;
          })
          return settledLogoFields;
        });

        setSignatureFields((prev) => {
          let settledSignatureFields = [...prev.filter((signField) => signField?.page_number !== pageNumberToDelete)].map((elem) => {
            if (elem?.page_number > pageNumberToDelete) {
              return { ...elem, page_number: elem.page_number - 1 };
            }
            return elem;
          })
          return settledSignatureFields;
        });

        setFormFields((prev) => {
          let settledFormFields = [...prev.filter((fField) => fField?.pageNumber !== pageNumberToDelete)].map((elem) => {
            if (elem?.pageNumber > pageNumberToDelete) {
              return { ...elem, pageNumber: elem.pageNumber - 1 };
            }
            return elem;
          })
          return settledFormFields;
        });

      }
    }).catch((err) => console.log(err));
  };

  /**
   * Call Reset pdf version API and reload pdf in editor with response pdf link
   */
  const onResetPdfVersion = () => {
    Swal.fire({
      title: "Are you sure!",
      text: "Resetting a PDF will remove your updated data from it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      // cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          TransactionService.resetPdfEditorVersion({
            id: propData.documentId,
            transaction_id: propData.transactionId,
            activity_title: propData.documentName,
            activity_task_type: `pdf_reset`,
            activity_action_by: logged_user.id,
            activity_document_link: editorPdf
              ? editorPdf.split("/")[editorPdf.split("/").length - 1]
              : null,
          })
            .then((response) => {
              let bodydata = {
                mode: "preview"
              }
              if (propData.documentName === "Custom E-Sign Transaction Template") {
                bodydata = { ...bodydata, transaction_edoc_id: propData.documentId }
              }
              if (response.status == 200) {
                TransactionService.getPreview(
                  propData.documentName,
                  propData.transactionId,
                  bodydata
                )
                  .then((response) => {
                    dispatch(setLoadingStatus(false));
                    if (response.data && response.data.url) {
                      onPreviousPage();
                    } else {
                      if (propData.documentName === "Custom E-Sign Transaction Template") {
                        setEditorPdf("")
                      }
                    }
                  })
                  .catch((error) => {
                    toast.error("Error while reset PDF!");
                    dispatch(setLoadingStatus(false));
                    console.log(error);
                  });
                toast.success("PDF reset successfully.");
              } else {
                toast.error("Something went wrong!");
              }
            })
            .catch((error) => {
              dispatch(setLoadingStatus(false));
              toast.error("Cannot reset PDF!");
              console.log(error);
            });
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.log(error);
        }
      }
    });
  };

  /*
   * call refresh
   */
  // const onRefresh = () => {
  //   // formData.current = [];
  //   // dispatch(setLoadingStatus(true)).then((ews)=>{dispatch(setLoadingStatus(false))})

  //   Swal.fire({
  //     title: "Are you sure!",
  //     text: "Refresh all updated data.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     // cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       formData.current = [];
  //       setRefresh(prev => !prev)
  //     }
  //   });
  // };
  //Download pdf :
  const urlValue = editorPdf;
  const downloadPDF = async () => {
    // Fetch the PDF file using the URL provided in 'urlValue'
    let existingPdfBytes = await fetch(urlValue).then((res) =>
      res.arrayBuffer()
    );

    if (mergedPdfUrl) {
      existingPdfBytes = await fetch(mergedPdfUrl).then((res) =>
        res.arrayBuffer()
      );
    }
    // Load the PDF document from the fetched bytes
    let pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Access the form in the PDF
    const form = pdfDoc.getForm();

    // Apply all stored field updates before downloading
    Object.entries(updatedValuesRef.current).forEach(([fieldName, value]) => {
      const field = form.getTextField(fieldName);
      if (field) {
        field.setText(value);
      } else {
        console.warn(`Field "${fieldName}" not found in PDF.`);
      }
    });

    // Get all form fields in the PDF
    let fields = form.getFields();

    // Iterate over the form fields and update them with data from 'formData'
    await Promise.all(
      fields.map(async (field) => {
        const key = field.getName();
        const updateData = formData.current.find(
          (elem) => elem.fieldName === key
        );

        if (updateData) {
          switch (updateData.fieldType) {
            case "text":
              // Update a text field
              field.setText(updateData.fieldValue.toString());
              break;
            case "checkbox":
              // Update a checkbox field
              const kids = createPDFAcroFields(field.acroField.Kids()).map((_) => _[0]);
              kids.forEach((kid) => {
                if (updateData.fieldValue === true) {
                  field.check(); // Check the checkbox
                  kid.setValue(kid.getOnValue()); // Check that particular checkbox.
                } else {
                  field.uncheck(); // Check the checkbox
                  kid.setValue(PDFName.of("Off")); // Uncheck the checkbox
                }
              });
              break;
            default:
              break;
          }
        }
      })
    );

    // Get page and page height
    const page = await pdfDoc.getPage(0); // Get the first page
    const pageHeight = page.getHeight();

    // Add custom text fields
    textFields.forEach((field) => {
      const textField = form.createTextField(`textbox_${field.id}`);
      textField.setText(field.text);
      textField.enableMultiline() // show multiline text
      // Set font size and font in the DA (default appearance) string
      const da = textField.acroField.getDefaultAppearance() ?? '';
      const newDa = da + '\n' + setFontAndSize('Courier', Number(field?.fontSize)).toString(); //setFontAndSize() method came to resuce
      textField.acroField.setDefaultAppearance(newDa);

      // Conditional background color based on field?.backgroundTransparent
      const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);  // Transparent if true, red if false

      // Apply scaling to Y position and X position for text fields
      const adjustedY = pageHeight - (field.y) - (field.initialHeight);  // Adjust Y position based on scaling
      textField.addToPage(pdfDoc.getPage(field.pageNumber - 1 ?? 0), {
        x: field.x,  // Adjust X position based on scaling
        y: adjustedY,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        borderColor: undefined,
        width: field?.initialWidth,  // Scale width by zoom factor
        height: field?.initialHeight,
        initialWidth: field?.initialWidth,
        initialHeight: field?.initialHeight
      });
    });

    // Add custom form fields
    formFields.forEach((field) => {
      const formField = form.createTextField(`textbox_${field?.uniqueId}`);
      formField.setText(String(field.text));
      formField.enableMultiline() // show multiline text
      // Set font size and font in the DA (default appearance) string
      const da = formField.acroField.getDefaultAppearance() ?? '';
      const newDa = da + '\n' + setFontAndSize('Courier', Number(field?.fontSize)).toString(); //setFontAndSize() method came to resuce
      formField.acroField.setDefaultAppearance(newDa);

      // Conditional background color based on field?.backgroundTransparent
      const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);  // Transparent if true, red if false

      // Apply scaling to Y position and X position for text fields
      const adjustedY = pageHeight - (field.y) - (field.initialHeight);  // Adjust Y position based on scaling
      formField.addToPage(pdfDoc.getPage(field.pageNumber - 1 ?? 0), {
        x: field.x,  // Adjust X position based on scaling
        y: adjustedY,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        borderColor: undefined,
        width: field?.initialWidth,  // Scale width by zoom factor
        height: field?.initialHeight,
        initialWidth: field?.initialWidth,
        initialHeight: field?.initialHeight
      });
    });

    // ----custom checbox images
    await Promise.all(checkboxFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position

      // Determine the icon URL based on the checked status of the checkbox
      let iconUrl = field.checked === 0
        ? field?.background ? '/images/check-box-solid-tick.png' : '/images/check-box-tick.png'  // Checked icon
        : field?.background ? '/images/check-box-solid-cross.png' : '/images/check-box-cross.png'; // Unchecked icon
      if (field.checked === 2) {
        if (field?.background) {
          iconUrl = "/images/check-box-solid-white.png"
        } else {
          iconUrl = ""
        }
      }

      if (iconUrl) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(iconUrl).then(res => res.arrayBuffer());

        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = await pdfDoc.embedPng(iconBytes);

        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height)
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
        });
      }
    }));
    // ----custom logo images
    await Promise.all(logoFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position
      if (field.logo && field.file.type) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(field.logo).then(res => res.arrayBuffer());
        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = field.file.type == "image/png" ?
          await pdfDoc.embedPng(iconBytes) : await pdfDoc.embedJpg(iconBytes)
        // Conditional background color based on field?.backgroundTransparent
        const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);
        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height),
          backgroundColor: backgroundColor,
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
          opacity: 1
        });
      }
    }));
    // Save the modified PDF as base64 data
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    // Generate a unique PDF name (UUID)
    const pdfName = uuidv4();

    // Create a download link for the PDF
    const downloadLink = document.createElement("a");
    const fileName = `${pdfName}.pdf`;
    downloadLink.href = pdfBytes;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  async function updatePdfField(updatedValue, fieldName, url, fieldsData) {
    let keyForUpdate = Object.keys(fieldsData)?.[0];

    let updateValuesArr = templateFields?.reduce((acc, e) => {
      if (e.value === keyForUpdate && !e.is_multi_field) {
        acc.push(e.key);
      }
      return acc;
    }, []);
    try {
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

      // Load the existing PDF
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Get the form
      const form = pdfDoc.getForm();

      [...new Set([...updateValuesArr, fieldName])]?.map((fields) => {
        // Store the new value in the persistent state
        updatedValuesRef.current[fields] = updatedValue;
      })

      // Apply all stored values to their respective fields
      Object.entries(updatedValuesRef.current).forEach(([name, value]) => {
        const field = form.getTextField(name);
        if (field) {
          field.setText(String(value));
        } else {
          console.warn(`Field "${name}" not found in PDF.`);
        }
      });

      // Serialize the updated PDF
      const pdfBytes = await pdfDoc.save();

      // Create a new Blob and update the state
      const updatedPdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const PdfUrl = URL.createObjectURL(updatedPdfBlob);

      // Update your state without losing previous changes
      updatedPdfUrl.current = { url: PdfUrl };

    } catch (error) {
      console.error("Error updating PDF field:", error);
    }
  }

  const savePDF = async (singleEdit = false) => {
    dispatch(setLoadingStatus(true));
    // Fetch the PDF file using the URL provided in 'urlValue'
    let existingPdfBytes = await fetch(urlValue).then((res) =>
      res.arrayBuffer()
    );

    if (mergedPdfUrl) {
      existingPdfBytes = await fetch(mergedPdfUrl).then((res) =>
        res.arrayBuffer()
      );
    }
    // Load the PDF document from the fetched bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Access the form in the PDF
    const form = pdfDoc.getForm();

    // Apply all stored field updates before downloading
    Object.entries(updatedValuesRef.current).forEach(([fieldName, value]) => {
      const field = form.getTextField(fieldName);
      if (field) {
        field.setText(value);
      } else {
        console.warn(`Field "${fieldName}" not found in PDF.`);
      }
    });

    // Get all form fields in the PDF
    let fields = form.getFields();

    // Iterate over the form fields and update them with data from 'formData'
    await Promise.all(
      fields.map(async (field) => {
        const key = field.getName();
        const updateData = formData.current.find(
          (elem) => elem.fieldName === key
        );

        if (updateData) {
          switch (updateData.fieldType) {
            case "text":
              // Update a text field
              field.setText(updateData.fieldValue.toString());
              break;
            case "checkbox":
              // Update a checkbox field
              const kids = createPDFAcroFields(field.acroField.Kids()).map(
                (_) => _[0]
              );
              kids.forEach((kid) => {
                if (updateData.fieldValue === true) {
                  field.check(); // Check the checkbox
                  kid.setValue(kid.getOnValue()); // Check that particular checkbox.
                } else {
                  field.uncheck(); // Check the checkbox
                  kid.setValue(PDFName.of("Off")); // Uncheck the checkbox
                }
              });
              break;

            default:
              break;
          }
        }
      })
    );

    // for custom text boxes
    const page = await pdfDoc.getPage(0); // Get the first page
    const pageHeight = page.getHeight();
    // Add custom text fields
    textFields.forEach((field) => {
      const textField = form.createTextField(`textbox_${field.id}`);
      textField.setText(field.text);
      textField.enableMultiline() // show multiline text
      // Set font size and font in the DA (default appearance) string
      const da = textField.acroField.getDefaultAppearance() ?? '';
      const newDa = da + '\n' + setFontAndSize('Courier', Number(field?.fontSize)).toString(); //setFontAndSize() method came to resuce
      textField.acroField.setDefaultAppearance(newDa);

      // Conditional background color based on field?.backgroundTransparent
      const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);  // Transparent if true

      // Apply scaling to Y position and X position for text fields
      const adjustedY = pageHeight - (field.y) - (field.initialHeight);  // Adjust Y position based on scaling
      textField.addToPage(pdfDoc.getPage(field.pageNumber - 1 ?? 0), {
        x: field.x,  // Adjust X position based on scaling
        y: adjustedY,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        borderColor: undefined,
        width: field?.initialWidth,  // Scale width by zoom factor
        height: field?.initialHeight,
        initialWidth: field?.initialWidth,
        initialHeight: field?.initialHeight
      });
    });

    // Add custom form fields
    formFields.forEach((field) => {
      const formField = form.createTextField(`textbox_${field?.uniqueId}`);
      formField?.setText(String(field.text));
      formField?.enableMultiline() // show multiline text
      // Set font size and font in the DA (default appearance) string
      const da = formField?.acroField.getDefaultAppearance() ?? '';
      const newDa = da + '\n' + setFontAndSize('Courier', Number(field?.fontSize)).toString(); //setFontAndSize() method came to resuce
      formField?.acroField.setDefaultAppearance(newDa);

      // Conditional background color based on field?.backgroundTransparent
      const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);  // Transparent if true, red if false

      // Apply scaling to Y position and X position for text fields
      const adjustedY = pageHeight - (field.y) - (field.initialHeight);  // Adjust Y position based on scaling
      formField?.addToPage(pdfDoc?.getPage(field?.pageNumber - 1 ?? 0), {
        x: field.x,  // Adjust X position based on scaling
        y: adjustedY,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        borderColor: undefined,
        width: field?.initialWidth,  // Scale width by zoom factor
        height: field?.initialHeight,
        initialWidth: field?.initialWidth,
        initialHeight: field?.initialHeight
      });
    });

    // ----custom checbox images
    await Promise.all(checkboxFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position

      // Determine the icon URL based on the checked status of the checkbox
      let iconUrl = field.checked === 0
        ? field?.background ? '/images/check-box-solid-tick.png' : '/images/check-box-tick.png'  // Checked icon
        : field?.background ? '/images/check-box-solid-cross.png' : '/images/check-box-cross.png'; // Unchecked icon
      if (field.checked === 2) {
        if (field?.background) {
          iconUrl = "/images/check-box-solid-white.png"
        } else {
          iconUrl = ""
        }
      }

      if (iconUrl) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(iconUrl).then(res => res.arrayBuffer());

        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = await pdfDoc.embedPng(iconBytes);

        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height)
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
        });
      }
    }));

    await Promise.all(logoFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position

      // Determine the icon URL based on the checked status of the checkbox
      let iconUrl = field.checked === 0
        ? field?.background ? '/images/check-box-solid-tick.png' : '/images/check-box-tick.png'  // Checked icon
        : field?.background ? '/images/check-box-solid-cross.png' : '/images/check-box-cross.png'; // Unchecked icon
      if (field.checked === 2) {
        if (field?.background) {
          iconUrl = "/images/check-box-solid-white.png"
        } else {
          iconUrl = ""
        }
      }
      if (field.logo && field.file.type) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(field.logo).then(res => res.arrayBuffer());
        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = field.file.type == "image/png" ?
          await pdfDoc.embedPng(iconBytes) : await pdfDoc.embedJpg(iconBytes)
        // Conditional background color based on field?.backgroundTransparent
        const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);
        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height),
          backgroundColor: backgroundColor,
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
          opacity: 1
        });
      }
    }));
    // Save the modified PDF as base64 data
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    // Convert the base64 PDF data to a Buffer
    const base64Data = Buffer.from(
      pdfBytes.replace(/^data:application\/pdf;base64,/, ""), // Assuming pdfBytes is a data URL for a PDF
      "base64"
    );
    if (singleEdit) {
      singleSaveDocumentVersion(base64Data);
    } else {
      onSaveDocumentVersion(base64Data);
    }
    dispatch(setLoadingStatus(false));
  };

  // function for save directly changed field values in db 
  const savePdfField = async (fieldsData, updatedValue, fieldName, url) => {
    // dispatch(setLoadingStatus(true));    
    try {
      setModalLoading(true); // show loader on save button
      const res = await TransactionService?.updateTransactionFields(propData?.transactionId, fieldsData);
      if (res?.status === 200) {
        setModalPosition({ x: null, y: null, id: null }); // remove modal after save
        let keyForUpdate = Object.keys(fieldsData)?.[0];
        let valueForUpdate = Object.values(fieldsData)?.[0];
        dispatch(updatePdfEditorField(priceFields?.includes(keyForUpdate) ? { [keyForUpdate]: getPrice(valueForUpdate) } : fieldsData));
        await updatePdfField(priceFields?.includes(keyForUpdate) ? getPrice(updatedValue) : updatedValue, fieldName, url, fieldsData)
        toast.success("Your PDF updated successfully.");
        setModalLoading(false);
        return { isResponse: res?.status, data: fieldsData };
      } else {
        setModalLoading(false);
        toast.error("Cannot update PDF right now!");
        return { isResponse: false, data: fieldsData };
      }
    } catch (error) {
      setModalLoading(false);
      toast.error("Cannot update PDF right now!");
      // dispatch(setLoadingStatus(false));
      console.log(error);
      return { isResponse: false, data: fieldsData };
    }
  };

  // function for reflect edited values on fields after field modal save 
  const reflectPdfEdit = async (eventFieldName, eventFieldValue, eventFieldType) => {
    // Create a payload object to represent the field's name, value, and type
    const payload = {
      fieldName: eventFieldName,
      fieldValue: eventFieldValue,
      fieldType: eventFieldType === "textarea" ? "text" : eventFieldType,
    };

    // Check if the field is already in the formData array
    const prefilled = formData.current.find(
      (elem) => elem.fieldName === eventFieldName
    );

    // Update the formData array with the new or updated field data
    if (prefilled) {
      // setFormData((formData) =>
      //   formData.map((data) => (data.fieldName === fieldName ? payload : data))
      // );
      // updatePresent((state)=> state.map((data) => (data.fieldName === fieldName ? payload : data)))
      formData.current = formData.current.map((data) =>
        data.fieldName === eventFieldName ? payload : data
      );
    } else {
      // If the field is not in formData, add it to the array
      // setFormData((formData) => [...formData, payload]);
      // updatePresent((state)=>[...state,payload])
      formData.current = [...formData.current, payload];
    }

    // // Fetch the PDF file using the URL provided in 'urlValue'
    // let existingPdfBytes = await fetch(urlValue).then((res) =>
    //   res.arrayBuffer()
    // );

    // if (mergedPdfUrl) {
    //   existingPdfBytes = await fetch(mergedPdfUrl).then((res) =>
    //     res.arrayBuffer()
    //   );
    // }
    // // Load the PDF document from the fetched bytes
    // let pdfDoc = await PDFDocument.load(existingPdfBytes);

    // // Access the form in the PDF
    // const form = pdfDoc.getForm();

    // // Get all form fields in the PDF
    // let fields = form.getFields();

    // // Iterate over the form fields and update them with data from 'formData'
    // await Promise.all(
    //   fields.map(async (field) => {
    //     const key = field.getName();
    //     const updateData = formData.current.find(
    //       (elem) => elem.fieldName === key
    //     );

    //     if (updateData) {
    //       switch (updateData.fieldType) {
    //         case "text":
    //           // Update a text field
    //           field.setText(updateData.fieldValue.toString());
    //           break;
    //         default:
    //           break;
    //       }
    //     }
    //   })
    // );
    // // Save the modified PDF document
    // const modifiedPdfBytes = await pdfDoc.save();

    // // Create a Blob from the modified PDF
    // const pdfBlob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

    // // Generate a Blob URL
    // const blobUrl = URL.createObjectURL(pdfBlob);

    // setEditorPdf(blobUrl); // reflect changes on pdf

    // setRefresh((prev) => !prev);
    // refreshContent(true);        // for reload whole doc 
  }

  // function for get updated pdf before merge with other pdf
  const getEditedPdfUrl = async (urlValue) => {
    // Fetch the PDF file using the URL provided in 'urlValue'
    let editedPdfBytes = await fetch(urlValue).then((res) =>
      res.arrayBuffer()
    );

    // Load the PDF document from the fetched bytes
    let pdfDoc = await PDFDocument.load(editedPdfBytes);

    // Access the form in the PDF
    const form = pdfDoc.getForm();

    // Get all form fields in the PDF
    let fields = form.getFields();

    // Iterate over the form fields and update them with data from 'formData'
    await Promise.all(
      fields.map(async (field) => {
        const key = field.getName();
        const updateData = formData.current.find(
          (elem) => elem.fieldName === key
        );

        if (updateData) {
          switch (updateData.fieldType) {
            case "text":
              // Update a text field
              field.setText(updateData.fieldValue.toString());
              break;
            case "checkbox":
              // Update a checkbox field
              const kids = createPDFAcroFields(field.acroField.Kids()).map((_) => _[0]);
              kids.forEach((kid) => {
                if (updateData.fieldValue === true) {
                  field.check(); // Check the checkbox
                  kid.setValue(kid.getOnValue()); // Check that particular checkbox.
                } else {
                  field.uncheck(); // Check the checkbox
                  kid.setValue(PDFName.of("Off")); // Uncheck the checkbox
                }
              });
              break;

            default:
              break;
          }
        }
      })
    );

    // Save the modified PDF as base64 data
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    // Removes 'data:application/pdf;base64,' if included
    const base64String = pdfBytes.split(',')[1];

    // Decode the Base64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Generate an object URL from the Blob
    const pdfUrl = URL.createObjectURL(blob);

    return pdfUrl;

  }

  const refreshContent = async (isReload) => {
    // refresh and reload every edition like default and called preview api for get updated data on document
    if (isReload) {
      dispatch(setLoadingStatus(true));
      let id = propData.transactionId;
      const dataTransaction = (await TransactionService.getTransactionEsignById(id)).data;
      switch (propData.documentName) {
        case "Listing Agreement Exclusion":
        case "Listing Agreement Addendum":
          id = propData.documentId;
          break;
        case "Purchase & Sale Agreement Exclusion":
        case "Purchase & Sale Agreement Addendum":
        case "IYBA Acceptance of Vessel":
          id = `${propData.documentId}`;
          break;
        case "Seller Closing Statement":
          const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
          id = dataSellerClosing?.id || id;
          break;
        case "Buyer Closing Statement":
          const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
          id = dataBuyerClosing?.id || id;
          break;
        case "Trade-In Vessel":
          const dataTradeInVessel = dataTransaction?.t_trade_in_vessel ? dataTransaction?.t_trade_in_vessel : null;
          id = dataTradeInVessel?.id || id;
          break;
        case "CYBA Counter Offer":
        case "Bill of Sale - Notary":
        case "Bill of Sale - Notary (Seller Only)":
        case "YBAA Amendment to PSA":
        case "YBAA Amendment to PSA (Short Form)":
        case "Counter Offer":
        case "Custom E-Sign Transaction Template":
        case "NYBA Counter Offer":
          id = `${propData.transactionId}/${propData.documentId}`;
          break;
        default:
          id = propData.transactionId;
      }
      TransactionService.getPreview(propData.documentName, id, { module: "preview" }).then(
        (response) => {
          const updatedLink = response.data.url;
          setTimeStamp(new Date().getTime());
          if (!mergedPdfUrl) {
            setFiles([]);
            setEditorPdf(`${updatedLink}?tm=${timeStamp}`);
            setMergedPdfUrl(propData?.is_pdf_merge ? `${updatedLink}?tm=${timeStamp}` : null);
          }
          formData.current = [];
          // setTextFields([]);
          // setCheckboxFields([]);
          // setLogoFields([]);
          // setSignatureFields([]);
          // setFormFields([]);
          setFieldTrackArr([]);
          setFieldTrackObj({});
          setReloadKey((prev) => prev + 1);
          setRefresh((prev) => !prev);
          dispatch(setLoadingStatus(false));
        }).catch((error) => {
          dispatch(setLoadingStatus(false));
        });
    }
  }

  // function for track change of draggable form fields
  const handleFormFieldsChange = (editedFieldValue, fieldId, formName, editedFieldName = "") => {
    switch (formName) {
      case "VesselFields":
        setVesselFields((prev) => {
          const updatedVesselFields = prev.map((vField) => vField.fieldId === fieldId ? { ...vField, fieldValue: editedFieldValue } : vField);
          setUpdatedFormFields(() => [updatedVesselFields?.find((uvField) => uvField.fieldId === fieldId)]);
          return updatedVesselFields;
        });
        break;

      case "BuyerFields":
        setBuyerFields((prev) => {
          const updatedBuyerFields = prev.map((bField) => bField.fieldId === fieldId ? { ...bField, fieldValue: editedFieldValue } : bField);
          setUpdatedFormFields(() => [updatedBuyerFields?.find((ubField) => ubField.fieldId === fieldId)]);
          return updatedBuyerFields;
        });
        break;

      case "CoBuyerFields":
        setCoBuyerFields((prev) => {
          const updatedCoBuyerFields = prev.map((cbField) => cbField.fieldId === fieldId ? { ...cbField, fieldValue: editedFieldValue } : cbField);
          setUpdatedFormFields(() => [updatedCoBuyerFields?.find((ucbField) => ucbField.fieldId === fieldId)]);
          return updatedCoBuyerFields;
        });
        break;

      case "SellerFields":
        setSellerFields((prev) => {
          const updatedSellerFields = prev.map((sField) => sField.fieldId === fieldId ? { ...sField, fieldValue: editedFieldValue } : sField);
          setUpdatedFormFields(() => [updatedSellerFields?.find((usField) => usField.fieldId === fieldId)]);
          return updatedSellerFields;
        });
        break;

      case "CoSellerFields":
        setCoSellerFields((prev) => {
          const updatedCoSellerFields = prev.map((csField) => csField.fieldId === fieldId ? { ...csField, fieldValue: editedFieldValue } : csField);
          setUpdatedFormFields(() => [updatedCoSellerFields?.find((ucsField) => ucsField.fieldId === fieldId)]);
          return updatedCoSellerFields;
        });
        break;

      case "TradeInVesselFields":
        setTradeInVesselFields((prev) => {
          const updatedTradeInVesselFields = prev.map((tvField) => tvField.fieldId === fieldId ? { ...tvField, fieldValue: editedFieldValue } : tvField);
          setUpdatedFormFields(() => [updatedTradeInVesselFields?.find((utvField) => utvField.fieldId === fieldId)]);
          return updatedTradeInVesselFields;
        });
        break;

      case "OfferFields":
        setOfferFields((prev) => {
          // for normal fiels which is not dependancy for other fields calculation
          if (!(["Less Deposit *", "Total Purchase Price *"].includes(editedFieldName))) {
            const updatedOfferFields = prev.map((offerField) => offerField?.fieldId === fieldId ? { ...offerField, fieldValue: editedFieldValue } : offerField);
            setUpdatedFormFields(() => [updatedOfferFields?.find((uoField) => uoField.fieldId === fieldId)]);
            return updatedOfferFields;
          }

          // when total purchase price change then balance and purchase price will also change
          if (editedFieldName === "Total Purchase Price *") {
            const updatedOfferFields = prev.map((offerField) => {
              if (offerField.fieldId === fieldId) {
                return { ...offerField, fieldValue: editedFieldValue };
              }
              // Purchase Price = total purchase price - purchase price additional units
              if (offerField?.fieldName === "Purchase Price (Vessel)") {
                let newPurchasePriceVessel = (Number(editedFieldValue) - offerFields[6]?.fieldValue) || 0;
                return { ...offerField, fieldValue: newPurchasePriceVessel };
              }
              // Less Deposit = total purchase price / 10
              if (offerField?.fieldName === "Less Deposit *") {
                let newLessDeposit = (Number(editedFieldValue) / 10) || 0;
                return { ...offerField, fieldValue: newLessDeposit };
              }
              // Balance = total purchase price - less deposit - less trade allowance
              if (offerField?.fieldName === "Balance") {
                let newBalanceDue = (Number(editedFieldValue) - ((Number(editedFieldValue) / 10) + offerFields[8]?.fieldValue)) || 0;
                return { ...offerField, fieldValue: newBalanceDue };
              }
              return offerField;
            });
            // add updated four fields for particular case
            setUpdatedFormFields(() => [updatedOfferFields[5], updatedOfferFields[7], updatedOfferFields[9], updatedOfferFields[10]]);
            return updatedOfferFields;
          }

          // when less deposit change then balance value will change 
          if (editedFieldName === "Less Deposit *") {
            const updatedOfferFields = prev.map((offerField) => {
              if (offerField.fieldId === fieldId) {
                return { ...offerField, fieldValue: editedFieldValue };
              }
              // Balance = total purchase price - less deposit - less trade allowance
              if (offerField?.fieldName === "Balance") {
                let newBalanceDue = (offerFields[9]?.fieldValue - (Number(editedFieldValue) + offerFields[8]?.fieldValue)) || 0;
                return { ...offerField, fieldValue: newBalanceDue };
              }
              return offerField;
            });
            // add updated four fields for particular case
            setUpdatedFormFields(() => [updatedOfferFields[7], updatedOfferFields[10]]);
            return updatedOfferFields;
          }
        });
        break;

      case "coBrokerDealSideFields":
        setCoBrokerFields((prev) => {
          const updatedCoBrokerDealSideFields = prev[0]?.map((dField) => dField.fieldId === fieldId ? { ...dField, fieldValue: editedFieldValue } : dField);
          setUpdatedFormFields(() => [updatedCoBrokerDealSideFields?.find((ueField) => ueField.fieldId === fieldId)]);
          return [updatedCoBrokerDealSideFields, prev[1]];
        });
        break;

      case "coBrokerOtherSideFields":
        setCoBrokerFields((prev) => {
          const updatedCoBrokerOtherSideFields = prev[1]?.map((oField) => oField.fieldId === fieldId ? { ...oField, fieldValue: editedFieldValue } : oField);
          setUpdatedFormFields(() => [updatedCoBrokerOtherSideFields?.find((ueField) => ueField.fieldId === fieldId)]);
          return [prev[0], updatedCoBrokerOtherSideFields];
        });
        break;

      case "ExclusionFields":
        setExclusionFields((prev) => {
          const updatedExclusionFields = prev?.map((eField) => eField.fieldId === fieldId ? { ...eField, fieldValue: editedFieldValue } : eField);
          setUpdatedFormFields(() => [updatedExclusionFields?.find((ueField) => ueField.fieldId === fieldId)]);
          return updatedExclusionFields;
        });
        break;

      default:
        break;
    }
  }

  // Define function for cancle form data changes 
  const onFormFieldsChangesCancle = (formNameKeyToCancle) => {
    dispatch(setLoadingStatus(true));
    setFormFieldsEdit((prev) => ({ ...prev, [formNameKeyToCancle]: false }));
    dispatch(setLoadingStatus(false));
  }

  // Define function for save form data on database
  const onFormFieldsChangesSave = (apiStepDb) => {
    Swal.fire({
      title: 'Attention!',
      html: `Changes in form data will remove all editions and reload the document.`,
      icon: "warning",
      showCancelButton: true,
      // cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'Okay',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));

        let stepMain = {
          id: propData.transactionId,
          broker_user_id: dataBroker?.id,
          company_id: dataBroker?.company_id,
          company_office_id: dataBroker?.company_office_id,
          createdBy: logged_user?.id,
          updatedBy: logged_user?.id
        };
        let transactionPayload = {
          main: {
            ...getPlainTransactionData(dataDeal),
            task_type: "deal",
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
          },
          isBelonged: true,
          // foreignkeyName: "transaction_vessel_id",
        };

        // make payload changes for individual forms
        switch (apiStepDb) {
          case "vessel":
            // get updated vessel data for db
            const vesselHIN = vesselFields.find((prev) => prev.fieldName === "HIN *")?.fieldValue;
            const vesselStock = vesselFields.find((prev) => prev.fieldName === "Stock")?.fieldValue;
            // const vesselMake = vesselFields.find((prev) => prev.fieldName === "Manufacturer/Builder *")?.fieldValue;
            const vesselYear = vesselFields.find((prev) => prev.fieldName === "Year *")?.fieldValue;
            const vesselModel = vesselFields.find((prev) => prev.fieldName === "Model")?.fieldValue;
            const vesselLength = vesselFields.find((prev) => prev.fieldName === "Length *")?.fieldValue;
            const vesselName = vesselFields.find((prev) => prev.fieldName === "Name")?.fieldValue;
            // const vesselGrossPrice = vesselFields.find((prev) => prev.fieldName === "Gross Price")?.fieldValue;
            // const vesselCategory = vesselFields.find((prev) => prev.fieldName === "Category")?.fieldValue;
            const vesselDocNo = vesselFields.find((prev) => prev.fieldName === "USCG/Official/Doc#")?.fieldValue;
            const vesselRegNo = vesselFields.find((prev) => prev.fieldName === "Reg No")?.fieldValue;
            const vesselTitleNo = vesselFields.find((prev) => prev.fieldName === "Title No")?.fieldValue;

            stepMain = {

              ...stepMain,
              id: dataVessel?.id,
              hull_number: vesselHIN,
              stock: vesselStock,
              // make: vesselMake,
              year: Number(vesselYear),
              model: vesselModel,
              length: Number(vesselLength),
              vessel_name: vesselName,
              // total_purchase_price: Number(vesselGrossPrice),
              // boat_category: vesselCategory,
              doc_no: vesselDocNo,
              reg_no: vesselRegNo,
              title_no: vesselTitleNo,
            }

            transactionPayload = {
              ...transactionPayload,
              foreignkeyName: "transaction_vessel_id"
            }

            break;

          case "buyer":
            if (dataDeal?.buyer_type === "LEGAL_OWNER" || dataDeal?.buyer_type === "LEGAL_AND_OR_ASSIGN") {
              // get updated buyer data for db
              const buyerFirstName = buyerFields.find((prev) => prev.fieldName === "First Name *")?.fieldValue;
              const buyerMiddleName = buyerFields.find((prev) => prev.fieldName === "Middle Name")?.fieldValue;
              const buyerLastName = buyerFields.find((prev) => prev.fieldName === "Last Name *")?.fieldValue;
              const buyerEmail = buyerFields.find((prev) => prev.fieldName === "Email *")?.fieldValue;
              const buyerMobilePhone = buyerFields.find((prev) => prev.fieldName === "Mobile Phone *")?.fieldValue;
              const buyerCitizenOf = buyerFields.find((prev) => prev.fieldName === "Citizen Of")?.fieldValue;
              const buyerAddress1 = buyerFields.find((prev) => prev.fieldName === "Address *")?.fieldValue;
              const buyerAddress2 = buyerFields.find((prev) => prev.fieldName === "Address 2")?.fieldValue;
              const buyerAddress3 = buyerFields.find((prev) => prev.fieldName === "Address 3")?.fieldValue;
              // const buyerCountry = buyerFields.find((prev) => prev.fieldName === "Country")?.fieldValue;
              const buyerCity = buyerFields.find((prev) => prev.fieldName === "City *")?.fieldValue;
              // const buyerState = buyerFields.find((prev) => prev.fieldName === "State *")?.fieldValue;
              const buyerPostalCode = buyerFields.find((prev) => prev.fieldName === "Postal Code *")?.fieldValue;

              stepMain = {
                ...stepMain,
                id: dataDeal.buyer_contact_id,
                first_name: buyerFirstName,
                middle_name: buyerMiddleName,
                last_name: buyerLastName,
                email: buyerEmail,
                mobile_phone: buyerMobilePhone,
                nationality: buyerCitizenOf,
                address_1: buyerAddress1,
                address_2: buyerAddress2,
                address_3: buyerAddress3,
                // country: buyerCountry,
                city: buyerCity,
                // state: buyerState,
                postal_code: buyerPostalCode
              };
            } else if (dataDeal?.buyer_type === "CORPORATE_OWNED" || dataDeal?.buyer_type === "ENTITY_AND_OR_ASSIGN") {
              // get updated buyer data for db
              const buyerTrustName = buyerFields.find((prev) => prev.fieldName === "Corporation/LLC/Trust Name")?.fieldValue;
              const buyerTrustTaxId = buyerFields.find((prev) => prev.fieldName === "Tax Id")?.fieldValue;
              const buyerTrustAddress = buyerFields.find((prev) => prev.fieldName === "Address")?.fieldValue;
              const buyerTrustUnit = buyerFields.find((prev) => prev.fieldName === "Unit/Apt")?.fieldValue;
              // const buyerTrustCountry = buyerFields.find((prev) => prev.fieldName === "Country")?.fieldValue;
              const buyerTrustCity = buyerFields.find((prev) => prev.fieldName === "City")?.fieldValue;
              const buyerTrustState = buyerFields.find((prev) => prev.fieldName === "State")?.fieldValue;
              const buyerTrustZip = buyerFields.find((prev) => prev.fieldName === "Trust Zip")?.fieldValue;
              const buyerTrustJurisdictionOfOwner = buyerFields.find((prev) => prev.fieldName === "Jurisdiction Of Owner")?.fieldValue;
              const buyerTrustSignerName = buyerFields.find((prev) => prev.fieldName === "Signer Name")?.fieldValue;
              const buyerTrustSignerTitle = buyerFields.find((prev) => prev.fieldName === "Signer Title")?.fieldValue;
              const buyerTrustSignerPhone = buyerFields.find((prev) => prev.fieldName === "Signer Phone")?.fieldValue;
              const buyerTrustSignerEmail = buyerFields.find((prev) => prev.fieldName === "Signer Email")?.fieldValue;

              stepMain = {
                ...stepMain,
                id: dataDeal.buyer_contact_id,
                trust_name: buyerTrustName,
                trust_tax_id: buyerTrustTaxId,
                trust_address: buyerTrustAddress,
                trust_unit_apt: buyerTrustUnit,
                // trust_country: buyerTrustCountry,
                trust_city: buyerTrustCity,
                trust_state: buyerTrustState,
                trust_zip: buyerTrustZip,
                trust_jurisdiction_of_owner: buyerTrustJurisdictionOfOwner,
                trust_signer_name: buyerTrustSignerName,
                trust_signer_title: buyerTrustSignerTitle,
                trust_signer_phone: buyerTrustSignerPhone,
                trust_signer_email: buyerTrustSignerEmail
              };
            }

            transactionPayload = {
              ...transactionPayload,
              foreignkeyName: "buyer_contact_id"
            }
            break;

          case "seller":
            if (dataDeal?.seller_type === "OWNER_OF_RECORD") {
              const sellerEmail = sellerFields.find((prev) => prev.fieldName === "Email")?.fieldValue;
              stepMain = {
                ...stepMain,
                id: dataDeal.seller_contact_id,
                email: sellerEmail,
              };
            } else if (dataDeal?.seller_type === "CORPORATE_OWNED" || dataDeal?.seller_type === "LLC_OWNED" || dataDeal?.seller_type === "TRUST_OWNED") {
              // get updated buyer data for db
              const sellerTrustName = sellerFields.find((prev) => prev.fieldName === "Corporation/LLC/Trust Name *")?.fieldValue;
              const sellerTrustTaxId = sellerFields.find((prev) => prev.fieldName === "Tax Id")?.fieldValue;
              const sellerTrustAddress = sellerFields.find((prev) => prev.fieldName === "Address *")?.fieldValue;
              const sellerTrustUnit = sellerFields.find((prev) => prev.fieldName === "Unit/Apt")?.fieldValue;
              // const sellerTrustCountry = sellerFields.find((prev) => prev.fieldName === "Country")?.fieldValue;
              const sellerTrustCity = sellerFields.find((prev) => prev.fieldName === "City *")?.fieldValue;
              const sellerTrustState = sellerFields.find((prev) => prev.fieldName === "State *")?.fieldValue;
              const sellerTrustZip = sellerFields.find((prev) => prev.fieldName === "Trust Zip")?.fieldValue;
              const sellerTrustJurisdictionOfOwner = sellerFields.find((prev) => prev.fieldName === "Jurisdiction Of Owner")?.fieldValue;
              const sellerTrustSignerName = sellerFields.find((prev) => prev.fieldName === "Signer Name *")?.fieldValue;
              const sellerTrustSignerTitle = sellerFields.find((prev) => prev.fieldName === "Signer Title *")?.fieldValue;
              const sellerTrustSignerPhone = sellerFields.find((prev) => prev.fieldName === "Signer Phone")?.fieldValue;
              const sellerTrustSignerEmail = sellerFields.find((prev) => prev.fieldName === "Signer Email")?.fieldValue;

              stepMain = {
                ...stepMain,
                id: dataDeal.seller_contact_id,
                trust_name: sellerTrustName,
                trust_tax_id: sellerTrustTaxId,
                trust_address: sellerTrustAddress,
                trust_unit_apt: sellerTrustUnit,
                // trust_country: sellerTrustCountry,
                trust_city: sellerTrustCity,
                trust_state: sellerTrustState,
                trust_zip: sellerTrustZip,
                trust_jurisdiction_of_owner: sellerTrustJurisdictionOfOwner,
                trust_signer_name: sellerTrustSignerName,
                trust_signer_title: sellerTrustSignerTitle,
                trust_signer_phone: sellerTrustSignerPhone,
                trust_signer_email: sellerTrustSignerEmail
              };
            } else if (dataDeal?.seller_type === "LEGAL_OWNER" || dataDeal?.seller_type === "JOINT_OWNER") {
              // get updated buyer data for db
              const sellerFirstName = sellerFields.find((prev) => prev.fieldName === "First Name *")?.fieldValue;
              const sellerMiddleName = sellerFields.find((prev) => prev.fieldName === "Middle Name")?.fieldValue;
              const sellerLastName = sellerFields.find((prev) => prev.fieldName === "Last Name *")?.fieldValue;
              const sellerEmail = sellerFields.find((prev) => prev.fieldName === "Email *")?.fieldValue;
              const sellerMobilePhone = sellerFields.find((prev) => prev.fieldName === "Mobile Phone *")?.fieldValue;
              const sellerCitizenOf = sellerFields.find((prev) => prev.fieldName === "Citizen Of")?.fieldValue;
              const sellerAddress1 = sellerFields.find((prev) => prev.fieldName === "Address")?.fieldValue;
              const sellerAddress2 = sellerFields.find((prev) => prev.fieldName === "Address 2")?.fieldValue;
              const sellerAddress3 = sellerFields.find((prev) => prev.fieldName === "Address 3")?.fieldValue;
              // const sellerCountry = sellerFields.find((prev) => prev.fieldName === "Country")?.fieldValue;
              const sellerCity = sellerFields.find((prev) => prev.fieldName === "City")?.fieldValue;
              const sellerState = sellerFields.find((prev) => prev.fieldName === "State")?.fieldValue;
              const sellerPostalCode = sellerFields.find((prev) => prev.fieldName === "Postal Code")?.fieldValue;

              stepMain = {
                ...stepMain,
                id: dataDeal.seller_contact_id,
                first_name: sellerFirstName,
                middle_name: sellerMiddleName,
                last_name: sellerLastName,
                email: sellerEmail,
                mobile_phone: sellerMobilePhone,
                nationality: sellerCitizenOf,
                address_1: sellerAddress1,
                address_2: sellerAddress2,
                address_3: sellerAddress3,
                // country: sellerCountry,
                city: sellerCity,
                state: sellerState,
                postal_code: sellerPostalCode
              };
            }

            transactionPayload = {
              ...transactionPayload,
              foreignkeyName: "seller_contact_id"
            }
            break;

          case "cobuyer":
            // get updated co-buyer data for db
            const coBuyerFirstName = coBuyerFields.find((prev) => prev.fieldName === "First Name *")?.fieldValue;
            const coBuyerMiddleName = coBuyerFields.find((prev) => prev.fieldName === "Middle Name")?.fieldValue;
            const coBuyerLastName = coBuyerFields.find((prev) => prev.fieldName === "Last Name *")?.fieldValue;
            const coBuyerEmail = coBuyerFields.find((prev) => prev.fieldName === "Email *")?.fieldValue;
            const coBuyerMobilePhone = coBuyerFields.find((prev) => prev.fieldName === "Mobile Phone *")?.fieldValue;
            const coBuyerCitizenOf = coBuyerFields.find((prev) => prev.fieldName === "Citizen Of")?.fieldValue;
            const coBuyerAddress1 = coBuyerFields.find((prev) => prev.fieldName === "Address")?.fieldValue;
            const coBuyerAddress2 = coBuyerFields.find((prev) => prev.fieldName === "Address 2")?.fieldValue;
            const coBuyerAddress3 = coBuyerFields.find((prev) => prev.fieldName === "Address 3")?.fieldValue;
            // const coBuyerCountry = coBuyerFields.find((prev) => prev.fieldName === "Country")?.fieldValue;
            const coBuyerCity = coBuyerFields.find((prev) => prev.fieldName === "City")?.fieldValue;
            // const coBuyerState = coBuyerFields.find((prev) => prev.fieldName === "State")?.fieldValue;
            const coBuyerPostalCode = coBuyerFields.find((prev) => prev.fieldName === "Postal Code")?.fieldValue;

            stepMain = {
              ...stepMain,
              id: dataDeal.co_buyer_contact_id,
              first_name: coBuyerFirstName,
              middle_name: coBuyerMiddleName,
              last_name: coBuyerLastName,
              email: coBuyerEmail,
              mobile_phone: coBuyerMobilePhone,
              nationality: coBuyerCitizenOf,
              address_1: coBuyerAddress1,
              address_2: coBuyerAddress2,
              address_3: coBuyerAddress3,
              // country: coBuyerCountry,
              city: coBuyerCity,
              // state: coBuyerState,
              postal_code: coBuyerPostalCode
            };

            transactionPayload = {
              ...transactionPayload,
              foreignkeyName: "co_buyer_contact_id"
            }
            break;

          case "coseller":
            // get updated co-buyer data for db
            const coSellerFirstName = coSellerFields.find((prev) => prev.fieldName === "First Name *")?.fieldValue;
            const coSellerMiddleName = coSellerFields.find((prev) => prev.fieldName === "Middle Name")?.fieldValue;
            const coSellerLastName = coSellerFields.find((prev) => prev.fieldName === "Last Name *")?.fieldValue;
            const coSellerEmail = coSellerFields.find((prev) => prev.fieldName === "Email *")?.fieldValue;
            const coSellerMobilePhone = coSellerFields.find((prev) => prev.fieldName === "Mobile Phone *")?.fieldValue;
            const coSellerCitizenOf = coSellerFields.find((prev) => prev.fieldName === "Citizen Of")?.fieldValue;
            const coSellerAddress1 = coSellerFields.find((prev) => prev.fieldName === "Address")?.fieldValue;
            const coSellerAddress2 = coSellerFields.find((prev) => prev.fieldName === "Address 2")?.fieldValue;
            const coSellerAddress3 = coSellerFields.find((prev) => prev.fieldName === "Address 3")?.fieldValue;
            // const coSellerCountry = coSellerFields.find((prev) => prev.fieldName === "Country")?.fieldValue;
            const coSellerCity = coSellerFields.find((prev) => prev.fieldName === "City")?.fieldValue;
            // const coSellerState = coSellerFields.find((prev) => prev.fieldName === "State")?.fieldValue;
            const coSellerPostalCode = coSellerFields.find((prev) => prev.fieldName === "Postal Code")?.fieldValue;

            stepMain = {
              ...stepMain,
              id: dataDeal.co_seller_contact_id,
              first_name: coSellerFirstName,
              middle_name: coSellerMiddleName,
              last_name: coSellerLastName,
              email: coSellerEmail,
              mobile_phone: coSellerMobilePhone,
              nationality: coSellerCitizenOf,
              address_1: coSellerAddress1,
              address_2: coSellerAddress2,
              address_3: coSellerAddress3,
              // country: coSellerCountry,
              city: coSellerCity,
              // state: coSellerState,
              postal_code: coSellerPostalCode
            };

            transactionPayload = {
              ...transactionPayload,
              foreignkeyName: "co_seller_contact_id"
            }
            break;

          case "trade_in_vessel":
            // get updated vessel data for db
            const tVesselHIN = tradeInVesselFields.find((prev) => prev.fieldName === "HIN")?.fieldValue;
            const tVesselName = tradeInVesselFields.find((prev) => prev.fieldName === "Name")?.fieldValue;
            const tVesselStock = tradeInVesselFields.find((prev) => prev.fieldName === "Stock")?.fieldValue;
            const tVesselAllowance = tradeInVesselFields.find((prev) => prev.fieldName === "Trade In Value *")?.fieldValue;
            const tVesselLength = tradeInVesselFields.find((prev) => prev.fieldName === "Length *")?.fieldValue;
            // const tVesselCategory = tradeInVesselFields.find((prev) => prev.fieldName === "Category *")?.fieldValue;
            // const tVesselMake = tradeInVesselFields.find((prev) => prev.fieldName === "Mfr *")?.fieldValue;
            const tVesselModel = tradeInVesselFields.find((prev) => prev.fieldName === "Model *")?.fieldValue;
            const tVesselYear = tradeInVesselFields.find((prev) => prev.fieldName === "Year *")?.fieldValue;
            const tVesselDocNo = tradeInVesselFields.find((prev) => prev.fieldName === "USCG/Official/Doc#")?.fieldValue;
            const tVesselRegNo = tradeInVesselFields.find((prev) => prev.fieldName === "Reg No")?.fieldValue;
            const tVesselTitleNo = tradeInVesselFields.find((prev) => prev.fieldName === "Title No")?.fieldValue;
            const tVesselDeliveryLocation = tradeInVesselFields.find((prev) => prev.fieldName === "Delivery Location *")?.fieldValue;

            stepMain = {
              ...stepMain,
              id: dataTradeInVessel?.id,
              hull_number: tVesselHIN,
              vessel_name: tVesselName,
              stock: tVesselStock,
              allowance: tVesselAllowance,
              length: Number(tVesselLength),
              // boat_category: tVesselCategory,
              // make: tVesselMake,
              model: tVesselModel,
              year: Number(tVesselYear),
              doc_no: tVesselDocNo,
              reg_no: tVesselRegNo,
              title_no: tVesselTitleNo,
              delivery_location: tVesselDeliveryLocation
            }

            transactionPayload = {
              ...transactionPayload,
              isBelonged: false,
            }
            break;

          case "offer":
            // get updated offer data for db
            const offerDate = offerFields.find((prev) => prev.fieldName === "Offer Date *")?.fieldValue;
            const offerExpirationDate = offerFields.find((prev) => prev.fieldName === "Offer Expiration Date *")?.fieldValue;
            const offerAcceptRejectDate = offerFields.find((prev) => prev.fieldName === "Accept/Reject Date *")?.fieldValue;
            const offerClosingDate = offerFields.find((prev) => prev.fieldName === "Closing Date *")?.fieldValue;
            const offerDepositDueIn = offerFields.find((prev) => prev.fieldName === "Deposit Due In (# of Days)")?.fieldValue;
            const offerPurchasePriceVessel = offerFields.find((prev) => prev.fieldName === "Purchase Price (Vessel)")?.fieldValue;
            const offerPurchasePriceUnits = offerFields.find((prev) => prev.fieldName === "Purchase Price (Addt'l Units)")?.fieldValue;
            const offerLessDeposit = offerFields.find((prev) => prev.fieldName === "Less Deposit *")?.fieldValue;
            const offerLessTradeAllowance = offerFields.find((prev) => prev.fieldName === "Less Trade Allowance")?.fieldValue;
            // const offerTotalPurchasePrice = offerFields.find((prev) => prev.fieldName === "Total Purchase Price *")?.fieldValue;
            // const offerBalance = offerFields.find((prev) => prev.fieldName === "Balance")?.fieldValue;

            stepMain = {
              ...stepMain,
              id: dataOffer?.id,
              offer_date: offerDate,
              offer_expiration_date: offerExpirationDate,
              accept_reject_date: offerAcceptRejectDate,
              closing_date: offerClosingDate,
              deposit_due_in: offerDepositDueIn,
              purchase_price: Number(offerPurchasePriceVessel),
              purchase_price_additional_units: Number(offerPurchasePriceUnits),
              less_deposit: Number(offerLessDeposit),
              less_trade_allowance: Number(offerLessTradeAllowance),
              // total_purchase_price: Number(offerTotalPurchasePrice),
            }

            transactionPayload = {
              ...transactionPayload,
              isBelonged: false,
            }
            break;

          case "exclusion":
            // get updated co-buyer data for db
            const Exclusion1 = exclusionFields.find((prev) => prev.fieldName === "Exclusion 1")?.fieldValue;
            const Exclusion2 = exclusionFields.find((prev) => prev.fieldName === "Exclusion 2")?.fieldValue;
            const Exclusion3 = exclusionFields.find((prev) => prev.fieldName === "Exclusion 3")?.fieldValue;
            const Exclusion4 = exclusionFields.find((prev) => prev.fieldName === "Exclusion 4")?.fieldValue;

            stepMain = {
              data: [Exclusion1, Exclusion2, Exclusion3, Exclusion4]
            };

            transactionPayload = {
              ...transactionPayload,
              isBelonged: false,
            }
            break;

          default:
            break;
        }

        // api call of transaction
        dispatch(
          upsertTransaction({
            step: {
              db: apiStepDb,
              isGlobal: false,
              main: {
                ...stepMain
              }
            },
            transaction: {
              ...transactionPayload
            }
          })
        ).then(async (res) => {
          await refreshContent(true);
          setFormFieldsEdit((prev) => ({ ...prev, [`is_${apiStepDb}_edit`]: false }));
          // dispatch(setLoadingStatus(false));
        }).catch((err) => {
          console.log(err, "error on update form data by draggable fields");
          dispatch(setLoadingStatus(false));
        });
      }
    }).catch((err) => { console.log(err, "error while save form fields changes"); });
    dispatch(setLoadingStatus(false));
  }

  // Function to handle copying text to the clipboard
  const handleFormFieldCopyClick = (fieldText) => {
    navigator.clipboard.writeText(fieldText)
      .then(() => {
        // console.log('Text copied to clipboard!');
      })
      .catch((err) => {
        console.log('Failed to copy text: ' + err);
      });
  };

  return (
    <div className="create-container">
      <div className=" rsw-wrapper">
        {propData.type == 'listing-agreement' &&
          <FormDataSideBarListing
            transactionId={propData.transactionId}
            sliderFor='editor'
            reloadPdf={refreshContent}
            isPdfEdit={true}
            signatureAssigner={signatureAssigner}
            setSignatureAssigner={setSignatureAssigner}
            style={style}
            theme={theme}
            signatureRoles={signatureRoles}
            formFieldsProps={{ vesselFields, setVesselFields, reloadKey, formFieldsEdit, setFormFieldsEdit, handleFormFieldsChange, onFormFieldsChangesSave }}
          />
        }
        {propData.type == 'deals' &&
          <FormDataSideBarDeal
            transactionId={propData.transactionId}
            sliderFor='editor'
            reloadPdf={refreshContent}
            isPdfEdit={true}
            documentName={propData?.documentName}
            documentId={propData?.documentId}
            signatureAssigner={signatureAssigner}
            setSignatureAssigner={setSignatureAssigner}
            style={style}
            theme={theme}
            signatureRoles={signatureRoles}
            formFieldsProps={{ vesselFields, termFields, coBuyerFields, coBrokerFields, sellerFields, coSellerFields, buyerFields, offerFields, tradeInVesselFields, exclusionFields, ybaaAmendmentField, acceptanceOfVesselField, sellerClosing, agreementAmmendmuntField, buyerClosing, agreementExclusion, reloadKey, formFieldsEdit, setFormFieldsEdit, handleFormFieldsChange, onFormFieldsChangesSave, onFormFieldsChangesCancle, handleFormFieldCopyClick }}
            resetModal={resetModal}
            leftSideBarRef={leftSideBarRef}
          />
        }
        <div>
          <div className="nav-bar">
            <div style={{ position: "absolute", left: "30px" }}>
              <button className="btn btn-continue" onClick={onPreviousPage}>
                Back
              </button>
            </div>

            {/* if document exist then only show reset , etc ... options */}
            {(editorPdf || mergedPdfUrl) && <>
              {/* if pdf is edited before then reset button will appear */}
              {(propData.haveEditVersions && editorPdf) &&
                <div style={{ position: "absolute", left: "120px" }}>
                  <button className="btn btn-reset " onClick={onResetPdfVersion}>
                    Reset
                  </button>
                </div>
              }

              {/* submit document button */}
              <div style={{ position: "absolute", right: "14px" }}>
                <button
                  className="uk-button submit-document-button "
                  onClick={() => savePDF()}
                >
                  {/* <FontAwesomeIcon icon={faCloud} /> */}
                  <span> Save Changes </span>
                </button>
              </div>

              {/* download button  */}
              <div style={{ position: "absolute", right: "150px" }}>
                <button
                  className="uk-button download-button"
                  onClick={() => downloadPDF()}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  <span> Download </span>
                </button>
              </div>
            </>}

          </div>

          <div className={`${classes.container} `}>
            <Grid container>
              <Grid item xs={12}>
                <Box className={`${classes.pdf_box}`}>
                  {/* savePDF is function which will execute on submitdoc button click */}
                  {/* downloadPDF is function which will execute on download button click */}

                  <PDfEditorContainer
                    loggedUser={logged_user}
                    doc={editorPdf}
                    formData={formData}
                    onSubmitUpdatedPDF={onSaveDocumentVersion}
                    savePDF={savePDF}
                    downloadPDF={downloadPDF}
                    mergeProps={{ mergedPdfUrl, setMergedPdfUrl, files, setFiles, isConfirmForMerge, setIsConfirmForMerge, mergeRef }}
                    getEditedPdfUrl={getEditedPdfUrl}
                    templatePageCount={templatePageCount}
                    textFields={textFields}
                    setTextFields={setTextFields}
                    checkboxFields={checkboxFields}
                    setCheckboxFields={setCheckboxFields}
                    logoFields={logoFields}
                    setLogoFields={setLogoFields}
                    signatureFields={signatureFields}
                    setSignatureFields={setSignatureFields}
                    signatureAssigner={signatureAssigner}
                    getSignatureBgColor={getSignatureBgColor}
                    onSaveDocumentVersion={onSaveDocumentVersion}
                    onMergeDocument={onMergeDocument}
                    onDeleteDocumentPage={onDeleteDocumentPage}
                    formFieldsProps={{ vesselFields, buyerFields, termFields, coBrokerFields, ybaaAmendmentField, acceptanceOfVesselField, sellerClosing, agreementAmmendmuntField, buyerClosing, agreementExclusion, coBuyerFields, sellerFields, coSellerFields, offerFields, tradeInVesselFields, exclusionFields, formFields, setFormFields, updatedFormFields }}
                    undoRedoProps={{ fieldTrackArr, setFieldTrackArr, fieldTrackObj, setFieldTrackObj }}
                    reloadPdf={{ reloadKey, setReloadKey }}
                    setSignatureAssigner={setSignatureAssigner}
                    style={style}
                    theme={theme}
                    signatureRoles={signatureRoles}
                    documentName={propData?.documentName}
                    modalPosition={modalPosition}
                    setModalPosition={setModalPosition}
                    resetModal={resetModal}
                    templateFields={templateFields}
                    // allFormFields={allFormFields}
                    savePdfField={savePdfField}
                    modalLoading={modalLoading}
                    setModalLoading={setModalLoading}
                    reflectPdfEdit={reflectPdfEdit}
                    leftSideBarRef={leftSideBarRef}
                    updatedValuesRef={updatedPdfUrl}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomEditor;
