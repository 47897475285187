import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import TransactionService from '../../../../../services/TransactionService';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getTransactionInitialById } from "../../../../../redux/actions/transaction";

import { toast } from "react-toastify";
import * as yup from "yup";
import MarkdownEditor from '../../../../common/CkEditor/MarkdownEditor';

const PanelFormMaterialDamageDisclosure = ({ closePanel }) => {

    const [note, setNote] = useState('');

    const schema = yup.object().shape({});

    /* Form Validation */
    const { register, handleSubmit, setValue, getValues } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataTerms = dataTransaction?.t_terms || {};

    const dispatch = useDispatch();
    const [materialDamaged, setMaterialDamaged] = useState(dataTerms?.materially_damaged);

    useEffect(() => {
        setValue("material_damage_detail", dataTerms.material_damage_detail ?? "");
        setNote(dataTerms.material_damage_detail)
    }, [dataTerms]);

    useEffect(() => {
        setValue("materially_damaged", materialDamaged)
    }, [materialDamaged]);

    const handleRegisteration = async (data) => {
        try {
            data["transaction_id"] = dataTransaction?.id || null;
            data["id"] = dataTerms?.id || null;
            dispatch(setLoadingStatus(true));
            
            await TransactionService.updateTerms(data);
            dispatch(getTransactionInitialById(dataTransaction?.id));
            dispatch(setLoadingStatus(false));
            toast.success("Terms data updated successfully.", {
                autoClose: 2000
            });
            closePanel(true);
        } catch (error) {
            dispatch(setLoadingStatus(false));
            toast.error("Error occurs, please add terms data.", {
                autoClose: 2000
            });
        }
    };

    const handleError = (error) => {
        console.log(error);
        toast.error("Error occurs, please add at least one exclusion.", {
            autoClose: 2000
        })
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Materially Damaged">
                        <div className="radio-toolbar">
                            <input type="radio" id="yes" name="material_damaged" checked={materialDamaged != null && materialDamaged}
                                onClick={() => setMaterialDamaged(true)}
                            />
                            <label className="uk-form-label radio" htmlFor="yes">Yes</label>
                            <input type="radio" name="material_damaged" id="no" checked={materialDamaged != null && !materialDamaged}
                                onClick={() => { setMaterialDamaged(false); setValue('material_damage_detail', null); }}
                            />
                            <label className="uk-form-label radio" htmlFor="no">No</label>
                            <input type="radio" name="material_damaged" id="unknown" checked={materialDamaged == null}
                                onClick={() => { setMaterialDamaged(null); setValue('material_damage_detail', null); }}
                            />
                            <label className="uk-form-label radio" htmlFor="unknown">Unknown</label>
                        </div>
                    </FormInputWrapper>
                </FormRow>
                {materialDamaged && (
                    <FormRow>
                        <FormInputWrapper size="two" label="Please explain material damage">
                            {/* <textarea className="uk-textarea" rows="3" {...register("material_damage_detail")} /> */}
                            <MarkdownEditor noteProps={{ note, setNote }} setEditData={(data) => { setValue('material_damage_detail', data); }} />
                        </FormInputWrapper>
                    </FormRow>
                )}
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormMaterialDamageDisclosure;