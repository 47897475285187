import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import {
  upsertTransaction,
  getExclusions,
  getTransactionInitialById,
} from "../../../../../redux/actions/transaction";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import MarkdownEditor from "../../../../common/CkEditor/MarkdownEditor";

const schema = yup.object().shape({});

const AddExclusions = ({ step, setStep }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
  const dataDeal = dataTransaction || {};

  const dataExclusions = dataDeal?.t_exclusion?.[0]?.exclusion ?? "";
  const [note, setNote] = useState('');

  useEffect(() => {
    if (dataTransaction) {
      dispatch(getExclusions(dataDeal.id));
    }
  }, []);

  useEffect(() => {
    if (dataExclusions) {
      setNote(dataExclusions);
      setValue('exclusion', dataExclusions);
    }
    // setIsShowMore(dataExclusions?.length > 4);
  }, [dataExclusions]);

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleRegisteration = (exclusions) => {
    if (exclusions) {
      dispatch(
        upsertTransaction({
          step: {
            db: "exclusion",
            main: {
              data: exclusions,
            },
          },
          transaction: {
            isBelonged: false,
            main: {
              ...getPlainTransactionData(dataDeal),
              task_type: "deal",
              broker_user_id: dataBroker?.id,
              company_id: dataBroker?.company_id,
              company_office_id: dataBroker?.company_office_id,
            },
          },
        }, "Exclusion")
      ).then((data) => {
        dispatch(getTransactionInitialById(dataDeal.id));
        setStep(step + 1);
      });
    } else {
      setStep(step + 1);
    }
  };

  const handleError = (errors) => {
    setStep(step + 1);
  };

  const links = [
    { label: "Deals", link: "/deals" },
    { label: "Add a Deal", link: null },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Exclusions" vesselName={vesselName} uniqueId={uniqueTransactionId}>

      {/* <p>If you have more than 4 exclusions, skip this section and add it from the detail page.</p> */}
      <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Exclusions" size="two">
            <MarkdownEditor noteProps={{ note, setNote }} setEditData={(data) => { setValue('exclusion', data); }} />
          </FormInputWrapper>
          {/* <FormInputWrapper label="Exclusion #1" size="two">
            <input {...register("exclusion1")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #2" size="two">
            <input {...register("exclusion2")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #3" size="two">
            <input {...register("exclusion3")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #4" size="two">
            <input {...register("exclusion4")} className="uk-input exclusion" type="text" />
          </FormInputWrapper> */}
        </FormRow>
        {/* {!isShowMore && (
          <FormRow>
            <ThemeButton theme="secondary" onClick={() => setIsShowMore(true)}>
              show more
            </ThemeButton>
          </FormRow>
        )}
        {isShowMore && (
          <FormRow>
            <FormInputWrapper label="Exclusion #5" size="two">
              <input {...register("exclusion5")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #6" size="two">
              <input {...register("exclusion6")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #7" size="two">
              <input {...register("exclusion7")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #8" size="two">
              <input {...register("exclusion8")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>
            <FormInputWrapper label="Exclusion #9" size="two">
              <input {...register("exclusion9")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #10" size="two">
              <input {...register("exclusion10")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>
            <FormInputWrapper label="Exclusion #11" size="two">
              <input {...register("exclusion11")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #12" size="two">
              <input {...register("exclusion12")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #13" size="two">
              <input {...register("exclusion13")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #14" size="two">
              <input {...register("exclusion14")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #15" size="two">
              <input {...register("exclusion15")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #16" size="two">
              <input {...register("exclusion16")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>
            <FormInputWrapper label="Exclusion #17" size="two">
              <input {...register("exclusion17")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #18" size="two">
              <input {...register("exclusion18")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #19" size="two">
              <input {...register("exclusion19")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>

            <FormInputWrapper label="Exclusion #20" size="two">
              <input {...register("exclusion20")} className="uk-input exclusion" type="text" />
            </FormInputWrapper>
          </FormRow>
        )} */}
        <FormRow style={{ marginTop: "16px" }}>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton to="/deals" onClick={() => handleRegisteration(getValues())}>
            Save & Finish Later
          </LinkButton>
        </FormRow>
        <AsterixTypo isVisible={false} />
      </Form>
    </FormPage>
  );
};

export default AddExclusions;
