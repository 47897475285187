import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from '../../../../../common/Form';
import FormRow from '../../../../../common/FormRow';
import FormInputWrapper from '../../../../../common/FormInputWapper';
import { getFigure, getPrice } from '../../../../../../utils/currency';
import { setLoadingStatus } from '../../../../../../redux/actions/loading';
import { getPlainTransactionData } from '../../../../../../utils/stepFilter';
import { getTransactionEdocById, getTransactionEsignById, getTransactionInitialById, upsertTransaction } from '../../../../../../redux/actions/transaction';

import * as yup from "yup";

const figureFields = [
    "gross_sales_commission",
    "survey_allowance",
    "holdback_escrow",
    "balance_due_seller",
    "expense_total",
    "credit_total",
    "deposit_total",
    "disbursement_total",
];
export const getTotals = (details) => {
    let expenseTotal = 0;
    let creditTotal = 0;
    let depositTotal = 0;
    let disbursementTotal = 0;
    details.forEach((detail) => {
        const amount = getFigure(detail.amount);
        switch (detail.type) {
            case "expenses":
                expenseTotal += amount || 0;
                break;
            case "credits":
                creditTotal += amount || 0;
                break;
            case "deposits":
                depositTotal += amount || 0;
                break;
            case "disbursements":
                disbursementTotal += amount || 0;
                break;
            default:
                break;
        }
    });
    return { expenseTotal, creditTotal, depositTotal, disbursementTotal };
};

const TabFormTransactionDetails = ({ closePanel, menuType }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { isDirty, errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataSellerClosing = dataDeal.t_seller_closing || {};
    const dataBuyerClosing = dataDeal.t_buyer_closing || {};
    const dataOffer = dataDeal.t_offers || {};
    const dataSellerPayoff = dataDeal.t_seller_payoff || {};
    const dataDetails = dataDeal.t_details || [];
    const dataCobroker = dataDeal.co_broker || {};
    const dataVesselAcceptance = dataDeal.t_acceptance || [];
    useEffect(() => {
        if (dataSellerClosing) {
            Object.keys(dataSellerClosing).map((key) => {
                if (figureFields.indexOf(key) > -1) {
                    setValue(key, getPrice(dataSellerClosing[key]));
                } else {
                    setValue(key, dataSellerClosing[key]);
                }
                setValue("survey_allowance", getPrice(dataSellerClosing?.survey_allowance ?? dataBuyerClosing?.survey_allowance ?? dataVesselAcceptance?.[0]?.adjustment_amount));
                setValue("gross_sales_commission", getPrice(dataCobroker?.gross_commission_amount));
            });
        }
    }, [dataSellerClosing, dataVesselAcceptance ,dataBuyerClosing]);

    useEffect(() => {
        const details = dataDetails.filter((d) => d.document_type === "seller");
        const { expenseTotal, creditTotal, depositTotal, disbursementTotal } = getTotals(details);
        setValue("expense_total", getPrice(expenseTotal));
        setValue("credit_total", getPrice(creditTotal));
        setValue("deposit_total", getPrice(depositTotal));
        setValue("disbursement_total", getPrice(disbursementTotal));
    }, [dataDetails]);

    useEffect(() => {
        const total = purchase_total || 0;
        const sales_commission = getFigure(getValues("gross_sales_commission")) || 0;
        const survey_allowance = getFigure(getValues("survey_allowance")) || 0;
        const holdback_escrow = getFigure(getValues("holdback_escrow")) || 0;
        const gross_proceeds = total - sales_commission - survey_allowance - holdback_escrow;
        setValue("gross_proceeds", getPrice(gross_proceeds));
    }, [watch(["holdback_escrow"]), watch("survey_allowance")]);

    useEffect(() => {
        const grossProceeds = getFigure(getValues("gross_proceeds")) || 0;
        const expenseAmount = getFigure(getValues("expense_total")) || 0;
        const subtotal = getPrice(grossProceeds - payoff - expenseAmount);
        setValue("subtotal", subtotal);
    }, [watch(["expense_total"])]);

    useEffect(() => {
        const { credit_total, deposit_total, subtotal } = getValues();
        const net_proceeds = (getFigure(subtotal) || 0) + (getFigure(credit_total) || 0) + (getFigure(deposit_total) || 0);
        setValue("net_proceeds", getPrice(net_proceeds));
    }, [watch(["credit_total", "deposit_total"])]);

    useEffect(() => {
        const { net_proceeds, disbursement_total } = getValues();
        const balance_due_seller = (getFigure(net_proceeds) || 0) - (getFigure(disbursement_total) || 0);
        setValue("balance_due_seller", getPrice(balance_due_seller));
    }, [watch(["disbursement_total"])]);



   

    const handleBlur = (ref) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(ref));
        setValue(ref, figure);
    };

    const handleRegistration = (detail, isFinishLater = false) => {
        Object.keys(detail).forEach((key) => {
            if (!detail[key]) {
                detail[key] = null;
            }
            if (figureFields.indexOf(key) > -1) {
                detail[key] = getFigure(detail[key]);
            }
        });
        dispatch(setLoadingStatus(true));
        dispatch(upsertTransaction({
            step: {
                db: "seller_closing",
                main: {
                    ...detail,
                    buyer_closing_id: dataBuyerClosing?.id ? dataBuyerClosing?.id : null,
                    acceptance_ids: dataTransaction?.t_acceptance?.filter((e) => e?.status === 2)?.map((e) => e?.id) ?? []
                },
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Seller Closing")
        ).then((data) => {
            dispatch(getTransactionInitialById(data.id)).then(res => {
                dispatch(setLoadingStatus(false));
                dispatch(getTransactionEsignById(data.id))
                    .then((res) => {
                        dispatch(getTransactionEdocById(data.id, menuType === 'invitation', dataDeal.broker?.company_id))
                            .catch(() => {
                                dispatch(setLoadingStatus(false));
                            })
                    }).catch(() => {
                        dispatch(setLoadingStatus(false));
                    })
            }).catch(() => {
                dispatch(setLoadingStatus(false));
            });
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    const purchase_total = (dataOffer.purchase_price || 0) + (dataOffer.purchase_price_additional_units || 0);
    const payoff = dataSellerPayoff.payoff || 0;

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Contract Sales Price">
                        <input className="uk-input" defaultValue={getPrice(purchase_total)} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Gross Sales Commission *">
                        <input className="uk-input" type="text" {...register("gross_sales_commission")}
                            defaultValue={getPrice(dataCobroker?.gross_commission_amount)} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Survey Allowance">
                        <input className="uk-input" type="text" {...register("survey_allowance")}
                            onBlur={() => handleBlur("survey_allowance")} onFocus={() => handleFocus("survey_allowance")}
                            defaultValue={getPrice(dataVesselAcceptance?.adjustment_amount)} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Holdback (Escrow)">
                        <input className="uk-input" type="text" {...register("holdback_escrow")}
                            onBlur={() => handleBlur("holdback_escrow")} onFocus={() => handleFocus("holdback_escrow")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Gross Proceeds">
                        <input className="uk-input" type="text" {...register("gross_proceeds")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Payoff">
                        <input className="uk-input" type="text" defaultValue={getPrice(payoff)} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Expense Total">
                        <input className="uk-input" type="text" {...register("expense_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Subtotal">
                        <input className="uk-input" type="text" {...register("subtotal")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Credit Total">
                        <input className="uk-input" type="text" {...register("credit_total")} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Deposit Total">
                        <input className="uk-input" type="text" {...register("deposit_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Net Proceeds">
                        <input className="uk-input" type="text" {...register("net_proceeds")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Disbursement Total">
                        <input className="uk-input" type="text" {...register("disbursement_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Balance Due Seller">
                        <input className="uk-input" {...register("balance_due_seller")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <div className="text-right pt-3">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                        <button type="submit" className='uk-button uk-button-primary'>Save</button>
                    </div>
                </FormRow>
            </Form>
        </div>
    )
}

export default TabFormTransactionDetails;