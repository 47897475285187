import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import moment from "moment";

import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import {
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import AutoCompleteSearch from "../../../../common/Autocomplete";
import { getLength, getOrigin } from "../../../../../utils/length";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { useHistory } from "react-router-dom";
import {
  clearVessel,
  retrieveVesselOrToys,
} from '../../../../../redux/actions/vessels_toys';
import VesselAndToyService from "../../../../../services/VesselAndToyService";
import Auth from "../../../../../utils/auth";
import { getFigure, getPrice } from "../../../../../utils/currency";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import TransactionService from "../../../../../services/TransactionService";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import { tradeInVesselDateFields } from "../../../../../utils/AppConstants";
import { dateFormatter } from "../../../../../utils/common";

//Define schema for validations
const schema = yup.object().shape({
  vessel_name: yup.string(),
  allowance: yup.string().required(),
  hull_number: yup.string().required(),
  length: yup.string().required(),
  make: yup.string().required(),
  model: yup.string().required(),
  year: yup.number().required(),
  doc_no: yup.string().when(['reg_no', 'title_no'], {
    is: (reg_no, title_no) => (!reg_no || reg_no.length === 0) && (!title_no || title_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  reg_no: yup.string().when(['doc_no', 'title_no'], {
    is: (doc_no, title_no) => (!doc_no || doc_no.length === 0) && (!title_no || title_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  title_no: yup.string().when(['doc_no', 'reg_no'], {
    is: (doc_no, reg_no) => (!doc_no || doc_no.length === 0) && (!reg_no || reg_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  })
}, [['reg_no', 'title_no'], ['doc_no', 'title_no'], ['doc_no', 'reg_no']]);

const MAX_ENGINE_COUNT = 6;

const AddTradeInVessel = ({ step, setStep }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  //Get logged user data
  const logged_user = Auth.getInstance().getUserData();

  const [error, setError] = useState([]);
  const [lengthUnit, setLengthUnit] = useState("");
  const [numberOfEngines, setNumberOfEngines] = useState(1);
  const [isDutyPaid, setIsDutyPaid] = useState(false);
  const [isSalesPaid, setIsSalesPaid] = useState(false);
  const [isOld, setOld] = useState(false);
  const [isForeignBuiltVessel, setIsForeignBuiltVessel] = useState(false);

  //State for storing boat category dropdown
  const [boatCategories, setBoatCategories] = useState([]);

  //State for storing boat manufactures dropdown
  const [boatManufacturers, setBoatManufacturers] = useState([]);

  /* Define State Variables */
  const [boatType, setBoatType] = useState("");

  const [dataVessel, setDataVessel] = useState(null);
  const [isTradeIn, setIsTradeIn] = useState(false);

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => state.transactions.broker);
  const dataVessels = useSelector((state) => state.vessel_toys.list);
  const dataDeal = dataTransaction || {};
  const dataVesselFromTable = dataDeal.t_trade_in_vessel;

  //Define all keys of form
  const keys = [
    "make",
    "hull_number",
    "is_old_built",
    // "make",
    "vessel_name",
    "stock",
    "length",
    "model",
    "boat_category",
    "year",
    "flag",
    "doc_no",
    "doc_no_issuer",
    "reg_no",
    "reg_no_issuer",
    "title_no",
    "title_no_issuer",
    "place_of_registration",
    "is_duty_paid",
    "sales_use_tax_paid",
    "engine_make",
    "engine_model",
    "engine_hp",
    "accept_reject_date",
    "exclusion_list",
    "delivery_location",
    "allowance"
  ];

  const optionsForAutocomplete = dataVessels
    .map((vessel) => {
      let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""} ${vessel.year || ""
        }`;
      vessel_title = vessel_title === "   " ? "N/A" : vessel_title;
      return { id: vessel.id, value: vessel_title };
    })
    .filter(option => option.value !== 'N/A')
    .sort((prev, next) => prev.value.localeCompare(next.value))
    .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

  Array.from({ length: MAX_ENGINE_COUNT }).forEach((_, index) => {
    const realIndex = index + 1;
    keys.push(`engine_serial_${realIndex}`);
    keys.push(`engine_year_${realIndex}`);
    keys.push(`engine_hours_${realIndex}`);
  })

  //Separate power category of boat from all category of boat
  const dataPowerCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.PowerCategories) {
      return state.vessel_toys.mlsFilters.PowerCategories || [];
    } else {
      return [];
    }
  });

  //Separate sail category of boat from all category of boat
  const dataSailCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.SailCategories) {
      return state.vessel_toys.mlsFilters.SailCategories || [];
    } else {
      return [];
    }
  });

  //Remove null manufactures from all data manufactures
  const dataManuFecturers = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.manufacturers) {
      return state.vessel_toys.mlsFilters.manufacturers || {}
    } else {
      return {}
    }
  });

  //UseEffect for setBoatManufactures with sorting
  useEffect(() => {
    if (dataManuFecturers) {
      setBoatManufacturers(Object.values(dataManuFecturers).sort());
      setValue("make", dataVessel?.make)
    }
  }, [dataManuFecturers]);


  useEffect(() => {
    dispatch(retrieveVesselOrToys("vessel"));
    return () => {
      dispatch(clearVessel());
    };
  }, []);

  //UseEffect for setBoatCategories with sorting
  useEffect(() => {
    const categories = boatType == "Power" ? dataPowerCategories : dataSailCategories;
    setBoatCategories(categories.sort());
  }, [boatType]);

  //UseEffect for store value of boat_category
  useEffect(() => {
    setTimeout(() => {
      setValue("boat_category", dataVessel?.boat_category || "");
    }, 1000);
  }, [dataVessel])

  useEffect(() => {
    setDataVessel(dataVesselFromTable);
  }, [dataVesselFromTable]);

  useEffect(() => {
    if (dataVessel) {
      keys.forEach((key) => {
        setValue(key, dataVessel[key] ? `${dataVessel[key]}` : null);
        if (key.indexOf("date") > -1) {
          setValue(
            key,
            dataVessel[key] ? moment(dataVessel[key]).format("yyyy-MM-DD") : null
          );
        }

        if (key == "createdBy") {
          setValue("createdBy", dataVessel[key] ? dataVessel[key] : logged_user.id)
        }
      });
      // setValue("make", dataVessel?.make)

      setIsDutyPaid(dataVessel["is_duty_paid"]);
      setIsSalesPaid(dataVessel["sales_use_tax_paid"]);
      setBoatType(dataVessel?.boat_type || "Power");
      setValue("boat_type", dataVessel.boat_type);
      setIsTradeIn(true);
    } else {
      keys.forEach((key) => setValue(key, null));
      setValue("broker_user_id", logged_user.id)
      setValue("company_office_id", logged_user.company_office_id)
      setValue("createdBy", logged_user.id)
      setValue("company_id", logged_user.company_id)
      setBoatType(dataVessel?.boat_type || "Power");
      setValue("boat_type", dataVessel?.boat_type || "Power");
      setValue("allowance", 0);
      setValue("flag", "US");
    }
    setValue("updatedBy", logged_user.id)
    setValue("number_of_engines", dataVessel?.number_of_engines || 1);
    setLengthUnit(dataVessel?.length_unit || "ft");
    setValue("length", getLength(dataVessel?.length));
    setOld(dataVessel?.is_old_built || false);
    setValue("boat_category", dataVessel?.boat_category || "");
    setValue("make", dataVessel?.make)
  }, [JSON.stringify(dataVessel)]);

  useEffect(() => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  }, [lengthUnit]);

  /* Form Validation */
  const { register, handleSubmit, setValue, trigger, getValues, watch, formState: { errors }, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const handleRegistration = (vessel, isFinishLater = false) => {
    dispatch(setLoadingStatus(true));

    // change all date string in yyyy-MM-DD format
    for (const index in tradeInVesselDateFields) {
      vessel[tradeInVesselDateFields[index]] = dateFormatter(vessel[tradeInVesselDateFields[index]]);
    }

    vessel = getFormattedData(vessel);
    vessel.length = parseFloat(vessel.length);
    vessel.length_unit = lengthUnit;
    vessel.is_duty_paid = isDutyPaid;
    vessel.sales_use_tax_paid = isSalesPaid;
    vessel.is_old_built = isOld;
    vessel.boat_type = boatType;
    vessel.allowance = getFigure(vessel.allowance);
    if (dataVessel && dataVessel.id) {
      vessel.id = dataVessel.id;
    }

    dispatch(upsertTransaction({
      step: {
        db: "trade_in_vessel",
        main: vessel,
      },
      transaction: {
        isBelonged: false,
        main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
      },
    }, "Trade In Vessel's")).then((data) => {
      dispatch(setLoadingStatus(false));
      if (isFinishLater) {
        history.push(`/deals/view/${data.id}/main`);
      } else {
        dispatch(getTransactionEsignById(data.id));
        setStep(step + 1);
      }
    }).catch((error) => {
      console.log(error);
      dispatch(setLoadingStatus(false));
    });
  };

  const handleError = err => {
    console.log(err);
  };

  const handleSelect = async (value, option) => {
    const res = await VesselAndToyService.getVessel(option.id);
    delete res.data.vessel.id;
    setDataVessel(res.data.vessel);
  };

  const handleBlur = () => {
    let value = getValues("allowance");
    let totalValue = getValues("all_purchase_price");
    let price = getPrice(value);
    let totalPrice = getPrice(totalValue);
    setValue("allowance", price);
    setValue('all_purchase_price', totalPrice);
  };

  const handleFocus = () => {
    let value = getValues("allowance");
    let totalValue = getValues("all_purchase_price");
    let price = getPrice(value);
    let totalPrice = getPrice(totalValue);
    setValue("allowance", price);
    setValue('all_purchase_price', totalPrice);
  };

  //Function for remove ft. or mt. for length
  const handleLengthBlur = () => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  };

  //Function for remove ft. or mt. for length
  const handleLengthFocus = () => {
    let symbolitic = getValues("length");
    setValue("length", getOrigin(symbolitic, lengthUnit));
  };

  //UseEffect for add fields as per number of field is selected.
  useEffect(() => {
    setNumberOfEngines(getValues('number_of_engines'));
    setValue("make", dataVessel?.make)
  }, [watch('number_of_engines')]);

  const removeTradeIn = () => {
    if (dataVessel && dataVessel.id) {
      dispatch(setLoadingStatus(true));
      TransactionService.removeTradeInVessel(dataVessel.id).then((response) => {
        dispatch(getTransactionEsignById(dataTransaction.id));
        dispatch(setLoadingStatus(false));
        setStep(step + 1);
      }).catch((error) => {
        dispatch(setLoadingStatus(false));
      });
    } else {
      setStep(step + 1);
    }
  };

  const links = [
    { label: "Deals", link: `/deals/view/${dataTransaction?.id}/main` },
    { label: "Add a Deal", link: null },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <React.Fragment>
      <FormPage links={links} title="Trade-In Vessel Addendum" vesselName={vesselName} uniqueId={uniqueTransactionId}>
        <FormRow>
          <FormInputWrapper label="Is there a Trade-In Vessel?" className="mb-2">
            <div className="radio-toolbar">
              <input
                type="radio"
                id="radioTradeInYes"
                name="radioTradeIn"
                checked={isTradeIn}
                onClick={() => setIsTradeIn(!isTradeIn)}
                onChange={() => { }}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                id="radioTradeInNo"
                name="radioTradeIn"
                checked={!isTradeIn}
                onClick={() => setIsTradeIn(!isTradeIn)}
                onChange={() => { }}
              />
              <label className="uk-form-label radio">No</label>
            </div>
          </FormInputWrapper>
        </FormRow>

        {isTradeIn
          ? <>
            <FormRow style={{ marginBottom: "24px" }}>
              <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} />
            </FormRow>
            <Form>
              <FormRow>
                <FormInputWrapper label="Hull Number">
                  <input className={clsx("uk-input", { "uk-form-danger": errors.hull_number?.message })} placeholder="Hull number must be 12 digits" type="text" {...register("hull_number")} />
                </FormInputWrapper>
                <FormInputWrapper label="HIN is not available or is not 12 character USCG compliant.">
                  <input className="uk-checkbox" type="checkbox" checked={isOld} onClick={() => { setOld(!isOld); }} onChange={() => { }} />
                </FormInputWrapper>
              </FormRow>
              <FormRow>
                <div className="one-column uk-padding-small">
                  <label className="uk-form-label" htmlFor="vessel_name">
                    Vessel Name
                  </label>
                  <input
                    className="uk-input"
                    type="text"
                    {...register("vessel_name")}
                  />
                </div>

                <div className="one-column uk-padding-small">
                  <label className="uk-form-label" htmlFor="stock">
                    Stock #
                  </label>
                  <input
                    className={clsx("uk-input", {
                      "uk-form-danger": errors.stock?.message,
                    })}
                    type="text"
                    {...register("stock")}
                  />
                </div>

                <FormInputWrapper label="Trade In Value *">
                  <input
                    className={clsx("uk-input", {
                      "uk-form-danger": errors.allowance?.message,
                    })}
                    type="text"
                    {...register("allowance")}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                </FormInputWrapper>
              </FormRow>
              <FormRow>
                <FormInputWrapper label="Length *">
                  <input
                    className={clsx("uk-input", { "uk-form-danger": errors.length?.message })}
                    type="text"
                    {...register("length")}
                    onBlur={handleLengthBlur}
                    onFocus={handleLengthFocus}
                  />
                </FormInputWrapper>

                <FormInputWrapper label="Length Unit">
                  <input
                    type="radio"
                    name="length_unit"
                    value="ft"
                    onClick={() => setLengthUnit("ft")}
                    onChange={() => { }}
                    checked={lengthUnit === "ft"}
                  />
                  <label className="uk-form-label radio">Feet</label>
                  <input
                    type="radio"
                    name="length_unit"
                    value="m"
                    onClick={() => setLengthUnit("m")}
                    onChange={() => { }}
                    checked={lengthUnit === "m"}
                  />
                  <label className="uk-form-label ratio">Meters</label>
                </FormInputWrapper>
                <FormInputWrapper label="USCG/Official/Doc #">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no })} type="text" {...register("doc_no")} onKeyUp={() => {
                    trigger(["reg_no", "title_no"]);
                  }} />
                </FormInputWrapper>
              </FormRow>
              <FormRow>

                <FormInputWrapper label="Category *">
                  <select
                    className={clsx("uk-select", { "uk-form-danger": errors.boat_category?.message })}
                    {...register('boat_category')}
                    disabled={!boatType}
                  >
                    <option key={`vessel_boat_category_default`} value=""></option>
                    {boatCategories.map((boatCategoryKey, idx) => (
                      <option key={`vessel_boat_category_${idx}`} value={boatCategoryKey}>{boatCategoryKey}</option>
                    ))}
                  </select>
                </FormInputWrapper>


                <FormInputWrapper label="Type">
                  <input
                    type="radio"
                    name="boat_type"
                    value="Power"
                    onClick={() => {
                      setBoatType("Power");
                      setValue("boat_category", "")
                      // setBoatCategories(dataPowerCategories);
                    }}
                    onChange={() => { }}
                    checked={boatType === "Power"}
                  />
                  <label className="uk-form-label radio">Power</label>
                  <input
                    type="radio"
                    name="boat_type"
                    value="Sail"
                    onClick={() => {
                      setBoatType("Sail");
                      setValue("boat_category", "")
                      // setBoatCategories(dataSailCategories);
                    }}
                    onChange={() => { }}
                    checked={boatType === "Sail"}
                  />
                  <label className="uk-form-label ratio">Sail</label>
                </FormInputWrapper>

                <FormInputWrapper label="USCG/Official/Doc # Issuing Entity">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no_issuer })} type="text" {...register("doc_no_issuer")} />
                </FormInputWrapper>

              </FormRow>
              <FormRow>
                <FormInputWrapper label="Manufacturer/Builder *">
                  <select
                    className={clsx("uk-select", { "uk-form-danger": errors.make?.message })}
                    // defaultValue={toy.vessel.make}
                    {...register('make')}
                  >
                    <option key={`vessel_make_default`} value=""></option>
                    {boatManufacturers.map((boatMakeKey, idx) => (
                      <option key={`vessel_make_${idx}`} value={boatMakeKey}>{boatMakeKey}</option>
                    ))}
                  </select>
                </FormInputWrapper>

                <FormInputWrapper label="Model*">
                  <input
                    className={clsx("uk-input", {
                      "uk-form-danger": errors.model?.message,
                    })}
                    type="text"
                    {...register("model")}
                  />
                </FormInputWrapper>

                {/* <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="model">
                Model*
              </label>

            </div> */}

                <FormInputWrapper label="Year *">
                  <input className={clsx("uk-input", { "uk-form-danger": errors.year?.message })} type="text" {...register("year")} />
                </FormInputWrapper>

              </FormRow>
              <FormRow>
                <FormInputWrapper label="Number of Engines">
                  <select
                    className="uk-select"
                    {...register("number_of_engines")}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                </FormInputWrapper>
              </FormRow>
              <FormRow>
                <FormInputWrapper label="Engine Make *">
                  <input className="uk-input" type="text" {...register("engine_make")} />
                  {/* <select
                className="uk-select"
                {...register('engine_make')}
              >
                {engineMakes.map((engineMake, idx) => (
                  <option key={`engine_make_${idx}`} value={engineMake}>{engineMake}</option>
                ))}
              </select> */}
                </FormInputWrapper>
                <FormInputWrapper label="Engine Model *">
                  <input className="uk-input" type="text" {...register("engine_model")} />
                </FormInputWrapper>
                <FormInputWrapper label="Engine HP *">
                  <input className="uk-input" type="text" {...register("engine_hp")} />
                </FormInputWrapper>
              </FormRow>
              {Array.from({ length: numberOfEngines }).map((_, index) => {
                const realIndex = index + 1;

                return (
                  <FormRow key={`engine_${index}`}>
                    <FormInputWrapper label={`Engine ${realIndex} Year`}>
                      <input className="uk-input" type="text" {...register(`engine_year_${realIndex}`)} />
                    </FormInputWrapper>
                    <FormInputWrapper label={`Engine ${realIndex} Serial${index === 0 ? ' *' : ''}`}>
                      <input className="uk-input" type="text" {...register(`engine_serial_${realIndex}`)} />
                    </FormInputWrapper>
                    <FormInputWrapper label={`Engine ${realIndex} Hours`}>
                      <input className="uk-input" type="text" {...register(`engine_hours_${realIndex}`)} />
                    </FormInputWrapper>
                  </FormRow>
                );
              })}
              <FormRow>
                <FormInputWrapper label="Flag">
                  <input className="uk-input" type="text" {...register("flag")} />
                </FormInputWrapper>
                <FormInputWrapper size="one" label="Foreign Built Vessel">
                  <input
                    type="radio"
                    name="foreign_built_vessel"
                    value={true}
                    onClick={() => setIsForeignBuiltVessel(true)}
                    onChange={() => { }}
                    checked={isForeignBuiltVessel}
                  />
                  <label className="uk-form-label radio">Yes</label>
                  <input
                    type="radio"
                    name="foreign_built_vessel"
                    value={false}
                    onClick={() => setIsForeignBuiltVessel(false)}
                    onChange={() => { }}
                    checked={!isForeignBuiltVessel}
                  />
                  <label className="uk-form-label radio">No</label>
                </FormInputWrapper>
              </FormRow>

              <FormRow>
                <p style={{ color: '#5479A3' }} className={clsx("", { "uk-text-danger uk-text-bold": errors.doc_no?.message || errors.reg_no?.message || errors.title_no?.message })}>Please input at least one: Reg No., or Title No.</p>
              </FormRow>
              <FormRow>
                {/* <FormInputWrapper label="Doc No.">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no })} type="text" {...register("doc_no")} onKeyUp={() => {
                    trigger(["reg_no", "title_no"]);
                  }} />
                </FormInputWrapper> */}
                <FormInputWrapper label="Reg No.">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no })} type="text" {...register("reg_no")} onKeyUp={() => {
                    trigger(["doc_no", "title_no"]);
                  }} />
                </FormInputWrapper>
                <FormInputWrapper label="Title No.">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no })} type="text" {...register("title_no")} onKeyUp={() => {
                    trigger(["reg_no", "doc_no"]);
                  }} />
                </FormInputWrapper>
              </FormRow>
              <FormRow>
                {/* <FormInputWrapper label="Doc No. Issuing Entity">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no_issuer })} type="text" {...register("doc_no_issuer")} />
                </FormInputWrapper> */}
                <FormInputWrapper label="Reg No. Issuing Entity">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no_issuer })} type="text" {...register("reg_no_issuer")} />
                </FormInputWrapper>
                <FormInputWrapper label="Title No. Issuing Entity">
                  <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no_issuer })} type="text" {...register("title_no_issuer")} />
                </FormInputWrapper>
              </FormRow>

              <FormRow>
                <FormInputWrapper size="one" label="Duty Paid">
                  <input
                    type="radio"
                    name="duty_paid"
                    value={true}
                    onClick={() => setIsDutyPaid(true)}
                    onChange={() => { }}
                    checked={isDutyPaid != null && isDutyPaid}
                  />
                  <label className="uk-form-label radio">Yes</label>
                  <input
                    type="radio"
                    name="duty_paid"
                    value={false}
                    onClick={() => setIsDutyPaid(false)}
                    onChange={() => { }}
                    checked={isDutyPaid != null && !isDutyPaid}
                  />
                  <label className="uk-form-label radio">No</label>
                  <input
                    type="radio"
                    name="duty_paid"
                    value={false}
                    onClick={() => setIsDutyPaid(null)}
                    onChange={() => { }}
                    checked={isDutyPaid == null}
                  />
                  <label className="uk-form-label radio">Unknown</label>
                </FormInputWrapper>
                <FormInputWrapper label="Sales/Use Tax Paid">
                  <input
                    type="radio"
                    name="sales_use_tax_paid"
                    value={true}
                    onClick={() => setIsSalesPaid(true)}
                    onChange={() => { }}
                    checked={isSalesPaid != null && isSalesPaid}
                  />
                  <label className="uk-form-label radio">Yes</label>
                  <input
                    type="radio"
                    name="sales_use_tax_paid"
                    value={false}
                    onClick={() => setIsSalesPaid(false)}
                    onChange={() => { }}
                    checked={isSalesPaid != null && !isSalesPaid}
                  />
                  <label className="uk-form-label radio">No</label>
                  <input
                    type="radio"
                    name="sales_use_tax_paid"
                    value={false}
                    onClick={() => setIsSalesPaid(null)}
                    onChange={() => { }}
                    checked={isSalesPaid == null}
                  />
                  <label className="uk-form-label radio">Unknown</label>
                </FormInputWrapper>
              </FormRow>
              <FormRow>
                <FormInputWrapper label="Trade Vessel Accept/Reject Date *">
                  <Controller
                    control={control}
                    name="accept_reject_date"
                    render={({ field }) => (
                      <DatePicker
                        className={"uk-input"}
                        style={{ cursor: "pointer", color: "#000" }}
                        format={'MM/DD/YYYY'}
                        value={field.value && dayjs(field.value)}
                        onChange={(date, dateString) => {
                          field.onChange(dateString);
                        }}
                      />
                    )}
                  />
                  {/* {errors && errors.reg_no && <p style={{ color: "red" }}>Please enter a accept/reject date</p>} */}
                </FormInputWrapper>
                <FormInputWrapper label="Trade Vessel Delivery Location *">
                  <input type="text" className="uk-input" {...register("delivery_location")} />
                  {/* {errors && errors.delivery_location && <p style={{ color: "red" }}>Please enter a delivery location</p>} */}
                </FormInputWrapper>
                <FormInputWrapper label="Trade Vessel Exclusion List *">
                  <textarea className={clsx("uk-input", { 'uk-form-danger': errors.exclusion_list })} {...register("exclusion_list")} />
                  {errors && errors.exclusion_list && <p style={{ color: "red" }}>Please enter a trade vessel exclusion list</p>}
                </FormInputWrapper>
              </FormRow>
              <FormRow>
                <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
                <button type="button" className='uk-button uk-button-primary' onClick={() => setStep(step + 1)}>Continue</button>
                <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
                  Save & Finish Later
                </button>
              </FormRow>
              <AsterixTypo isVisible={false} />
            </Form>
          </>
          : <FormRow>
            <ThemeButton theme="primary" onClick={removeTradeIn}>
              Continue
            </ThemeButton>
            <LinkButton to="/deals">Finish Later</LinkButton>
          </FormRow>
        }
      </FormPage>
    </React.Fragment>
  );
};

export default AddTradeInVessel;
