
import React, { useState } from "react";
import { Switch, Tooltip } from "@mui/material";
import { phoneNumber } from '../../utils/phonenumber';
import Auth from "../../utils/auth";
import AssociateCompanyForParticipantModal from "../common/AssociateCompanyForParticipantModal";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "react-data-table-component";
import datatableStyles from "../../utils/datatable-style";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";
import { updateUser } from "../../redux/actions/users";
import { toast } from "react-toastify";

const ParticipantList = ({ searchProps, type, onPasswordChange, onDelete, participants, totalRows, fetchUserData, columns }) => {

    const { search, setSearch } = searchProps;
    // States for manage open close modal for manage participant associate companies
    const [showAssociateCompanies, setShowAssociateCompanies] = useState(false);
    const [associateParticipant, setAssociateParticipant] = useState(null);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading.status);
    const { path } = useRouteMatch();
    const loggedUser = Auth.getInstance().getUserData();
    const { UserInRole } = loggedUser;
    const { UserRole } = UserInRole;
    const { name } = UserRole;
    const { company_id } = loggedUser;

    //Function for handle row limit
    const handleRowsPerPage = (numberOfRows, page) => {
        setSearch((search) => {
            return {
                ...search,
                limit: numberOfRows,
                page: page
            }
        })
    }

    //Function for handle page no
    const handlePageChange = page => {
        setSearch((search) => {
            return {
                ...search,
                page: page
            }
        })
    };

    //Function for handle sort
    const handleSort = (column, sortDirection) => {
        // column.selector, sortDirection
        setSearch((search) => {
            return {
                ...search,
                sortOrder: sortDirection,
                sortField: column.selector
            }
        })
    };

    /**
     * On Click Manage Companies for participant open modal for display associated companies
     * @param {*} participantData 
     */
    const onOpenAssociateCompaniesModal = (participantData) => {
        setAssociateParticipant(participantData);
        setShowAssociateCompanies(true);
    }


    /**
     * On Change participant status from toggle switch
     * @param {*} participantId 
     * @param {*} status 
     */
    const onChangeParticipantStatus = (participantId, status) => {
        dispatch(setLoadingStatus(true));
        dispatch(updateUser(participantId, { active: status })).then(response => {
            dispatch(setLoadingStatus(false));
            toast.success('Status successfully updated.');
            fetchUserData(search);
        }).catch(err => {
            dispatch(setLoadingStatus(false));
            console.log(err);
            toast.error('Something went wrong while update participant.')
        });
    }


    return (
        <>
            {!participants.length && isLoading
                ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
                : <DataTable
                    title="Users Table"
                    columns={columns}
                    data={participants}
                    striped={true}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    center={true}
                    noHeader={true}
                    fixedHeader={true}
                    pagination
                    paginationServer
                    paginationPerPage={participants?.length}
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={name === "Company Admin" ? [100] : [10, 20, 50, 100]}
                    onChangeRowsPerPage={handleRowsPerPage}
                    onChangePage={handlePageChange}
                    onSort={handleSort}
                    sortServer
                    persistTableHead
                    customStyles={datatableStyles}
                />}

            {showAssociateCompanies && <AssociateCompanyForParticipantModal show={showAssociateCompanies} onClose={() => setShowAssociateCompanies(false)} participant={associateParticipant} />}
        </>
    )
}

export default ParticipantList