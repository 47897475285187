import {
    REMOVE_ALL_PDF_EDITOR_FIELD,
    SET_PDF_EDITOR_FIELD,
    UPDATE_PDF_EDITOR_FIELD,
} from "../actions/types";

const initialState = {
    pdfEditorField: {}
};

function pdfEditorReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_PDF_EDITOR_FIELD:
            return {
                ...state,
                ...payload
            };

        case UPDATE_PDF_EDITOR_FIELD:
            return {
                ...state,
                ...payload
            };
        case REMOVE_ALL_PDF_EDITOR_FIELD:
            return {};
        default:
            return state;
    }
}

export default pdfEditorReducer;
