import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getExclusions, getTransactionInitialById, upsertTransaction } from '../../../../../redux/actions/transaction';

import * as yup from "yup";
import MarkdownEditor from '../../../../common/CkEditor/MarkdownEditor';


const PanelFormExclusions = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, getValues, formState } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const { isDirty, errors } = formState;

    const dispatch = useDispatch();
    const [note, setNote] = useState('');
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataDeal = dataTransaction || {};
    const dataExclusions = dataDeal.t_exclusion?.[0]?.exclusion ?? "";

    useEffect(() => {
        if (dataTransaction) {
            dispatch(getExclusions(dataDeal.id));
        }
    }, []);

    useEffect(() => {
        if (dataExclusions) {
            setNote(dataExclusions);
            setValue('exclusion', dataExclusions);
        }
    }, [dataExclusions]);

    const handleRegistration = (exclusions) => {
        if (exclusions) {
            dispatch(setLoadingStatus(true));
            dispatch(upsertTransaction({
                step: {
                    db: "exclusion",
                    main: {
                        data: exclusions,
                    },
                },
                transaction: {
                    isBelonged: false,
                    main: {
                        ...getPlainTransactionData(dataDeal),
                        task_type: "deal",
                        broker_user_id: dataBroker?.id,
                        company_id: dataBroker?.company_id,
                        company_office_id: dataBroker?.company_office_id,
                    },
                },
            }, "Exclusion")
            ).then((data) => {
                dispatch(getTransactionInitialById(dataDeal.id));
                dispatch(setLoadingStatus(false));
                closePanel(true);
            }).catch((error) => {
                dispatch(setLoadingStatus(false));
                console.log(error);
            });
        }
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Exclusions" size="two">
                        <MarkdownEditor noteProps={{ note, setNote }} setEditData={(data) => { setValue('exclusion', data); }} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormExclusions;