import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import DataTable from "react-data-table-component";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LinkButton from "../../../../common/buttons/LinkButton";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import datatableStyles from "../../../../../utils/datatable-style";

import { getTransactionEdocById, getTransactionEsignById, getTransactionInitialById, resetEdoc, sendEmail } from "../../../../../redux/actions/transaction";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { useEffect, useState } from "react";
import TransactionService from "../../../../../services/TransactionService";
import { toast } from "react-toastify";
import PreviewModal from "../../../../common/PreviewModal";
import "./eDocumentsViewTable.css";
import { confirmAlert } from "react-confirm-alert";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import Auth from "../../../../../utils/auth";
import { uploadForms } from "../../../../../utils/s3";
import { createFileFromLink } from "../../../../../utils/common";
import SurveyWaiverFormDataModal from "../../../../common/SurveyWaiverFormDataModal";
import SendPreviewEmailModal from "../../../../common/SendPreviewEmailModal";
import EDocService from "../../../../../services/EDocService";
import { DocumentResetModal } from "../../../../common/DocumentResetModal";
import { Tooltip } from "@mui/material";
import { isParticipant } from "../../../../../utils/permission";

const EdocumentsViewTable = ({ data, setTab, status, menuType }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [isPreview, setIsPreview] = useState(false);
  const [requestSignature, setRequestSignature] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [resetEdocDetail, setResetEdocDetail] = useState(null);

  const deals = useSelector((state) => state.transactions);
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const isLoading = useSelector((state) => state.loading.status);
  const dataAcceptance = dataTransaction?.t_acceptance || [];
  const docArr = ["Acceptance of Vessel"]
  const [successDocuments, setSuccessDoc] = useState([]);
  const [failedDocuments, setFailedDoc] = useState([]);
  const [selectedDocuments, setSetselectedDocuments] = useState([]);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  const { url } = useRouteMatch();
  const { id } = useParams();
  const listLink = url.split("/").slice(1)[0]

  const formDataList = [
    "purchase-&-sale-agreement",
    "iyba-purchase-&-sale---make-offer",
    "ybaa-purchase-and-sale-agreement",
    "pier-one-purchase-and-sale-agreement",
    "buyer's-“as-is”-acknowledgement",
    "authorization-of-release-of-funds",
    "ybaa-acceptance-of-vessel",
    "trial-run-waiver",
    "bill-of-sale",
    "bill-of-sale---engine-(single)",
    "bill-of-sale---all-engines",
    "bill-of-sale---engine-1",
    "bill-of-sale---engine-2",
    "bill-of-sale---engine-3",
    "bill-of-sale---engine-4",
    "bill-of-sale---engine-5",
    "bill-of-sale---engine-6",
    "net-to-seller",
    "ybaa-purchase-and-sale-agreement-(canada)",
    "as---is-acceptance-of-vessel",
    "cyba-purchase-agreement",
    "cyba-purchase-agreement-amendment",
    "cyba-buyer-final-statement",
    "cyba-seller-final-statement",
    "cyba-counter-offer",
    "cyba-transaction-summary",
    "cyba-repair-allowance-addendum",
    "cyba-trial-survey",
    "cancelation-of-purchase---buyer",
    "payoff-verification",
    "escrow-agreement---disbursement-authorization",
    "bill-of-sale---vessel",
    "bill-of-sale---trailer",
    "bill-of-sale---tender",
    "bill-of-sale---trade-in",
    "bill-of-sale-trade-in---trailer",
    "ybaa-escrow-agreement",
    "ybaa-amendment-to-psa",
    "ybaa-amendment-to-psa-(short-form)",
    "counter-offer",
    "nyba-counter-offer",
    "commission-agreement-(seller)",
    "commission-agreement-(seller,co-broker)",
    'fl-sales-tax-exemption-affidavit',
    "release-of-liability-and-hold-harmless",
    "registration-waiver",
    "potential-tax-liability",
    "cancellation-and-refund-request",
    "nyba-purchase-and-sale-agreement",
    "deal-summary",
    "disbursement-of-funds---buyer",
    "fl-removal-affidavit---2024",
    "escrow-holdback-agreement",
    "custom-esign-transaction-template",
    'fl-dealers-sales-tax-stmt---dr41c',
    "custom-esign-transaction-template",
    'material-damage-disclosure-deals',
    "material-damage-disclosure-deals---seller-fills"
  ];

  const handSignOnlyDocs = [
    'bill-of-sale---notary',
    'warranty-of-title---with-notary',
    'uscg-bill-of-sale',
    'texas-pwd-143',
    'texas-pwd-144',
    'us-power-of-attorney---buyer',
    'us-power-of-attorney---seller',
    'loan-payoff-authorization',
    'assignment-of-contract',
    'fl-hsmv-82040-application-for-certificate-of-title-(vessel)',
    'fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053',
    // 'fl-dealers-sales-tax-stmt---dr41c',
    'exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003',
    'disbursement-of-funds---seller',
    'bill-of-sale---notary-(seller-only)',
    'resolution-to-buy---corp',
    'resolutions-to-buy---llc',
    'resolution-to-sell---corp',
    'resolutions-to-sell---llc',
    'fl-affidavit-for-acceptance-outside-florida',
    "affidavit-of-non-export---company",
    "affidavit-of-non-export---individual",
    "uscg---deletion-request"
  ];
  useEffect(() => {
    dispatch(getTransactionEsignById(id))
  }, [])


  const fetchTransactionData = (isCopied = false) => {
    dispatch(setLoadingStatus(true));
    dispatch(getTransactionInitialById(id)).then((res) => {
      dispatch(getTransactionEdocById(id, menuType === 'invitation', dataTransaction.broker?.company_id)).then(
        (res) => {
          dispatch(getTransactionEsignById(id)).then(
            (resp) => {
            }).catch(() => {
              dispatch(setLoadingStatus(false));
            }).then(() => {
              if (isCopied) {
                toast.success("Document added successfully.", {
                  autoClose: 2000
                });
              }
              dispatch(setLoadingStatus(false))
            })
        }).catch((err) => dispatch(setLoadingStatus(false)))
    });
  }

  const sorted_data = data.sort((a, b) => {
    if (a.t_esign_template?.sort_order == b.t_esign_template?.sort_order) {
      if (a.duplicate_occurrence) {
        return a.duplicate_occurrence === b.duplicate_occurrence ? 0 : a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1;
      } else {
        return 0;
      }
    }
    return a.t_esign_template?.sort_order > b.t_esign_template?.sort_order ? 1 : -1;
  });

  const handleEmailSigners = (edoc) => {
    dispatch(setLoadingStatus(true));
    dispatch(sendEmail(edoc.id)).then(res => {
      dispatch(setLoadingStatus(false));
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      console.log(err);
    })
  }

  const logged_user = Auth.getInstance().getUserData();

  const handleReset = (edoc) => {
    setResetEdocDetail(edoc);
    setIsReset(true);
  };

  const closeResetModal = (isReload) => {
    if (isReload) {
      dispatch(setLoadingStatus(true));
      dispatch(getTransactionEdocById(id, menuType === 'invitation', dataTransaction?.broker?.company_id)).then(() => {
        dispatch(setLoadingStatus(false));
      }).catch(() => {
        dispatch(setLoadingStatus(false));
      });
    }
    setIsReset(false);
    setResetEdocDetail(null);
  }

  const renderButton = (row) => {
    const isSignerSignatures = row.request_signature?.signers?.filter(signer => signer.status == "Signed" || signer.status == "Sent" || signer.status == "Declined" || signer.status == "Link Generated" || signer.status == "Initialized") || [];
    return isSignerSignatures.length;
  }

  const renderAction = (row) => {
    if (status === "cancelled") return

    let type = row.status;
    let prefixUrl = url.replace("/main", "");
    let task_type = row?.t_esign_template?.is_custom_template ? row?.title?.split(" ")?.join("-") : row.task_type;
    let link = `${prefixUrl}/${task_type}`;
    let previewLink = `${link}/send_to_signer`;

    //  making all documents active per client ticket 320
    // const row_index = sorted_data.indexOf(row);
    //const should_disabled = (row.task_type !== 'purchase-&-sale-agreement') &&
    // sorted_data[row_index-1].status !== 'Completed';
    // sorted_data[0].status !== 'Completed';

    if (formDataList
      .includes(task_type)) {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/${listLink}/add/${id}/send_to_signer`;
      } else if (type === "In-Progress") {
        // link = `/${listLink}/add/${id}/vessel`;
        previewLink = `/${listLink}/add/${id}/send_to_signer`;
      }
      link = `/${listLink}/add/${id}/vessel`;
    }

    if (task_type === "purchase-&-sale-agreement-addendum") {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/${listLink}/view/${id}/purchase-&-sale-agreement-addendum/send_to_signer?doc_id=${row.id}`;
      } else if (type === "In-Progress") {
        // link = `/${listLink}/view/${id}/purchase-&-sale-agreement-addendum?doc_id=${row.id}`;
        previewLink = `/${listLink}/view/${id}/purchase-&-sale-agreement-addendum/send_to_signer?doc_id=${row.id}`;
      }
      link = `/${listLink}/view/${id}/purchase-&-sale-agreement-addendum?doc_id=${row.id}`;
    }

    if (task_type == "uscg-bill-of-sale") {
      link = `${prefixUrl}/uscg-bill-of-sale`
      previewLink = `${link}/send_to_signer`
    }
    if (task_type == "texas-pwd-143") {
      link = `${prefixUrl}/texas-pwd-143`
      previewLink = `${link}/send_to_signer`
    }
    if (task_type == "texas-pwd-144") {
      link = `${prefixUrl}/texas-pwd-144`
      previewLink = `${link}/send_to_signer`
    }

    if (task_type === "purchase-&-sale-agreement-exclusion") {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/${listLink}/view/${id}/purchase-&-sale-agreement-exclusion/send_to_signer?doc_id=${row.id}`;
      } else if (type === "In-Progress") {
        // link = `/${listLink}/view/${id}/purchase-&-sale-agreement-exclusion?doc_id=${row.id}`;
        previewLink = `/${listLink}/view/${id}/purchase-&-sale-agreement-exclusion/send_to_signer?doc_id=${row.id}`;
      }
      link = `/${listLink}/view/${id}/purchase-&-sale-agreement-exclusion?doc_id=${row.id}`;
    }

    if (task_type === "acceptance-of-vessel") {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/${listLink}/view/${id}/acceptance-of-vessel/send_to_signer?doc_id=${row.id}`;
      } else if (type === "In-Progress") {
        // link = `/${listLink}/view/${id}/acceptance-of-vessel?doc_id=${row.id}`;
        previewLink = `/${listLink}/view/${id}/acceptance-of-vessel/send_to_signer?doc_id=${row.id}`;
      }
      link = `/${listLink}/view/${id}/acceptance-of-vessel?doc_id=${row.id}`;
    }

    if (["co-broker-commission-agreement", "cyba-cooperative-agreement"].includes(task_type)) {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/${listLink}/add/${id}/co_broker`;
      } else if (type === "In-Progress") {
        // link = `/${listLink}/add/${id}/co_broker`;
        previewLink = `/${listLink}/add/${id}/co_broker`;
      }
      link = `/${listLink}/add/${id}/co_broker`;
    }

    if (task_type === 'survey-waiver') {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
      }
    }

    const eSignBtn = () => (
      <ThemeButton onClick={() => {
        setTab("signatures")
        history.push(`/${listLink}/view/${id}/main#tab=%22signatures%22`, { task_type })
      }} style={{ padding: "0 10px" }}
        disabled={row?.t_esign_template?.is_custom_template && !row?.manual_edocuments_versions?.length}
      >
        {handSignOnlyDocs.includes(task_type) ? 'Hand Sign' : 'eSign'}
      </ThemeButton>
    )

    // const formDataBtn = (link) => {
    //   if (task_type === 'survey-waiver') {
    //     return <ThemeButton theme="primary" onClick={openSurveyWaiverFormDataModal}>
    //       <img src="/icons/edit.svg" class="svg"></img>
    //       Form Data
    //     </ThemeButton>
    //   }
    //   return <LinkButton to={link} theme="primary">
    //     <img src="/icons/edit.svg" class="svg"></img>
    //     Form Data
    //   </LinkButton>
    // }

    const resetBtn = (row) => {
      return <ThemeButton onClick={() => handleReset(row)} theme="danger">
        <img src="/icons/pdf.svg" class="svg"></img>
        Reset
      </ThemeButton>
    }

    switch (type) {
      case "Draft":
        return (
          <>
            {!handSignOnlyDocs.includes(task_type) && (
              <>
                {/* {formDataBtn(link)} */}
                {renderButton(row)
                  ? resetBtn(row)
                  : <></>
                }
              </>
            )}
            {eSignBtn()}
          </>
        );
      case "In-Progress":
        return (
          <>
            {!handSignOnlyDocs.includes(task_type) && (
              <>
                {/* {formDataBtn(link)} */}
                {renderButton(row)
                  ? resetBtn(row)
                  : <></>
                }
              </>
            )}
            {/* <ThemeButton data-uk-toggle="target: #modal-view-purchase-sale-agreement">
              Details
            </ThemeButton> */}
            {eSignBtn()}
            {/* <ThemeButton onClick={() => handleReset(row)}>Reset</ThemeButton> */}
          </>
        );
      case "Completed":
        return (
          <>
            {renderButton(row)
              ? resetBtn(row)
              : <></>
            }
          </>
        );
      default:
        return (
          <>
            {handSignOnlyDocs.includes(task_type) ? (
              <>
                {eSignBtn()}
              </>
            ) : (
              <>
                {/* {formDataBtn(link)} */}
                {renderButton(row)
                  ? resetBtn()
                  : <></>
                }
              </>
            )}
          </>
        );
    }
  };

  const showStatus = (status) => {
    if (status === "Completed") {
      return "Fully Executed";
    } else {
      return status || "Open";
    }
  };

  // onClickPdfStarts here-----
  const onClickPdfEdit = async (row) => {
    const logged_user = Auth.getInstance().getUserData();
    const documentName = row?.t_esign_template?.is_custom_template ? "Custom E-Sign Transaction Template" : docArr.includes(row.title) ? row?.t_esign_template?.template?.template_name : row.title
    const documentId = row.id;
    const templateLink = `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${row?.t_esign_template?.template?.template_link}`;
    let id = dataTransaction.id;
    switch (documentName) {
      case "Trade-In Vessel":
        const dataTradeInVessel = dataTransaction.t_trade_in_vessel ? dataTransaction.t_trade_in_vessel : null;
        id = dataTradeInVessel?.id || id;
        break;
      case "Seller Closing Statement":
        const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
        id = dataSellerClosing?.id || id;
        break;
      case "Buyer Closing Statement":
        const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
        id = dataBuyerClosing?.id || id;
        break;
      case "IYBA Conditional Acceptance":
      case "Conditional Acceptance with Survey Allowance":
      case "IYBA Rejection of Vessel":
      case "IYBA Acceptance of Vessel":
        const dataAcceptanceList = dataTransaction.t_acceptance || [];
        const acceptance = dataAcceptanceList.find(el => el.id === documentId);
        id = (acceptance && acceptance?.id) ? acceptance?.id : `${id}/${documentId}`;
        break;
      case "Co-Broker Commission Agreement":
        const dataCobroker = dataTransaction?.co_broker || null;
        id = dataCobroker?.id || id;
        break;
      case "Purchase & Sale Agreement Exclusion":
        const exclusions = dataTransaction?.t_purchase_exclusion || [];
        const exclusion = exclusions.find(el => el.id === documentId);
        id = (exclusion && exclusion?.id) ? `${exclusion?.id}` : `${id}/${documentId}`;
        break;
      case "Purchase & Sale Agreement Addendum":
        const dataAddendum = deals.transaction ? deals.transaction.t_purchase_addendum || [] : [];
        const addendum = dataAddendum.find(el => el.t_edocument_id === documentId);
        id = (addendum && addendum?.id) ? `${addendum?.id}` : `${id}/${documentId}`;
        break;
      case "CYBA Counter Offer":
      case "Bill of Sale - Notary (Seller Only)":
      case "Bill of Sale - Notary":
      case "YBAA Amendment to PSA":
      case "YBAA Amendment to PSA (Short Form)":
      case "Counter Offer":
      case "NYBA Counter Offer":
        // case "Custom E-Sign Transaction Template":
        id = `${dataTransaction.id}/${documentId}`;
        break;
      default:
        id = dataTransaction.id;
        break;
    }
    dispatch(setLoadingStatus(true));
    try {
      const docResponse = (await EDocService.getEditorDocumentPdf(documentId))?.data;

      // call api for get all fields of template
      const docFieldsResponse = (await EDocService.getEditorDocumentPdfFields(documentId))?.data;

      if (docResponse.found && docResponse.pdf_url) {
        dispatch(setLoadingStatus(false));
        history.push({
          pathname: `/${listLink}/view/${dataTransaction.id}/preview/edit`,
          state: {
            file_url: docResponse.pdf_url,
            documentId: documentId,
            documentName: documentName,
            dataTransactionId: id,
            transactionId: dataTransaction.id,
            type: 'deals',
            haveEditVersions: true,
            templateLink: templateLink,
            uniqueId: `PS-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
            companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
            updated_elements: docResponse?.updated_elements ? [...docResponse?.updated_elements] : [],
            roles: [...docResponse?.allowed_template_roles],
            is_pdf_merge: docResponse?.is_pdf_merge,
            task_type: row?.task_type,
            form_fields: docFieldsResponse || [],
          }
        });
      } else if (row?.t_esign_template?.is_custom_template && !docResponse?.pdf_url) {
        dispatch(setLoadingStatus(false));
        history.push({
          pathname: `/${listLink}/view/${dataTransaction.id}/preview/edit`,
          state: {
            file_url: "",
            documentId: documentId,
            documentName: documentName,
            dataTransactionId: id,
            transactionId: dataTransaction.id,
            type: 'deals',
            haveEditVersions: true,
            templateLink: templateLink,
            uniqueId: `PS-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
            companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
            updated_elements: [],
            roles: [
              "Buyer",
              "Co-Buyer",
              "Seller",
              "Co-Seller"
            ],
            is_pdf_merge: false,
            task_type: row?.task_type,
            form_fields: docFieldsResponse || [],
          }
        });
      }
      else {
        let previewObj = { mode: "preview" };
        if (row?.t_esign_template?.is_custom_template) {
          previewObj = { ...previewObj, transaction_edoc_id: row?.id }
        }
        TransactionService.getPreview(documentName, id, previewObj).then((res) => {
          dispatch(setLoadingStatus(false));
          if (res.status !== 200) {
            throw res.data;
          } else {
            history.push({
              pathname: `/${listLink}/view/${dataTransaction.id}/preview/edit`,
              state: {
                file_url: res.data.url,
                documentId: documentId,
                documentName: documentName,
                dataTransactionId: id,
                transactionId: dataTransaction.id,
                type: 'deals',
                haveEditVersions: false,
                templateLink: templateLink,
                uniqueId: `PS-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
                companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
                roles: [...docResponse?.allowed_template_roles],
                is_pdf_merge: docResponse?.is_pdf_merge,
                task_type: row?.task_type,
                form_fields: docFieldsResponse || [],
              }
            });
          }
        }).catch((err) => {
          if (err.message.includes('401')) {
            dispatch(setLoadingStatus(false));
            toast.error('You are not authorized for this request.');
            return;
          }
          TransactionService.getDocumentUrl(documentName)
            .then((res) => {
              dispatch(setLoadingStatus(false));
              if (res?.data.url) {
                history.push({
                  pathname: `/${listLink}/view/${dataTransaction.id}/preview/edit`,
                  state: {
                    file_url: res.data.url,
                    documentId: documentId,
                    documentName: documentName,
                    dataTransactionId: id,
                    transactionId: dataTransaction.id,
                    type: 'deals',
                    haveEditVersions: false,
                    uniqueId: `PS-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
                    companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
                    task_type: row?.task_type
                  }
                });
              } else {
                toast.error("Error occurs, please try again.", {
                  autoClose: 2000
                })
              }
            }).catch(() => {
              dispatch(setLoadingStatus(false));
              toast.error("Error occurs, please try again.", {
                autoClose: 2000
              })
            })
        });
      }

    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      });
    }
  }
  //on click pdf ends here -----
  const openPreviewModal = async (request_signature, row) => {
    if (!request_signature || request_signature?.signers?.length == 0) {
      if (docArr.includes(row.title)) {
        await previewByStep(row?.t_esign_template?.template?.template_name, row.id, row);
        return;
      }
      await previewByStep(row.title, row.id, row)
      return;
    }

    if (dataTransaction?.seller_type === "OWNER_OF_RECORD") {

      setRequestSignature(() => ({ ...request_signature, signers: request_signature?.signers?.map(signer => signer?.request_signer?.role === "Seller" ? { ...signer, request_signer: { ...signer?.request_signer, name: "Owner of Record" } } : { ...signer }) }));
      // setRequestSignature(request_signature);

    } else {
      setRequestSignature(request_signature);
    }
    setIsPreview(true);
  }

  const closePreviewModal = () => {
    setIsPreview(false);
  }

  const previewByStep = (documentName, documentId, row) => {
    try {
      dispatch(setLoadingStatus(true));
      let id = dataTransaction.id;
      switch (documentName) {
        case "Trade-In Vessel":
          const dataTradeInVessel = dataTransaction.t_trade_in_vessel ? dataTransaction.t_trade_in_vessel : null;
          id = dataTradeInVessel?.id || id;
          break;
        case "Seller Closing Statement":
          const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
          id = dataSellerClosing?.id || id;
          break;
        case "Buyer Closing Statement":
          const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
          id = dataBuyerClosing?.id || id;
          break;
        case "IYBA Conditional Acceptance":
        case "Conditional Acceptance with Survey Allowance":
        case "IYBA Rejection of Vessel":
        case "IYBA Acceptance of Vessel":
          const dataAcceptanceList = dataTransaction.t_acceptance || [];
          const acceptance = dataAcceptanceList.find(el => el.id === documentId);
          id = (acceptance && acceptance?.id) ? acceptance?.id : `${id}/${documentId}`;
          break;
        case "Co-Broker Commission Agreement":
          const dataCobroker = dataTransaction?.co_broker || null;
          id = dataCobroker?.id || id;
          break;
        case "Purchase & Sale Agreement Exclusion":
          const exclusions = dataTransaction?.t_purchase_exclusion || [];
          const exclusion = exclusions.find(el => el.id === documentId);
          id = (exclusion && exclusion?.id) ? `${exclusion?.id}` : `${id}/${documentId}`;
          break;
        case "Purchase & Sale Agreement Addendum":
          const dataAddendum = deals.transaction ? deals.transaction.t_purchase_addendum || [] : [];
          const addendum = dataAddendum.find(el => el.t_edocument_id === documentId);
          id = (addendum && addendum?.id) ? `${addendum?.id}` : `${id}/${documentId}`;
          break;
        case "CYBA Counter Offer":
        case "Bill of Sale - Notary (Seller Only)":
        case "Bill of Sale - Notary":
        case "YBAA Amendment to PSA":
        case "YBAA Amendment to PSA (Short Form)":
        case "Counter Offer":
        case "NYBA Counter Offer":
          id = `${dataTransaction.id}/${documentId}`;
          break;
        default:
          id = dataTransaction.id;
          break;
      }
      let previewObj = { mode: "preview" };
      if (row?.t_esign_template?.is_custom_template) {
        previewObj = { ...previewObj, transaction_edoc_id: row?.id }
      }
      TransactionService.getPreview(row?.t_esign_template?.is_custom_template ? "Custom E-Sign Transaction Template" : documentName, id, previewObj)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          if (res.status !== 200 && res.data.url == null) {
            throw res.data;
          } else {
            window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
          }
        })
        .catch((err) => {
          if (err.message.includes('401')) {
            dispatch(setLoadingStatus(false));
            toast.error('You are not authorized for this request.');
            return;
          }
          // TransactionService.getDocumentUrl(row?.t_esign_template?.is_custom_template ? "Custom E-Sign Transaction Template" : documentName)
          //   .then((res) => {
          //     dispatch(setLoadingStatus(false));
          //     if (res?.data.url) {
          //       window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
          //     } else {
          //       toast.error("Error occurs, please try again.", {
          //         autoClose: 2000
          //       })
          //     }
          //   })
          //   .catch(() => {
          //     dispatch(setLoadingStatus(false));
          //     toast.error("Error occurs, please try again.", {
          //       autoClose: 2000
          //     })
          //   })
        });
    } catch (error) {
      // TransactionService.getDocumentUrl(row?.t_esign_template?.is_custom_template ? "Custom E-Sign Transaction Template" : documentName)
      //   .then((res) => {
      //     dispatch(setLoadingStatus(false));
      //     if (res?.data.url) {
      //       window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
      //     } else {
      //       toast.error("Error occurs, please try again.", {
      //         autoClose: 2000
      //       })
      //     }
      //   })
      //   .catch((err) => {
      //     dispatch(setLoadingStatus(false));
      //     toast.error("Error occurs, please try again.", {
      //       autoClose: 2000
      //     })
      //   })
    }
  }

  const modifyEdocumentsTitle = (row) => {
    let modifiedTitle = row.is_duplicate ? row?.duplicate_title : row?.t_esign_template?.template?.template_name || "";
    if (row.task_type == "acceptance-of-vessel") {
      const status = parseInt(dataAcceptance?.find(el => el.id == row.id)?.status);
      switch (status) {
        case 0:
          modifiedTitle += " - Accept"
          break;
        case 1:
          modifiedTitle += " - Conditional"
          break;
        case 2:
          modifiedTitle += " - Conditional With Survey"
          break;
        case 3:
          modifiedTitle += " - Reject"
          break;

        default:
          break;
      }
    }
    if (row.task_type == "commission-agreement-(seller,co-broker)") {
      modifiedTitle = 'Commission Agreement (Seller/Co-Broker)'
    }
    return modifiedTitle;
  }

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleDelete = async (id) => {
    try {
      dispatch(setLoadingStatus(true));
      await TransactionService.deleteEDocById(id);
      dispatch(setLoadingStatus(false));
      toast.success("Document deleted successfully.", {
        autoClose: 2000
      });
      fetchEDocuments(dataTransaction?.id)
    } catch (error) {
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    }
  };

  const fetchEDocuments = (transactionId) => {
    dispatch(setLoadingStatus(true));
    dispatch(getTransactionEdocById(transactionId))
      .then((res) => dispatch(setLoadingStatus(false)))
      .catch((err) => {
        toast.error("Error occurs, please try again.", {
          autoClose: 2000
        })
      })
  }

  const renderSendPreviewButton = () => {
    return <>
      <button className={`uk-button uk-button-primary`} disabled={selectedDocuments.length == 0} onClick={onSendSelectedDocuments}>Send Preview</button>
    </>
  }

  const onChangeDocumentSelection = (row) => {
    setSetselectedDocuments(
      selectedDoc => {
        if (selectedDoc.filter(document => document.edoc_id == row.id).length) {
          return selectedDoc.filter(doc => doc.edoc_id !== row.id);
        } else {
          let id = dataTransaction.id;
          switch (row.title) {
            case "Trade-In Vessel":
              const dataTradeInVessel = dataTransaction.t_trade_in_vessel ? dataTransaction.t_trade_in_vessel : null;
              id = dataTradeInVessel?.id || id;
              break;
            case "Seller Closing Statement":
              const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
              id = dataSellerClosing?.id || id;
              break;
            case "Buyer Closing Statement":
              const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
              id = dataBuyerClosing?.id || id;
              break;
            case "IYBA Conditional Acceptance":
            case "Conditional Acceptance with Survey Allowance":
            case "IYBA Rejection of Vessel":
            case "Acceptance of Vessel":
              const dataAcceptanceList = dataTransaction.t_acceptance || [];
              const acceptance = dataAcceptanceList.find(el => el.id === row.id);
              id = acceptance?.id || id;
              break;
            case "Co-Broker Commission Agreement":
              const dataCobroker = dataTransaction?.co_broker || null;
              id = dataCobroker?.id || id;
              break;
            case "Purchase & Sale Agreement Exclusion":
              const exclusions = dataTransaction?.t_purchase_exclusion || [];
              const exclusion = exclusions.find(el => el.id === row.id);
              id = exclusion?.id || id;
              break;
            case "Purchase & Sale Agreement Addendum":
              const dataAddendum = deals.transaction ? deals.transaction.t_purchase_addendum || [] : [];
              const addendum = dataAddendum.find(el => el.t_edocument_id === row.id);
              id = addendum?.id || id;
              break;
            default:
              id = dataTransaction.id;
              break;
          }
          return [
            ...selectedDoc,
            {
              id: id,
              edoc_id: row.id,
              document_name: row.task_type
            }];
        }
      }
    )
  }

  const onSendSelectedDocuments = () => {
    setShowSendEmailModal(true);
  }

  const isCompanyAdminCanEditPDF = () => {
    return dataTransaction?.broker?.company?.brokerage?.is_only_admin_can_edit_pdf
      ? ['Company Admin', 'Boatdox Admin'].includes(logged_user.UserInRole.UserRole.name) :
      true;
  }

  const columns = [
    {
      name: "",
      width: "40px",
      cell: (row) => {
        return (
          row?.title && row.title == "Acceptance of Vessel"
            ? <>
              <div>
                <span>
                  <i
                    className={clsx(
                      "fa",
                      { "fa-check-circle": row.status === "Completed" },
                      { "fa-circle-o": ["Draft", "In-Progress", "Open"].includes(row.status) || !row.status }
                      // { "fa-circle": row.status === "In-Progress" },
                      // { "fa-circle-o": row.status === "Open" || !row.status }
                    )}
                  />
                </span>
              </div>
            </>
            : <div>
              <span>
                <i
                  className={clsx(
                    "fa",
                    { "fa-check-circle": row.status === "Completed" },
                    { "fa-circle-o": ["Draft", "In-Progress", "Open"].includes(row.status) || !row.status }
                    // { "fa-circle circle-progress": row.status === "In-Progress" },
                    // { "fa-circle-o": row.status === "Open" || !row.status }
                  )}
                />
              </span>
            </div>
        )
      }
    },
    {
      name: "Title",
      // width: "360px",
      selector: "title",
      sortable: true,
      cell: (row) => <div>{row?.t_esign_template?.is_custom_template ? row.is_duplicate ? row?.duplicate_title : row?.t_esign_template?.custom_template_title : modifyEdocumentsTitle(row)}</div>,
    },
    {
      name: "Status",
      selector: "status",
      // width: "140px",
      sortable: true,
      cell: (row) => {
        if (row.task_type === "purchase-&-sales-agreement") {
          row.status = "Draft";
        }
        return (
          <span className="uk-label status">{showStatus(row.status)}</span>
        );
      },
    },
    {
      name: "Verify",
      center: true,
      cell: (row) => {
        return (
          <button style={{ marginRight: '0' }}
            disabled={row?.t_esign_template?.is_custom_template && !row?.manual_edocuments_versions?.length}
            onClick={() => {
              openPreviewModal(row.request_signature, row)
            }} className='uk-button uk-button-default' >
            Preview
          </button>
        )
      },
    },
    ...isCompanyAdminCanEditPDF()
      ? [{
        name: 'PDF Editor',
        // width: "140px",
        center: true,
        cell: (row) => {
          return (!renderButton(row) && !['iyba-purchase-&-sale---make-offer'].includes(row.task_type)) && isCompanyAdminCanEditPDF()
            ? <Tooltip title="PDF Editor" placement="left">
              <button className='btn btn-previous btn-sm' onClick={() => { onClickPdfEdit(row) }}>
                {(row.manual_edocuments_versions || row.manual_edoc) && (row.manual_edocuments_versions || row.manual_edoc)?.filter(version => version.is_current == true).length
                  ? <>
                    Edited
                  </>
                  : <>
                    <img src="/icons/view.svg" class="svg"></img>Edit
                  </>
                }
              </button>
            </Tooltip>
            : <></>
        }
      },]
      : [],
    {
      name: "Signature",
      width: "260px",
      cell: (row) => renderAction(row),
    },
    {
      name: "Duplicate",
      width: "105px",
      center: true,
      cell: (row) => {
        const isDuplicate = row?.t_esign_template?.template?.use_type || row?.t_esign_template?.template?.is_custom_template || null
        if (isDuplicate && isDuplicate === "multiple") {
          return (
            <>
              <Tooltip title="Duplicate" placement="left">
                <span className="copy_document"
                  onClick={() => {
                    copyDocument(row);
                  }}
                ><i class="fa fa-clone fa-lg"></i>
                </span>
              </Tooltip>

              {row?.is_duplicate &&
                <>
                  {!isParticipant() || (isParticipant() && logged_user?.id === row?.created_by_user_account_id) ? <Tooltip title="Delete" placement="right">
                    <a
                      className="delete_icon_link"
                      onClick={() => onDelete(row.id)}
                    >
                      <i class="fa fa-trash fa-lg text-danger" aria-hidden="true"></i>
                    </a>
                  </Tooltip> : <></>}

                </>
              }
            </>
          )
        }
      }
    },
    // {
    //   name: renderSendPreviewButton(),
    //   width: "115px",
    //   center: true,
    //   cell: (row) => {
    //     return (
    //       <div>
    //         <input type="checkbox" className="uk-checkbox" style={{ border: '1.75px solid #1ea2d5' }} checked={selectedDocuments?.filter(document => document.edoc_id == row.id).length} onClick={() => { onChangeDocumentSelection(row) }}
    //           onChange={() => { }} />
    //       </div>
    //     )
    //   }
    // }
  ];

  const copyDocument = async (row) => {
    try {
      dispatch(setLoadingStatus(true));
      const payload = {
        isCreatedByParticipant: isParticipant()
      }
      await TransactionService.copyDocuments(row.id, payload);
      fetchTransactionData(true);
    } catch (error) {
      dispatch(setLoadingStatus(false));
      console.log(error);
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    }
  }

  return (
    <>

      <div className="doc_div pt-3  justify-content-around">
        {
          successDocuments?.length > 0 &&
          successDocuments?.map((doc) => (
            <div className="uk-alert uk-alert-primary mx-3 " data-uk-alert>
              <p>{doc}</p>
            </div>
          ))
        }
        {
          failedDocuments?.length > 0 &&
          failedDocuments?.map((doc) => (
            <div className="uk-alert uk-alert-danger" data-uk-alert>
              <p>{doc}</p>
            </div>
          ))
        }
      </div>
      {
        isPreview && <PreviewModal request_signature={requestSignature} onClose={closePreviewModal}></PreviewModal>
      }

      {
        isReset && <DocumentResetModal edoc={resetEdocDetail} logged_user={logged_user} onClose={closeResetModal}
          uniqueId={dataTransaction?.unique_transaction_id && `PS-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`} companyUID={dataTransaction?.unique_transaction_id && `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`} />
      }

      {sorted_data.length == 0
        ? (
          <div className="no-record-container">
            {isLoading
              ? <p className="no-record">Fetching Details...</p>
              : (menuType === "invitation" || isParticipant())
                ? <p className="no-record">No documents to display.</p>
                : <p className="no-record">Please select documents for this transaction in your "Forms" tab above.</p>}
          </div>)
        : <DataTable
          columns={columns}
          data={sorted_data}
          highlightOnHover={true}
          striped={true}
          noHeader={true}
          customStyles={datatableStyles}
        />
      }
      {showSendEmailModal && <SendPreviewEmailModal show={showSendEmailModal} onClose={() => { setShowSendEmailModal(false); setSetselectedDocuments([]) }} documentList={selectedDocuments} setAlert={{ setSuccessDoc, setFailedDoc }} />}
    </>

  );
};

export default EdocumentsViewTable;
