import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Tab, Tabs } from '@mui/material';
import TabPanel from './TabPanel';
import TabFormExpenses from './panel_buyer_closing_statement/TabFormExpenses';
import TabFormCredits from './panel_buyer_closing_statement/TabFormCredits';
import TabFormDeposits from './panel_buyer_closing_statement/TabFormDeposits';
import TabFormNotes from './panel_buyer_closing_statement/TabFormNotes';
import TabFormTransactionDetails from './panel_buyer_closing_statement/TabFormTransactionDetails';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const PanelFormBuyerClosingStatement = ({ closePanel, menuType, handleOpenPanel }) => {

    const [current, setCurrent] = useState('t_details');
    const [asterix, setAsterix] = useState({});

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataBuyerClosing = dataDeal.t_buyer_closing;
    const dataDetails = dataDeal.t_details || [];
    // const toys = dataDeal.t_toys || [];
    // const tailers = dataDeal.t_trailer || [];
    // const additional_price = toys.reduce((total, toy) => total + toy.item_value, 0);

    useEffect(() => {
        if (dataBuyerClosing) {
            const { sales_price_total, sub_total, sales_tax_vessel, is_sales_tax, sales_price_additional_units } = dataBuyerClosing;
            if (is_sales_tax >= 0) {
                if (sales_price_total && sub_total && sales_tax_vessel >= 0) {
                    setAsterix("fulfill");
                } else if (sales_price_total || sub_total || sales_tax_vessel >= 0) {
                    setAsterix("notFulfill");
                } else {
                    setAsterix("empty");
                }
            } else {
                if (sales_price_total && sub_total) {
                    setAsterix("fulfill");
                } else if (sales_price_total || sub_total) {
                    setAsterix("notFulfill");
                } else {
                    setAsterix("empty");
                }
            }
        } else {
            setAsterix("empty");
        }
        registerRequiredFields(dataBuyerClosing);
    }, [dataTransaction]);

    const registerRequiredFields = (detail) => {
        let fields = [];
        if (detail) {
            const { sales_price_total, sub_total, sales_tax_vessel ,sales_price_additional_units } = detail;
            if (!sales_price_total) {
                fields.push({ sales_price_total: "Sales Price (total)" });
            }
            if (!sub_total) {
                fields.push({ sub_total: "Sub Total" });
            }
            // if (sales_tax_vessel && dataBuyerClosing.is_sales_tax) {
            //     fields.push({ sales_tax_vessel: "Sales Tax (Vessel)" });
            // }
            // if (tailers.reduce((total, trailer) => total + parseFloat(trailer.price), additional_price) !== sales_price_additional_units) {
            //     fields.push({ sales_price_additional_units: "Sales Price Additional Units" });
            // }
        } else {
            fields.push({ sales_price_total: "Sales Price (total)" });
            fields.push({ sub_total: "Sub Total" });
            fields.push({ sales_tax_vessel: "Sales Tax (Vessel)" });
            setAsterix((prev) => ({ ...prev, detail: "empty" }));
        }
        if (fields.length === 0) {
            setAsterix((prev) => ({ ...prev, detail: "fulfill" }));
        } else if (fields.length < 4) {
            setAsterix((prev) => ({ ...prev, detail: "notFulfill" }));
        }
        if (dataDetails.filter((d) => d.type === "expenses" && d.document_type === "buyer").length > 0) {
            setAsterix((prev) => ({ ...prev, expense: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, expense: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "credits" && d.document_type === "buyer").length > 0) {
            setAsterix((prev) => ({ ...prev, credit: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, credit: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "deposits" && d.document_type === "buyer").length > 0) {
            setAsterix((prev) => ({ ...prev, deposit: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, deposit: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "notes" && d.document_type === "buyer").length > 0) {
            setAsterix((prev) => ({ ...prev, note: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, note: "empty" }));
        }
    };

    const handleChange = (event, newValue) => {
        setCurrent(newValue);
    };

    const renderValidationCircle = (formType) => {
        return <i
            className={clsx(
                "pr-2 mb-1 fa ",
                { "fa-check-circle stepbar": asterix[formType] === "fulfill" },
                { "fa-circle-o": asterix[formType] === "empty" || !asterix[formType] },
                { "fa-circle": asterix[formType] === "notFulfill" }
            )}
        ></i>
    }

    return (
        <Fragment>
            <Tabs value={current} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab icon={renderValidationCircle('expense')} iconPosition='start' value="expenses" label="Expenses" />
                <Tab icon={renderValidationCircle('credit')} iconPosition='start' value="credits" label="Credits" />
                <Tab icon={renderValidationCircle('deposit')} iconPosition='start' value="deposits" label="Deposits" />
                <Tab icon={renderValidationCircle('note')} iconPosition='start' value="notes" label="Notes" />
                <Tab icon={renderValidationCircle('detail')} iconPosition='start' value="t_details" label="Transaction Details" />
            </Tabs>
            <Divider />
            <Fragment>
                <TabPanel value={current} index={'expenses'}>
                    <TabFormExpenses closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'credits'}>
                    <TabFormCredits closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'deposits'}>
                    <TabFormDeposits closePanel={closePanel} handleOpenPanel={handleOpenPanel} />
                </TabPanel>
                <TabPanel value={current} index={'notes'}>
                    <TabFormNotes closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'t_details'}>
                    <TabFormTransactionDetails closePanel={closePanel} menuType={menuType} />
                </TabPanel>
            </Fragment>
        </Fragment>
    )
}

export default PanelFormBuyerClosingStatement;