import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import {
  upsertTransaction,
  getExclusions,
  getTransactionInitialById,
} from "../../../../../redux/actions/transaction";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MarkdownEditor from "../../../../common/CkEditor/MarkdownEditor";

const schema = yup.object().shape({});

const AddExclusions = ({ step, setStep }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  // const [isShowMore, setIsShowMore] = useState(false);
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
  const dataListing = dataTransaction || {};
  const dataExclusions = dataListing?.t_exclusion?.[0]?.exclusion || "";
  const [note, setNote] = useState('');


  useEffect(() => {
    if (dataTransaction) {
      dispatch(getExclusions(dataListing.id));
    }
  }, []);

  useEffect(() => {
    if (dataExclusions) {
      setNote(dataExclusions);
      setValue('exclusion', dataExclusions);
    }
    // setIsShowMore(dataExclusions.length > 4);
  }, [dataExclusions]);

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleRegistration = (exclusions, isFinishLater = false) => {
    if (exclusions) {
      dispatch(
        upsertTransaction({
          step: {
            db: "exclusion",
            main: {
              data: exclusions,
            },
          },
          transaction: {
            isBelonged: false,
            main: {
              ...getPlainTransactionData(dataListing),
              task_type: "listing",
              broker_user_id: dataBroker?.id,
              company_id: dataBroker?.company_id,
              company_office_id: dataBroker?.company_office_id,
            },
          },
        }, "Exclusion")
      ).then((data) => {
        if (isFinishLater) {
          history.push(`/listing-agreement/view/${data.id}/main`);
        } else {
          dispatch(getTransactionInitialById(dataListing.id));
          setStep(step + 1);
        }
      });
    } else {
      setStep(step + 1);
    }
  };

  const handleError = () => {
    setStep(step + 1);
  };

  const links = [
    { label: "Listing Agreements", link: `/listing-agreement` },
    { label: "Add a Listing Agreement", link: null },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Exclusions" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      {/* <p>If you have more than 4 exclusions, skip this section and add it from the detail page.</p> */}
      <Form>
        <FormRow>
          <FormInputWrapper label="Exclusions" size="two">
            <MarkdownEditor noteProps={{ note, setNote }} setEditData={(data) => { setValue('exclusion', data); }} />
          </FormInputWrapper>
          {/* <FormInputWrapper label="Exclusion #1" size="two">
            <input {...register("exclusion1")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #2" size="two">
            <input {...register("exclusion2")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #3" size="two">
            <input {...register("exclusion3")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #4" size="two">
            <input {...register("exclusion4")} className="uk-input exclusion" type="text" />
          </FormInputWrapper> */}
        </FormRow>
        <FormRow style={{ marginTop: "16px" }}>
          <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
          <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
            Save & Finish Later
          </button>
        </FormRow>
        <AsterixTypo isVisible={false} />
      </Form>
    </FormPage>
  );
};

export default AddExclusions;
