import {
  GET_TRANSACTIONS,
  UPDATE_FIELD_OF_TRANSACTION,
  CLEAR_TRANSACTION,
  GET_OFFER_FOR_TRANSACTION,
  GET_ADDENDUM_FOR_TRANSACTION,
  GET_EXCLUSION_FOR_TRANSACTION,
  GET_CO_BROKER_FOR_TRANSACTION,
  GET_TRANSACTION_BY_ID,
  UPDATE_TASK_ORDER_FOR_TRANSACTION,
  UPDATE_TASK_FOR_TRANSACTION,
  INSERT_TASK_TO_TRANSACTION,
  DELETE_TASK_FOR_TRANSACTION,
  UPDATE_REQUIRED_FIELDS,
  ESIGN_REQUEST,
  DELETE_TOY_FROM_TRANSACTION,
  UPDATE_OPTION_REQUIRED_FIELDS,
  SET_BROKER,
  GET_LISTING_SELLER,
  RESET_EDOC,
  CLEAR_TRANSACTIONS,
  DELETE_COBROKER,
  GET_TRANSACTION_INITIAL_BY_ID,
  GET_TRANSACTION_EDOC_BY_ID,
  GET_TRANSACTION_ESIGN_BY_ID,
  GET_TRANSACTION_COMPANY_BY_ID,
  PUT_ASSOCIATION_FOR_TRANSACTION,
  SEND_EMAIL_TO_SIGNERS,
  GET_COMPANY_EDOC_TASK_LIST,
  GET_PARTICIPANTS_LIST
} from "./types";
import TransactionService from "../../services/TransactionService";
import DATService from "../../services/DocumentAndTaskService";
import { toast } from "react-toastify";
import Auth from "../../utils/auth";
import { isParticipant } from "../../utils/permission";

// Get all transactions
export const getAllTransactions = (search, broker = null, menuType = '') => async (dispatch) => {
  try {
    const loggedUser = Auth.getInstance().getUserData();
    const isParticipantLoggedIn = loggedUser.UserInRole.UserRole.name === 'Participant';
    const searchQuery = new URLSearchParams(search).toString();
    const res = menuType === "invitation"
      ? await TransactionService.getAllInviteDeals(loggedUser.id, search)
      : isParticipantLoggedIn
        ? await TransactionService.getAllParticipantDeals(loggedUser.id, search)
        : await TransactionService.getAll(broker, search);
    dispatch({
      type: GET_TRANSACTIONS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get all transactions by filter options
export const getAllByFilterOptions = (type, filterOptions, pageNo, size) => async (dispatch) => {
  try {
    const res = await TransactionService.getAllByFilterOptions(type, filterOptions, pageNo, size);
    dispatch({
      type: GET_TRANSACTIONS,
      payload: res.data.data,
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

// Upsert transaction
export const upsertTransaction = (data, type, sellerBuyer) => async (dispatch) => {
  try {
    const res = await TransactionService.upsert(data);

    type && toast.success(`${type || ""} details successfully submitted.`)
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// buyer-seller transaction
export const buyerSellerTransaction = (data, type) => async (dispatch) => {
  try {
    const res = await TransactionService.buyerSeller(data);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};


// Clear Deal
export const clearTransaction = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_TRANSACTION,
    });
  } catch (e) { }
};

// clear all
export const clearAllTransactions = () => (dispatch) => {
  dispatch({
    type: CLEAR_TRANSACTIONS,
  });
};

// Update Deals
export const updateTransaction = (id, data) => async (dispatch) => {
  try {
    const res = await TransactionService.update(id, data);

    if (Object.keys(data).includes("co_seller_contact_id") && !data.co_seller_contact_id) {
      data.co_seller = null;
    }
    if (Object.keys(data).includes("co_buyer_contact_id") && !data.co_buyer_contact_id) {
      data.co_buyer = null;
    }
    dispatch({
      type: UPDATE_FIELD_OF_TRANSACTION,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get offer
export const getOffer = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getOffer(id);

    dispatch({
      type: GET_OFFER_FOR_TRANSACTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get addendum
export const getAddendum = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getAddendum(id);

    dispatch({
      type: GET_ADDENDUM_FOR_TRANSACTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get exclusions
export const getExclusions = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getExclusions(id);

    dispatch({
      type: GET_EXCLUSION_FOR_TRANSACTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get cobroker
export const getCobroker = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getCobroker(id);

    dispatch({
      type: GET_CO_BROKER_FOR_TRANSACTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getParticipants = (companyId) => async (dispatch) => {
  try {
    const res = await TransactionService.fetchParticipants(companyId);

    return res.data
  } catch (err) {
    return Promise.reject(err);
  }
}

export const dealsESignRequest = (url, dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.dealsESignRequest(url, dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const buyersAsIsAcknowledgementESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.buyersAsIsAcknowledgementESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const authorizeOfReleaseOfFundsESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.authorizeOfReleaseOfFundsESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}


export const surveyWaiverESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.surveyWaiverESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

// for dispatch loading status of reducer and call api for response about document preview and edit work
export const disbursementOfFundsBuyerESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {

    const res = await TransactionService.disbursementOfFundsBuyerESignRequest(dealId, { mode, role, ...data });
    return Promise.resolve(res);

  } catch (err) {

    return Promise.reject(err);

  }
}

// call api for response about FL Removal Affidavit - 2024 document preview and edit work
export const flRemovalAffidavit2024ESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {

    const res = await TransactionService.flRemovalAffidavit2024ESignRequest(dealId, { mode, role, ...data });
    return Promise.resolve(res);

  } catch (err) {

    return Promise.reject(err);

  }
}

export const trialRunWaiverESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.trialRunWaiverESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}
export const potentialTaxLiabilityESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.potentialTaxLiabilityESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}
export const cancellationAndRefundRequestESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.cancellationAndRefundRequestESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const netToSellerESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.netToSellerESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const YBAAAcceptanceOfVesselESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.YBAAAcceptanceOfVesselESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const AsIsAcceptanceOfVesselESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.AsIsAcceptanceOfVesselESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const pierOneESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.pierOneESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const customTempleteESignRequest = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.customTempleteESignRequest(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return Promise.reject(err);
  }
}

export const eSignYBAAPurchaseAndSale = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignYBAAPurchaseAndSale(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignYBAAPurchaseAndSaleAgreementCanada = (dealId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignYBAAPurchaseAndSaleAgreementCanada(dealId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignDealAddendum = (addendumId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignDealAddendum(addendumId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignDealExclusion = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignDealExclusion(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const listingESignRequest = (listingId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.listingESignRequest(listingId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err
    });

    return err.response;
  }
}

export const pierOneListingESignRequest = (listingId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.pierOneListingESignRequest(listingId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err
    });

    return err.response;
  }
}

export const eSignListingAddendum = (addendumId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignListingAddendum(addendumId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignListingExclusion = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignListingExclusion(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignMaterialDamage = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignMaterialDamage(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignMaterialDamageDisclosureSellerFills = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignMaterialDamageDisclosureSellerFills(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignListingDisclosureSellerFills = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignListingDisclosureSellerFills(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignYbaaLA = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignYbaaLA(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignYBAAUsOpenListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignYBAAUsOpenListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignYBAACanadaOpenListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignYBAACanadaOpenListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCSAListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignCSAListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCYBAListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignCYBAListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCYBAExclusiveListingAgreementNet = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignCYBAExclusiveListingAgreementNet(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCYBAExclusiveListingAgreementGross = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignCYBAExclusiveListingAgreementGross(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCYBAListingAgreementAmendment = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignCYBAListingAgreementAmendment(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignYBAACanadaCentralListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignYBAACanadaCentralListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCYBAOpenListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignCYBAOpenListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignNYBAListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignNYBAListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignYBAAAmendmentToCentralListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignYBAAAmendmentToCentralListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}

export const eSignCoBroker = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCoBroker(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBACooperativeAgreement = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBACooperativeAgreement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignAcceptance = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignAcceptance(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSaleEngine = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleEngine(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSaleAllEngines = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleAllEngines(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSaleEngineOneToSix = (url, id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleEngineOneToSix(url, id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBAPurchaseAgreement = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBAPurchaseAgreement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignEscrowAgreement = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignEscrowAgreement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBAPurchaseAgreementAmendment = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBAPurchaseAgreementAmendment(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSale = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSale(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSaleNotary = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleNotary(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignWarrantyOfTitleNotary = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignWarrantyTitleNotary(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignUSCGBillOfSale = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignUSCGBillOfSale(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};
export const eSignTaxesPwd143 = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignTaxesPwd143(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignTaxesPwd144 = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignTaxesPwd144(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};


export const eSignAssignmentOfContract = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignAssignmentOfContract(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};
export const esignFLSalesTaxExemptionAffidavit = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.esignFLSalesTaxExemptionAffidavit(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};
export const esignFLAffidavitForAcceptanceOutsideFlorida = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.esignFLAffidavitForAcceptanceOutsideFlorida(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignUSPowerOfAttorneyBuyer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignUSPowerOfAttorneyBuyer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const esignUSCGDeletionRequest = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.esignUSCGDeletionRequest(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};
export const eSignUSPowerOfAttorneySeller = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignUSPowerOfAttorneySeller(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignFLPowerOfAttorneyForAVesselOrVesselWithTrailer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignFLPowerOfAttorneyForAVesselOrVesselWithTrailer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignFLDealersSalesTaxStmt = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignFLDealersSalesTaxStmt(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBABuyerFinalStatement = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBABuyerFinalStatement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBACounterOffer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBACounterOffer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCounterOffer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCounterOffer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignYBAAAmendmentToPSACommon = (url, id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignYBAAAmendmentToPSACommon(url, id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignNYBACounterOffer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignNYBACounterOffer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignNYBAPurchaseAndSales = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignNYBAPurchaseAndSales(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};


export const eSignBillOfSaleNotarySellerOnly = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleNotarySellerOnly(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBASellerFinalStatement = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBASellerFinalStatement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignPayoffVerification = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignPayoffVerification(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignEscrowAgreementDisbursementAuthorization = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignEscrowAgreementDisbursementAuthorization(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignReleaseOfLiabilityAndHoldHarmless = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignReleaseOfLiabilityAndHoldHarmless(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignRegistrationWaiver = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignRegistrationWaiver(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignYBAAEscrowAgreement = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignYBAAEscrowAgreement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSaleCommon = (url, id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleCommon(url, id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignBillOfSaleTrailer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignBillOfSaleTrailer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBARepairAllowanceAddendum = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBARepairAllowanceAddendum(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBATrialSurvey = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBATrialSurvey(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCancelationOfPurchaseBuyer = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCancelationOfPurchaseBuyer(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignCYBATransactionSummary = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCYBATransactionSummary(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignDealSummary = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignDealSummary(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignFLHSMV82040ApplicationForCertificateOfTitleVessel = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignFLHSMV82040ApplicationForCertificateOfTitleVessel(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignLoanPayoffAuthorization = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignLoanPayoffAuthorization(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignDisbursementOfFundsSeller = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignDisbursementOfFundsSeller(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignResolutionToBuySellCorpLLC = (url, id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignResolutionToBuySellCorpLLC(url, id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignAffidavitOfNonExport = (url, id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignAffidavitOfNonExport(url, id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};
// Buyer Closing E-Sign Statement
export const getBuyerClosing = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.buyerClosingStatement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

// Seller Closing E-Sign Statement
export const getSellerClosing = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.sellerClosingStatement(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};


//  eSign Commission Agreement (Seller) eSign
export const eSignCommissionAgreementSeller = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCommissionAgreementSeller(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};
//  eSign Commission Agreement (Seller) eSign
export const eSignCommissionAgreementSellerCoBroker = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignCommissionAgreementSellerCoBroker(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

// Trade-In Vessel Addendum E-Sign
export const eSignTradeInVessel = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignTradeInVessel(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};


export const eSignMaterialDamageDeal = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignMaterialDamageDeal(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignMaterialDamageDisclosureSellerFillsDeal = (id, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading",
    });

    const res = await TransactionService.eSignMaterialDamageDisclosureSellerFillsDeal(id, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
};

export const eSignYBMLSListingAgreement = (transactionId, mode, role, data = null) => async (dispatch) => {
  try {
    dispatch({
      type: ESIGN_REQUEST.pending,
      payload: "loading"
    });

    const res = await TransactionService.eSignYBMLSListingAgreement(transactionId, { mode, role, ...data });

    dispatch({
      type: ESIGN_REQUEST.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: ESIGN_REQUEST.error,
      payload: err,
    });

    return err.response;
  }
}


// Get Deal by Id
export const getTransactionbyId = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getTransactionbyId(id);

    dispatch({
      type: GET_TRANSACTION_BY_ID,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get Transaction initial
export const getTransactionInitialById = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getTransactionInitialById(id);

    dispatch({
      type: GET_TRANSACTION_INITIAL_BY_ID,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get Transaction EDOC list
export const getTransactionEdocById = (id, type = false, companyId = null) => async (dispatch) => {
  const loggedUser = Auth.getInstance().getUserData();
  const userId = loggedUser?.id
  // const companyId = loggedUser?.company_id

  try {
    const res = await TransactionService.getTransactionEdocListById(id, userId, companyId, (isParticipant() || type), type == true ? 'invitation_deals' : 'participants');
    const allDocs = res.data?.t_edocuments?.map((edoc) => {
      return edoc.transaction_edoc || edoc
    });


    dispatch({
      type: GET_TRANSACTION_EDOC_BY_ID,
      payload: { ...res.data, t_edocuments: allDocs },
    });
    return Promise.resolve({ ...res.data, t_edocuments: allDocs });
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get Company Task List for Deal/LA
export const getEdocCompanyTaskList = (companyId, checklistId, search) => async (dispatch) => {
  try {
    const searchQuery = new URLSearchParams(search).toString();
    const response = await TransactionService.getEdocCompanyTaskById(companyId, checklistId, searchQuery);

    dispatch({
      type: GET_COMPANY_EDOC_TASK_LIST,
      payload: response.data
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get Transaction esign
export const getTransactionEsignById = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getTransactionEsignById(id);

    dispatch({
      type: GET_TRANSACTION_ESIGN_BY_ID,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Get Transaction company
export const getTransactionCompanyById = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.getTransactionCompanyById(id);

    dispatch({
      type: GET_TRANSACTION_COMPANY_BY_ID,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update Sort order of tasks of deal
export const reOrderTasks = (tasks) => async (dispatch) => {
  try {
    const res = await DATService.updateTaskOrder(tasks, "transaction");

    dispatch({
      type: UPDATE_TASK_ORDER_FOR_TRANSACTION,
      payload: tasks,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Update task title
export const updateTaskForTransaction = (task) => async (dispatch) => {
  try {
    const res = await DATService.updateTask(task.master_task_id, task, "transaction");

    dispatch({
      type: UPDATE_TASK_FOR_TRANSACTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Create task
export const insertTaskToTransaction = (task) => async (dispatch) => {
  try {
    const res = await DATService.createTask(task, "transaction");

    dispatch({
      type: INSERT_TASK_TO_TRANSACTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Delete task
export const removeTaskFromTransaction = (id, userId = null) => async (dispatch) => {
  try {
    const res = await DATService.removeTask(id, "transaction", userId);

    dispatch({
      type: DELETE_TASK_FOR_TRANSACTION,
      payload: id,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Create required fields list
export const updateRequiredFields = (fields) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_REQUIRED_FIELDS,
      payload: fields,
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
  }
};

//Update toys for remove
export const removeToyFromTransaction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TOY_FROM_TRANSACTION,
      payload: id,
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

//Update options required fields
export const updateOptionsRequiredFields = (fields) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_OPTION_REQUIRED_FIELDS,
      payload: fields,
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

// Set broker
export const setBroker = (broker) => async (dispatch) => {
  try {
    dispatch({
      type: SET_BROKER,
      payload: broker,
    });
    return Promise.resolve();
  } catch (err) {
    return err;
  }
};

// Get Listing Seller
export const getListingSeller = (transaction_id, client_id) => async (dispatch) => {
  try {
    const res = await TransactionService.getListingSeller(transaction_id, client_id);

    dispatch({
      type: GET_LISTING_SELLER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const resetEdoc = (edoc_id, data) => async (dispatch) => {
  try {
    const res = await TransactionService.resetEdoc(edoc_id, data);

    dispatch({
      type: RESET_EDOC,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendEmail = (edoc_id) => async (dispatch) => {
  try {
    const res = await TransactionService.sendEmail(edoc_id);

    dispatch({
      type: SEND_EMAIL_TO_SIGNERS,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const deleteCobroker = (id) => async (dispatch) => {
  try {
    const res = await TransactionService.deleteCobroker(id);

    dispatch({
      type: DELETE_COBROKER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (e) {
    return e;
  }
};

export const putAssociationForTransaction = association => ({
  type: PUT_ASSOCIATION_FOR_TRANSACTION,
  payload: association
});

export const setAppliedTagFilter = (transactionId, data = null) => async (dispatch) => {
  try {
    const response = await TransactionService.setAppliedFilterTagData(transactionId, data);
    return Promise.resolve(response.data);
  } catch (error) {
    toast.error(error.error.message);
    console.error(error);
    return Promise.reject(error);
  }
}

export const addVesselImage = (id, data) => async dispatch => {
  try {
    const response = await TransactionService.addVesselImage(id, data);
    return Promise.resolve(response.data);
  } catch (error) {
    toast.error(error.error.message);
    console.error(error);
    return Promise.reject(error);
  }
}

export const getTransactionTasks = (data) => async dispatch => {
  try {
    const response = await TransactionService.getAllTransactionTasks(data);
    return Promise.resolve(response.data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

export const removeUploadDocument = (documentId, data = null) => async dispatch => {
  try {
    const response = await DATService.deleteUploadDocument(documentId, data);
    return Promise.resolve(response.data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

// Temporary for Migration
export const addRecords = (id, companyId) => async (dispatch) => {
  try {
    const response = await TransactionService.addRecords(id, companyId);
    return Promise.resolve(response.data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

export const addRecordsToys = (id, companyId) => async (dispatch) => {
  try {
    const response = await TransactionService.addRecordsForToys(id, companyId);
    return Promise.resolve(response.data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

export const addRecordsClients = (id, companyId) => async (dispatch) => {
  try {
    const response = await TransactionService.addRecordsForClients(id, companyId);
    return Promise.resolve(response.data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

// export const migrateFormForBucket = () => async (dispatch) => {
//   try {
//     const response = await TransactionService.migrateFormForBucket();
//     return Promise.resolve(response.data);
//   } catch (error) {
//     console.error(error);
//     return Promise.reject(error);
//   }
// }

// export const migrateEsignBucket = () => async (dispatch) => {
//   try {
//     const response = await TransactionService.migrateEsignBucket();
//     return Promise.resolve(response.data);
//   } catch (error) {
//     console.error(error);
//     return Promise.reject(error);
//   }
// }