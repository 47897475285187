import { combineReducers } from "redux";

import clients from "./clients";
import countries from "./countries";
import states from "./states";
import companies from "./companies";
import offices from "./offices";
import vessel_toys from "./vessels_toys";
import documents from "./document_tasks";
import users from "./users";
import auth from "./auth";
import loading from "./loading";
import edocs from "./edoc-templates";
import transactions from "./transaction";
import dashboard from "./dashboard";
import esign from "./esign";
import associations from "./associations";
import templates from "./templates";
import report from './report'
import tutorials from './tutorials'
import pdfEditorReducer from "./pdfEditor";

import { USER_LOGOUT } from "../actions/types";

const appReducer = combineReducers({
  clients,
  countries,
  states,
  companies,
  offices,
  vessel_toys,
  documents,
  users,
  auth,
  loading,
  edocs,
  transactions,
  dashboard,
  esign,
  associations,
  templates,
  report,
  tutorials,
  pdfEditorReducer
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;
