import {
  REMOVE_ALL_PDF_EDITOR_FIELD,
  SET_PDF_EDITOR_FIELD,
  UPDATE_PDF_EDITOR_FIELD,
} from "./types";


export const updatePdfEditorField = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PDF_EDITOR_FIELD,
      payload: payload,
    });
  } catch (err) {
    console.log(err);
  }
};


export const setPdfEditorField = (payload) => async (dispatch) => {
  try {

    dispatch({
      type: SET_PDF_EDITOR_FIELD,
      payload: payload,
    });
  } catch (err) {
    console.log(err);
  }
};


export const removeAllPdfEditorField = () => async (dispatch) => {
  try {

    dispatch({
      type: REMOVE_ALL_PDF_EDITOR_FIELD,
      payload: {},
    });
  } catch (err) {
    console.log(err);
  }
};