import React, { useEffect, useState, useRef, useCallback } from "react";

import SignaturePortal from "./Portal";
import SignaturePad from "react-signature-canvas";

import './Signature.css';

const initialState = {
  drawType: false,
  signatureText: "",
  fontFamily: "wonderwall",
  prevSignatureText: "",
  isEmptyCanvas: true,
};

const PDFSignature = (props) => {
  let sigCanvas = useRef({}); // Reference for the SignaturePad component
  let previousDrawing = useRef({}); // Reference to store the previous drawing (before clearing)
  let tabChangeDrawingStore = useRef({}); // Reference for the drawing store before tab change

  const [{ drawType, signatureText, fontFamily, prevSignatureText, isEmptyCanvas }, setState] = useState(initialState);
  const families = ["wonderwall", "Meddon", "Signatura", "Caveat"];

  function isCanvasEmpty(canvas) {
    const blankCanvas = document.createElement('canvas');
    blankCanvas.width = canvas._canvas.width;
    blankCanvas.height = canvas._canvas.height;
    return canvas.toDataURL() === blankCanvas.toDataURL();
  }

  useEffect(() => {
    setState(prevState => ({ ...initialState, fontFamily: prevState?.fontFamily, prevSignatureText: prevState?.signatureText, isEmptyCanvas: isCanvasEmpty(sigCanvas?.current) }));
  }, [props.modalType]);

  const handleDone = useCallback(() => {
    props.handleUpdateCanvas({
      drawType: drawType,
      signatureText: signatureText,
      isEmptyCanvas: isCanvasEmpty(sigCanvas.current),
      fontFamily: fontFamily,
      canvasData: sigCanvas.current.toDataURL("image/png"),
      elementId: props.elementId,
      width: sigCanvas.current._canvas.width,
      height: sigCanvas.current._canvas.height
    });
    setState(prevState => ({ ...prevState, prevSignatureText: signatureText }));
    if (sigCanvas.current) {
      const drawingData = sigCanvas?.current?.toDataURL(); // Save the current drawing as Base64
      previousDrawing.current = drawingData; // Store it in `previousDrawing`
    }
  }, [fontFamily, drawType, signatureText, props.elementId]);

  const handleCancel = useCallback(() => {
    setState(prevState => {
      if (previousDrawing?.current && sigCanvas?.current) {
        // clear canvas to restore previous drawing
        sigCanvas.current.clear();
        // Restore the previous drawing from `previousDrawing`
        sigCanvas?.current?.fromDataURL(previousDrawing?.current);
        return { ...prevState, signatureText: prevState?.prevSignatureText, isEmptyCanvas: false }
      } else {
        return { ...prevState, signatureText: prevState?.prevSignatureText }
      }
    });
  }, []);

  const removeSignerData = () => {
    props.removeSignerData(props.elementId)
  }

  useEffect(() => {
    setState(prevState => {
      if (tabChangeDrawingStore?.current && sigCanvas?.current) {
        // clear canvas to restore previous drawing
        sigCanvas.current.clear();
        // Restore the previous drawing from `tabChangeDrawingStore`
        sigCanvas?.current?.fromDataURL(tabChangeDrawingStore?.current);
        return { ...prevState, isEmptyCanvas: false }
      } else {
        return { ...prevState }
      }
    });
    window.dispatchEvent(new Event("resize"));
  }, [drawType])

  // when drawing ends on canvas then call for done button activeness
  const handleDrawEnd = () => {
    setState(prevState => ({ ...prevState, isEmptyCanvas: isCanvasEmpty(sigCanvas?.current) }));
  }

  return (
    <SignaturePortal>
      <div className="uk-modal-dialog">
        <button className="uk-modal-close-default" type="button" data-uk-close="true"></button>
        <div className="uk-modal-header">
          <h5>Add {props.modalType}</h5>
        </div>
        <div className="uk-modal-body">

          <ul data-uk-tab="swiping: false" className="uk-tab">
            <li className={!drawType ? "uk-active" : ""} onClick={() => {
              if (sigCanvas.current) {
                const drawingData = sigCanvas?.current?.toDataURL(); // Save the current drawing as Base64
                tabChangeDrawingStore.current = drawingData; // Store it in `tabChangeDrawingStore`
              }
              setState(prevState => ({ ...prevState, drawType: false }))
            }
            }>
              <a style={{ fontSize: '1.2rem' }}>Type</a>
            </li>
            <li className={drawType ? "uk-active" : ""} onClick={() => setState(prevState => ({ ...prevState, drawType: true }))}>
              <a style={{ fontSize: '1.2rem' }}>Draw</a>
            </li>
          </ul>


          <div hidden={!drawType}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px', width: '100%', height: '240px', cursor: 'cross' }}>
              <SignaturePad onEnd={handleDrawEnd} ref={sigCanvas} canvasProps={{ style: { cursor: "text", display: "block" }, className: "signature-canvas" }} />
            </div>

            <div className="form-row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button
                type="button"
                className="uk-button uk-button-primary small"
                onClick={() => {
                  if (sigCanvas?.current) {
                    // Store the current drawing in `previousDrawing` before clearing it
                    previousDrawing.current = sigCanvas?.current?.toDataURL();
                  }
                  setState(prevState => ({ ...prevState, isEmptyCanvas: true }));
                  sigCanvas?.current?.clear();
                  removeSignerData();
                }}
              >
                Clear
              </button>
            </div>
          </div>
          <div hidden={drawType} className="signature-text-container">
            <div style={{ width: "100%", overflow: "scroll" }}>
              <input
                className="signature-text-input"
                placeholder={props.modalType}
                style={{
                  fontFamily: fontFamily,
                  width: "inherit"
                }}
                onChange={evt => setState(prevState => ({ ...prevState, signatureText: evt.target.value }))}
                value={signatureText}
              />
            </div>

            <div className="signature-text-hint">
              {signatureText === "" ? (
                <>Type Your {props.modalType} Above</>
              ) : (
                <button
                  type="button"
                  style={{
                    border: 'none',
                    color: '#1e87f0'
                  }}
                  onClick={() => {
                    setState(prevState => ({ ...prevState, signatureText: "" }))
                    removeSignerData()
                  }}
                >
                  Clear {props.modalType}
                </button>
              )}
            </div>

            <div className="signature-template-container">
              {families.map((family, idx) => {
                return (
                  <div className={`signature-template-box${idx + 1}`} key={idx}>
                    <input
                      type="radio"
                      name="signature-type"
                      className="signature-template-radio"
                      checked={family === fontFamily}
                      value={family}
                      onChange={(evt) => setState(prevState => ({ ...prevState, fontFamily: evt.target.value }))}
                    />
                    <label
                      className="signature-template-label"
                      style={{ fontFamily: family }}
                    >
                      {signatureText?.trim()?.length == 0 ? props.modalType : signatureText}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="uk-modal-footer">
          <div className="row">
            <div className={"col-9"} >
              <small className="form-row file-format-size"> <b> By completing this document, I agree that the signature and initials will be the electronic representation of my signature and initials - just the same as a pen-and-paper signature or initial. </b></small>
            </div>
            <div className={"col-3"}>
              <div className="half-column uk-padding-small">
                <button
                  className="uk-button uk-button-default uk-modal-close small"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  disabled={(!drawType && signatureText?.trim()?.length == 0) || (drawType && isEmptyCanvas)} // disable button if signature text not entered
                  className="uk-button uk-button-primary uk-modal-close small "
                  type="button"
                  style={{ cursor: (!drawType && signatureText?.trim()?.length == 0) || (drawType && isEmptyCanvas) ? "auto" : "pointer" }}
                  onClick={handleDone}
                >
                  Done
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </SignaturePortal>
  );
}

export default PDFSignature;