import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Column from "../../../../common/Column";
import TransactionService from '../../../../../services/TransactionService';
import { getFigure, getPrice } from '../../../../../utils/currency';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getTransactionEsignById, upsertTransaction } from "../../../../../redux/actions/transaction";
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Strikethrough, Heading, Subscript, Superscript, List, TodoList, Underline, ListProperties, Markdown } from 'ckeditor5';
import { toast } from "react-toastify";
import * as yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';

const PanelFormAcceptanceOfVessel = ({ closePanel, acceptanceId }) => {

    const schema = yup.object().shape({});

    const { register, watch, handleSubmit, setValue, getValues, formState: { isDirty, errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const [status, setStatus] = useState(null);
    const [boolIsYBAA, setIsYBAA] = useState(true);

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const doc_id = acceptanceId;
    const [disableList, setDisableList] = useState({
        days_to_complete_conditions: true,
        adjustment_amount: true,
        condition: true,
        condition_1: true,
        condition_2: true,
        reason_for_rejection: true,
    });

    const titles = [
        "Acceptance of Vessel",
        "Conditional Acceptance of Vessel",
        "Conditional Acceptance with Survey Allowance of Vessel",
        "Rejection of Vessel",
    ];

    const keys = [
        "acceptace_date",
        "days_to_complete_conditions",
        "adjustment_amount",
        "condition",
        "condition_1",
        "condition_2",
        "reason_for_rejection",
        "additional_terms",
    ];

    useEffect(() => {
        if (doc_id) {
            fetchAcceptanceData();
        }
    }, [doc_id]);

    useEffect(() => {
        // formatter();
        const status = parseInt(getValues("status"));
        setStatus(status);
        switch (status) {
            case 0:
                setDisableList({
                    days_to_complete_conditions: true,
                    adjustment_amount: true,
                    condition: true,
                    condition_1: true,
                    condition_2: true,
                    reason_for_rejection: true,
                    additional_terms: true,
                });
                break;
            case 1:
                setDisableList({
                    days_to_complete_conditions: false,
                    adjustment_amount: true,
                    condition: true,
                    condition_1: false,
                    condition_2: false,
                    reason_for_rejection: true,
                    additional_terms: true,
                });
                break;
            case 2:
                setDisableList({
                    days_to_complete_conditions: false,
                    adjustment_amount: false,
                    condition: false,
                    condition_1: false,
                    condition_2: false,
                    reason_for_rejection: true,
                    additional_terms: true,
                });
                break;
            case 3:
                setDisableList({
                    days_to_complete_conditions: true,
                    adjustment_amount: true,
                    condition: true,
                    condition_1: true,
                    condition_2: true,
                    reason_for_rejection: false,
                    additional_terms: false,
                });
            default:
                break;
        }
    }, [watch("status")]);

    const fetchAcceptanceData = async () => {
        try {
            dispatch(setLoadingStatus(true));
            const { data } = await TransactionService.getAcceptanceDoc(doc_id);
            const dataAcceptance = data?.data || {};

            Object.keys(dataAcceptance).forEach((key) => {
                if (key === "adjustment_amount") {
                } else {
                    setValue(key, dataAcceptance[key]);
                }
            });
            setValue("adjustment_amount", getPrice(dataAcceptance["adjustment_amount"] ? dataAcceptance["adjustment_amount"] : dataTransaction?.t_buyer_closing?.survey_allowance ?? dataTransaction?.t_seller_closing?.survey_allowance));
            setValue('status', dataAcceptance['status']?.toString());
            dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(false));
            toast.error("Error occurs, While fetching data.", {
                autoClose: 2000
            })
        }
    }

    const handleBlur = () => {
        const price = getPrice(getValues("adjustment_amount"));
        setValue("adjustment_amount", price);
    };

    const handleFocus = () => {
        const price = getFigure(getValues("adjustment_amount"));
        setValue("adjustment_amount", price);
    };

    const handleRegistration = (acceptance) => {
        if (acceptance.status == 0) {
            acceptance.acceptace_date = new Date();
        }
        acceptance.adjustment_amount = getFigure(acceptance.adjustment_amount);
        Object.keys(acceptance).forEach((key) => {
            if (disableList[key]) {
                acceptance[key] = null;
            }
            if (!acceptance[key]) {
                acceptance[key] = null;
            }
        });

        if (doc_id) {
            acceptance['id'] = doc_id;
        }
        let survey_allownce_data = acceptance?.status == 2 ? {
            buyer_closing_id: dataTransaction?.t_buyer_closing?.id,
            seller_closing_id: dataTransaction?.t_seller_closing?.id,
            acceptance_ids: dataTransaction?.t_acceptance?.filter((e) => e?.status === 2 && e?.id !== doc_id)?.map((e) => e?.id) ?? []
        } : {}
        dispatch(setLoadingStatus(true));
        dispatch(upsertTransaction({
            step: {
                db: "acceptance",
                main: { ...acceptance, ...survey_allownce_data },
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Acceptance's")
        ).then((data) => {
            dispatch(getTransactionEsignById(data.id));
            dispatch(setLoadingStatus(false));
            closePanel(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <Column size="three">
                        <div className="radio-toolbar">
                            <input id='accept' type="radio" value={0} {...register("status")} />
                            <label htmlFor='accept' className="uk-form-label radio">Accept</label>
                            <input id='conditional' type="radio" value={1} {...register("status")} />
                            <label htmlFor='conditional' className="uk-form-label radio">Conditional Accept</label>
                            <input id='survey' type="radio" value={2} {...register("status")} />
                            <label htmlFor='survey' className="uk-form-label radio">Conditional Acceptance with Survey Allowance</label>
                            <input id='reject' type="radio" value={3} {...register("status")} />
                            <label htmlFor='reject' className="uk-form-label radio">Reject</label>
                        </div>
                    </Column>
                </FormRow>
                {(!disableList.days_to_complete_conditions && status !== 2) && (
                    <FormRow>
                        <FormInputWrapper label="Days to complete conditions">
                            <input className="uk-input small" type="text" {...register("days_to_complete_conditions")} />
                        </FormInputWrapper>
                    </FormRow>
                )}
                {!disableList.adjustment_amount && (
                    <FormRow>
                        <FormInputWrapper label={status === 2 ? "Adjustment Amount $ *" : "Adjustment Amount $"}>
                            <input className="uk-input small" type="text"
                                {...register("adjustment_amount")}
                                onBlur={handleBlur}
                                onFocus={handleFocus} />
                        </FormInputWrapper>
                    </FormRow>
                )}
                {!disableList.condition &&
                    <FormInputWrapper size="two" label="Conditions *">

                        <CKEditor
                            className=''
                            name='condition'
                            data={getValues("condition")}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                // setNote(data);
                                // setEditData(data)
                                setValue('condition', data);
                            }}
                            config={{
                                toolbar: {
                                    items: [
                                        // 'heading',
                                        '|', 'undo', 'redo',
                                        // '|', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript',
                                        '|', 'bulletedList', 'numberedList'
                                    ],
                                },
                                plugins: [
                                    Markdown, Undo, Bold, Essentials, Italic, Underline, Mention, Paragraph, Heading, Strikethrough, Subscript, Superscript, List
                                ],
                                placeholder: 'Add Provision'
                            }}
                        />
                    </FormInputWrapper>
                }
                {!disableList.condition_1 && disableList.condition && (
                    <FormRow>
                        <FormInputWrapper size="two" label="Condition 1">
                            <textarea className="uk-textarea" {...register("condition_1")} rows="4" cols="50" ></textarea>
                        </FormInputWrapper>
                    </FormRow>
                )}
                {!disableList.condition_2 && disableList.condition && (
                    <FormRow>
                        <FormInputWrapper size="two" label="Condition 2">
                            <textarea className="uk-textarea" {...register("condition_2")} rows="4" cols="50" ></textarea>
                        </FormInputWrapper>
                    </FormRow>
                )}
                {!disableList.reason_for_rejection && (
                    <>
                        <FormRow>
                            <FormInputWrapper size="two" label="Reason for Rejection *">
                                <textarea className="uk-textarea" {...register("reason_for_rejection")} rows="4" cols="50" ></textarea>
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper size="two" label="Additional Terms">
                                <textarea className="uk-textarea" {...register("additional_terms")} rows="4" cols="50" />
                            </FormInputWrapper>
                        </FormRow>
                    </>
                )}
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormAcceptanceOfVessel;