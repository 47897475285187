import React, { useEffect, useState, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import moment from "moment";

import { retrieveRoles, retrieveOffices, retrieveMlsOffices, retrieveBrokers, updateUser, getUser, findOfficeWithBrokers, inviteUser } from "../../redux/actions/users";
import { setLoadingStatus } from "../../redux/actions/loading";
import Auth from "../../utils/auth";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { resetStore } from '../../redux/actions/auth';
import UserService from "../../services/UserService";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import StyleSwitch from "../common/buttons/StyleSwitch";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { dateFormatter } from "../../utils/common";
import { retrieveCompanies } from "../../redux/actions/companies";
// import { phoneFigure, phoneNumber } from "../../utils/phonenumber";

const useStyles = makeStyles({
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  is_broker_div: {
    width: "38% !important",
  },
  show_broker_label: {
    marginRight: "9px !important",
  },
  employing_broker_input: {
    marginRight: "6px !important",
  }
});

const UserForm = (props) => {

  const { id } = useParams();

  const { user_id } = props.location.state;

  const company_id = props.location.state.userCompanyId;

  const history = useHistory();
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState('');

  //This state is used to store a mls office users
  const [mlsUsersList, setMlsUsersList] = useState([]);

  //This state is used to show error messsage of email Exists.
  const [isEmailExists, setIsEmailExists] = useState(false);
  const classes = useStyles();

  //This state is used for check whether user is agent oe not.
  const [isAgent, setIsAgent] = useState(false);

  //This state is used for check whether user is participant or not.
  const [isParticipant, setIsParticipant] = useState(false);

  // State used for store the Guest Agent's company and manage company selection
  const [guestAgentCompany, setGuestAgentCompany] = useState(null);


  // This state is used to show checked when all brokers is selected
  const [allOfficeBrokerSelected, setAllOfficeBrokerSelected] = useState([]);

  //This state is used to store a error message for broker attachment list.
  const [brokerAttachmentError, setBrokerAttachmentError] = useState(null);

  //This variable is used to store a users roles.
  const dataRoles = useSelector(state => state.users.roles);

  //This variable is used to store offices
  const dataOffices = useSelector(state => state.users.offices);
  dataOffices.sort((a, b) => a.office_name < b.office_name ? -1 : 1);

  //This variavle is used to store offices with it's broker.
  const officeDataWithBrokers = useSelector(state => state.users.officeListWithBroker);

  //This variable is used to store mls office of user.
  const mlsObject = useSelector(state => state.users.mlsOffices);

  //This variable is used to store all mls offices
  const mlsOffices = mlsObject?.data?.Offices ? mlsObject.data.Offices : []

  mlsOffices.sort((a, b) => a.OfficeName < b.OfficeName ? -1 : 1)

  //This state is used to store data of logged user
  const userData = useSelector(state => state.users.logged_by);
  const dispatch = useDispatch();

  //To-Do: Replace company Id while beta
  const user = Auth.getInstance().getUserData();
  const companyId = user.company_id;

  // const users = useSelector((state) => state.users.users);
  const dataCompanies = useSelector((state) => state.companies.companies);
  const sortedDataCompanies = dataCompanies.sort((a, b) => (b.company_name > a.company_name ? -1 : 1));

  useEffect(() => {
    dispatch(findOfficeWithBrokers(company_id, user_id));
    dispatch(retrieveOffices(company_id));
    // If Update user is a Guest Agent then display company and all companies list in selection
    if (userData.UserInRole?.UserRole?.name == 'Guest Agent') {
      dispatch(retrieveCompanies()).then((response) => {
        setGuestAgentCompany(company_id);
        dispatch(retrieveOffices(company_id));
      });
    }
  }, []);

  //Make a schema for edit form
  const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required."),
    last_name: yup.string().required("Last Name is required."),
    email: yup.string().required().email("Email is required"),
    mobile_phone: yup
      .string()
      .required('Phone number is required')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid'
      ),
    // expiration_date: yup.string().required(),
    user_role_id: yup.string().required(),
    mls_user_id: yup.string().nullable(true),
    mls_office_id: yup.string().nullable(true),
    title: yup.string().nullable(true),
    is_broker: yup.boolean().nullable(true),
    company_office_id: !isParticipant && yup.string().required("Please select an office"),
    // access_type: yup.string().required('Please select an access type'),
    access_type: !isParticipant && yup.string().when('user_role_id', {
      is: (user_role_id) => user_role_id === dataRoles.find(role => role.name === 'Company Admin').id,
      then: yup.string().nullable(true),
      otherwise: yup.string().required('Please select an access type')
    }),
    broker_access: !isParticipant && yup.string().when('user_role_id', {
      is: (user_role_id) => user_role_id === dataRoles.find(role => role.name === 'Agent').id,
      then: yup.string().required('You must choose a access of brokers for agent.').typeError('You must choose a access of brokers for agent.'),
      otherwise: yup.string().nullable()
    }),
  });


  /* Form Validation */
  const { register, handleSubmit, reset, setValue, getValues, formState: { errors }, watch, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      is_broker: false,
      is_deals_office_employing_broker_signs: false,
      is_listing_office_employing_broker_signs: false,
      is_hide_drag_drop_copy: false,
      is_hide_image_text_bar: false,
      is_hide_add_signature: false,
      broker_attachment: [],
      offices_without_brokers: [],
      mls_user_id: "",
      broker_access: "",
      active: true
    },
    shouldFocusError: true
  });


  // UseEffect for set the previous value of fields.
  useEffect(() => {
    //Fetch all roles from api
    dispatch(retrieveRoles());
    dispatch(setLoadingStatus(true))
    //Fetch user data as per loggedIn User id.
    dispatch(getUser(user_id)).then(data => {
      dispatch(setLoadingStatus(false))
      if (data?.message && data?.message.includes("401")) {
        toast.error("You are not authorized to access this action.");
        history.push('/users');
      }

      if (data && data.message && data?.message?.includes("401")) {
        toast.error("You are not authorized to access this action.");
        history.push('/users');
      }
      dispatch(retrieveMlsOffices(company_id)).then(() => {
        if (data.mls_office_id) {
          setMlsUserId(data.mls_office_id, data)
        }
      });

      setValue('user_role_id', data?.UserInRole?.UserRole?.id);
      setValue("company_office_id", data?.company_office_id || '')
      //Make a fields array to store one by one fields value.
      const fields = [
        // "broker_attachment",
        'first_name',
        'last_name',
        'email',
        'mobile_phone',
        'expiration_date',
        'license_code',
        'mls_office_id',
        'mls_user_id',
        'title',
        'is_broker',
        'access_type',
        'access_all_brokers',
        // 'user_role_id',
        // 'broker_attachment',
        'active',
        "broker_access",
        "is_deals_office_employing_broker_signs",
        "is_listing_office_employing_broker_signs",
        "is_hide_drag_drop_copy",
        "is_hide_image_text_bar",
        "is_hide_add_signature",
        // 'company_office_id',
        "participant_company_name",
      ];
      //Store the fields value.
      fields.forEach(field => {
        if (field === "expiration_date") {
          setValue(field, data.expiration_date ? moment(data?.expiration_date).format("yyyy-MM-DD") : "");
          return;
        }
        if (field == 'access_type') {
          setValue(field, data.access_type || "");
          return;
        }
        setValue(field, data[field])
      });

      //If related brokers available then then set the value of brokers
      if (data.related_brokers && data.related_brokers.length > 0) {
        let broker_list = [];
        data.related_brokers.forEach(element => {
          if (!element.broker_id) {
            setAllOfficeBrokerSelected((state) => {
              state.push(element.office_id)
              return state
            })
          }
          let obj = {}
          obj["office_id"] = element.office_id
          obj["broker_id"] = element.broker?.id ? element.broker.id : null
          broker_list.push(obj);
        });
        setValue("offices_without_brokers", allOfficeBrokerSelected);
        setValue('broker_attachment', broker_list);

        //Make office checked office when users is not available as a broker in particular office
        broker_list.map((broker) => makeCheckedOffice(broker.office_id))

      }

    }).catch(err => {
      dispatch(setLoadingStatus(false))
    })
  }, [mlsOffices.length]);

  //This function is used to checked office when user select all brokers from broker list.
  const makeCheckedOffice = (officeId) => {

    //Get values of broker_attachment field.
    const broker_attachment = getValues("broker_attachment");

    //Make a selected broker list
    const broker_list = broker_attachment.map((broker) => {
      if (broker.broker_id) {
        return broker.broker_id
      }
    })

    // Get office object from api response of offices.
    const officeObj = officeDataWithBrokers.find((office) => office.id == officeId);

    let office_list = allOfficeBrokerSelected

    if (officeObj && officeObj.company_user && officeObj.company_user.length > 0) {
      let isBroken = false;

      /**
       * Push in officelist array when user selects all brokers from same office
       * And this officeList will set to the allOfficeBrokerSelected state
       * allOfficeBrokerSelected state is used to show checked when all brokers is selected
       */
      officeObj.company_user.map((user, index, array) => {
        if (isBroken) {
          return
        }
        if (!broker_list?.includes(user.id)) {
          isBroken = true
          return
        }
        if (index == (officeObj.company_user.length - 1)) {
          office_list.push(officeObj.id)
        }
      })
    }
    setAllOfficeBrokerSelected(office_list)
  }


  // Use effect for user's role.
  useEffect(() => {
    if (dataRoles !== undefined && dataRoles.length > 0) {
      //If agent role is selected then fetch all offices and brokers using company id.
      if (watch('user_role_id') === dataRoles.find(role => role.name === 'Agent').id) {
        //Make isAgent state true for displaying all brokers.
        setIsAgent(true);
        setIsParticipant(() => false);
        //Fetch all office data with brokers
        dispatch(findOfficeWithBrokers(company_id, user_id));
      } else if (watch('user_role_id') === dataRoles.find(role => role.name === 'Participant')?.id) {

        setIsAgent(false);
        setIsParticipant(() => true);
        dispatch(findOfficeWithBrokers(companyId));
      } else {

        // If new user is not agent then make isAgent state false
        setIsAgent(false);
        setIsParticipant(() => false);
      }
    }
  }, [watch('user_role_id')]);

  /* Handle Function for From Inputs */
  const handleUpdate = (user) => {

    //Remove all error related state.
    // setError(false);
    // setMessage('');
    setBrokerAttachmentError(null);

    //Set validation message for broker_attachment list.
    if (isAgent && user.broker_access && user.broker_access == "individual" && user.broker_attachment.length === 0 && user.offices_without_brokers.length === 0) {
      setBrokerAttachmentError("Please select at least one broker or office");
      return;
    }

    //Set validation message for broker_attachment list.
    if (userData.UserInRole?.UserRole?.name == 'Guest Agent' && getValues('user_role_id') == dataRoles.find(role => role.name === 'Guest Agent').id) {
      toast.error("Please select a role");
      return;
    }

    if (!user.is_broker) {
      user.is_deals_office_employing_broker_signs = false;
      user.is_listing_office_employing_broker_signs = false;
    }

    // Do check if the Deal/LA office EB checkbox have changed and set flag for both Deal and LA
    user['is_deal_eb_sign_changed'] = userData?.is_deals_office_employing_broker_signs != user.is_deals_office_employing_broker_signs;
    user['is_listing_agreement_eb_sign_changed'] = userData?.is_listing_office_employing_broker_signs != user.is_listing_office_employing_broker_signs;

    if (isAgent && user.broker_access && user.broker_access == "individual" && user.broker_attachment.length === 1) {
      if (user.broker_attachment[0].broker_id == user_id) {
        setBrokerAttachmentError("Please select at least one broker without your self");
        return;
      }
    }

    // Set empty broker_attachment, offices_without_brokers list for broker_access is all
    if (isAgent && user.broker_access && ["all", "self_broker"].includes(user.broker_access)) {
      user = {
        ...user,
        broker_attachment: [],
        offices_without_brokers: []
      }
    }

    //Delete unnecessary fields from user object
    if (user.offices_without_brokers) {
      delete user.offices_without_brokers
    }

    // If new user role is not agent then delete broker_attachment and broker_access fields.
    if (user.user_role_id != dataRoles.find(role => role.name === 'Agent').id) {
      delete user.broker_attachment;
      delete user.broker_access;
    }

    if (userData.UserInRole?.UserRole?.name == 'Guest Agent') {
      user['company_id'] = guestAgentCompany;
    }

    //Set loading true for showing loader.
    dispatch(setLoadingStatus(true));

    /**
     * Check if email changed by company admin or not.
     * If email is changed then we have to call api for check this email is already available or not.
     * If email is already available then show the error message `Email is already exists`
     * If email is not exists then call api to update email and then call api for update user.
     * If company admin does not change the email then we will call api for only update user.
     */
    if (user?.email != userData.email) {
      let updatedUser = {
        ...user,
        email: user?.email.toLowerCase(),
      };

      if (isParticipant) {
        user = {
          first_name: user.first_name,
          last_name: user.last_name,
          mobile_phone: user.mobile_phone,
          title: user.title, participant_company_name:
            user.participant_company_name,
          email: user.email,
          user_role_id: user.user_role_id,
          user_type: "participant",
          company_id: companyId,
          user_id: user_id,
        }
      }

      //Check email from database
      UserService.checkEmail({
        email: updatedUser.email, user_type: "participant",
        company_id: companyId
      }).then((res) => {

        //Check whether email is exists or not.
        if (!res.data.isExists) {

          //Call api to update the email.
          UserService.updateEmail(user_id, updatedUser).then((res) => {
            toast.info("Please check your email for validation code to sign back in with your new email address.", {
              autoClose: 4000,
            });

            //After email updated successfully then user will automatically logged out
            setTimeout(() => {
              if (user_id == user.id) {
                Auth.getInstance().logout();
                dispatch(resetStore());
                window.location.reload();
              }
            }, 4000);

            // Set broker attachment fields
            if (typeof user.broker_attachment === 'string') {
              user = {
                ...user,
                broker_attachment: [user.broker_attachment]
              };
            } else if (typeof user.broker_attachment === 'boolean') {
              user = {
                ...user,
                broker_attachment: []
              };
            }

            dispatch(setLoadingStatus(true));

            //Call api to update user data.
            dispatch(updateUser(user_id, user)).then(response => {

              //Hide loader.
              dispatch(setLoadingStatus(false));
              setSuccess(true);

              //Show message for update successfully
              // setMessage('Updated Successfully');
              toast.success('User updated successfully.');
              setTimeout(() => {
                history.push('/users');
              }, 2000);
            }).catch(err => {

              //If error occurs in api calling then show the error message.
              dispatch(setLoadingStatus(false));
              if (err.data.error.message) {
                toast.error(err.data.error.message);
                // setMessage(err.data.error.message);
              } else {
                toast.error('Something went wrong while updating the user!')
                // setMessage(`Sorry, but we can't proceed your request right now.`);
              }
              // setError(true);
            });
          }).catch((error) => {
            setIsEmailExists(true);
            dispatch(setLoadingStatus(false));
          })

        } else {

          //Set emailExists flag true.
          setIsEmailExists(true);
          dispatch(setLoadingStatus(false));
        }
      }).catch((err) => { })
    } else {

      //This else block will executed when company admin does not update the value of user.

      //Set broker_attachment fields
      if (typeof user.broker_attachment === 'string') {
        user = {
          ...user,
          broker_attachment: [user.broker_attachment]
        };
      } else if (typeof user.broker_attachment === 'boolean') {
        user = {
          ...user,
          broker_attachment: []
        };
      }

      //Show the loader.
      dispatch(setLoadingStatus(true));

      //Calling api to update users data.
      dispatch(updateUser(user_id, user)).then(response => {

        //Hide loader and show the success message.
        dispatch(setLoadingStatus(false));
        setSuccess(true);
        toast.success('User updated successfully.');
        // setMessage('Updated Successfully');
        setTimeout(() => {
          history.push('/users');
        }, 2000);
      }).catch(err => {
        //If error occurs then hide the loader and show the error message.
        dispatch(setLoadingStatus(false));
        if (err.response?.data?.error) {
          toast.error(err.response?.data?.error);
          // setMessage(err.data.error.message);
        } else {
          toast.error('Something went wrong while updating the user!')
          // setMessage(`Sorry, but we can't proceed your request right now.`);
        }
        // setError(true);
      });
    }
  }

  // Function for handle error message.
  const handleError = (errors) => {
    if (Object.keys(errors)[0]) {
      // setError(true);
      toast.error(errors[Object.keys(errors)[0]].message);
      // setMessage(errors[Object.keys(errors)[0]].message);
      return;
    }
    // setError(false);
    // setMessage(null);
  };

  //Function for set mlsUserId Value.
  const setMlsUserId = async (office_id, data = null) => {
    if (office_id) {
      const mlsOffice = mlsOffices.find((office) => office.OfficeId == office_id);
      if (mlsOffice) {
        const sortedUser = await (mlsOffice.Users && mlsOffice.Users.length) > 0 ? mlsOffice.Users.sort((a, b) => a.BrokerName < b.BrokerName ? -1 : 1) : []
        mlsOffice && setMlsUsersList(sortedUser);
        if (data) {
          setValue("mls_user_id", data.mls_user_id ? data.mls_user_id : "");
        }
      }
    } else {
      setMlsUsersList([]);
    }
  }

  //Store `active` field value into the variable.
  const active = useMemo(() => getValues('active'), [watch('active')]);

  //Store the value of broker_access field in variable
  const accessAllBrokers = useMemo(() => getValues('broker_access'), [watch('broker_access')]);

  //Store the value of broker_attachment field in variable
  const selectedBrokerAttachments = useMemo(() => getValues('broker_attachment'), [watch('broker_attachment')]);

  //Store the value of role of user and give full access to the company admin
  const isCompanyAdmin = useMemo(() => {
    const selectedRole = getValues('user_role_id');
    if (dataRoles && dataRoles.length > 0 && selectedRole == dataRoles.find(role => role.name === 'Company Admin').id) {
      setValue("access_type", "full")
      return true;
    }
    return false;
  }, [watch('user_role_id')], [dataRoles]);

  //Store the list of brokers in variable.
  const brokerArr = selectedBrokerAttachments?.map((broker) => {
    return broker.broker_id
  })

  const checkBroker = (user) => {
    if (selectedBrokerAttachments) {
      const brokerArr = selectedBrokerAttachments?.map((broker) => {
        return broker.broker_id
      })

      return brokerArr.includes(user.id)
    }
  }

  // Function for remove items from array
  const removeArrayElemnt = (array, item) => {
    var index = array.indexOf(item);
    array.splice(index, 1);
  }

  /**
   * Function is used to show checked office as er it's click event
   * If office is checked then we will automatically checked all it's brokers
   * If office is unckhecked then we will automatically unchecked all it's brokers
   * @param {object} event 
   */
  const officeChangeHandler = (event) => {

    //Store the office id and office variable in one variables.
    const officeId = event.target.value
    let officeBrokerArr = officeDataWithBrokers.find((officeObj) => officeObj.id === officeId);

    //If office is checked then find all it's brokers.
    if (event.target.checked) {

      officeBrokerArr.company_user.map((user) => {
        let currentBroker = getValues("broker_attachment")
        const brokersArrObj = currentBroker.filter((broker) => broker.broker_id != user.id)
        setValue("broker_attachment", brokersArrObj)
      })

      //Make an array to checked all it's brokers
      const userArr = officeBrokerArr.company_user.map((user) => {
        return {
          "broker_id": user.id,
          "office_id": officeId
        }
      })

      //Push the value of office id to show checked
      setAllOfficeBrokerSelected((state) => {
        return [...state, officeId]
      })

      //If no user found from office then
      // we have to push in `userArr` array which is used to value of broker attachment fields.
      //And store the value of officeid in `offices_without_brokers` variable.
      if (userArr.length == 0) {
        userArr.push({
          "broker_id": null,
          "office_id": officeId
        })

        const officeArrWithoutBroker = getValues("offices_without_brokers")
        const filteredArr = officeArrWithoutBroker.filter((office_id) => office_id != officeId)
        setValue("offices_without_brokers", [...filteredArr, officeId])
      }

      //Set the value of broker_attachment fields.
      userArr.map((user) => {
        const currentBroker = getValues("broker_attachment")
        setValue("broker_attachment", [...currentBroker, user])
      })

    } else {

      //Remove the officeId from `allOfficeBrokerSelected` variable.
      setAllOfficeBrokerSelected((state) => {
        var index = state.indexOf(officeId);
        state.splice(index, 1)
        return state
      })

      const officeArrWithoutBroker = getValues("offices_without_brokers")
      const filteredArr = officeArrWithoutBroker.filter((office_id) => office_id != officeId)
      setValue("offices_without_brokers", filteredArr)

      let currentBroker = getValues("broker_attachment")
      const brokersArrObj = currentBroker.filter((broker) => broker.office_id != officeId)
      setValue("broker_attachment", brokersArrObj)
    }
  }

  //Get the value of is_broker
  const is_broker_checked = useMemo(() => getValues("is_broker"), [watch('is_broker')])

  //Get the value of is_employing_broker
  const is_deals_office_employing_broker_signs = useMemo(() => getValues("is_deals_office_employing_broker_signs"), [watch('is_deals_office_employing_broker_signs')]);
  const is_listing_office_employing_broker_signs = useMemo(() => getValues("is_listing_office_employing_broker_signs"), [watch('is_listing_office_employing_broker_signs')]);

  //Get the value of editor optoins hide
  const is_hide_drag_drop_copy = useMemo(() => getValues("is_hide_drag_drop_copy"), [watch('is_hide_drag_drop_copy')]);
  const is_hide_image_text_bar = useMemo(() => getValues("is_hide_image_text_bar"), [watch('is_hide_image_text_bar')]);
  const is_hide_add_signature = useMemo(() => getValues("is_hide_add_signature"), [watch('is_hide_add_signature')]);

  /**
   * This function is used for set value of broker and officeID in broker_attachment variable. 
   * @param {Object} event 
   */
  const brokerChangeHandler = (event) => {

    //Set the value of brokerId and officeId
    const brokerId = event.target.value
    const officeId = event.target.getAttribute("data-office")

    //If broker is checked then push the value of broker and officeId in broker_attachment field
    if (event.target.checked) {
      let currentBroker = getValues("broker_attachment")
      let obj = {
        "broker_id": brokerId,
        "office_id": officeId
      }
      setValue("broker_attachment", [...currentBroker, obj]);
      makeCheckedOffice(officeId)
    } else {

      //If broker is unchecked then remove the officeId from `allOfficeBrokerSelected`array
      if (allOfficeBrokerSelected?.includes(officeId)) {
        setAllOfficeBrokerSelected((state) => {
          var index = state.indexOf(officeId);
          state.splice(index, 1)
          return state
        })
      }

      //Set the new value of broker_attachment field
      let currentBroker = getValues("broker_attachment")
      const brokersArrObj = currentBroker.filter((broker) => {
        if (broker.broker_id) {
          return broker.broker_id != brokerId
        }
      })
      setValue("broker_attachment", brokersArrObj);
    }

  }

  const handleShowAsBrokerToggle = () => {
    setValue('is_broker', !getValues('is_broker'));
    if (getValues('is_broker') == false) {
      setValue('is_deals_office_employing_broker_signs', false);
      setValue('is_listing_office_employing_broker_signs', false);
    }
  }

  /**
   * On Company selection change for the Guest Agent user
   * @param {*} company Selected Company ID
   */
  const onGuestAgentCompanyChange = (company) => {
    setGuestAgentCompany(company);
    setValue("company_office_id", "");
    dispatch(retrieveOffices(company));
  }

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li><Link to={"/users"}>Users</Link></li>
        <li className="uk-disabled">Edit a User</li>
      </ul>

      <h1>Edit User</h1>

      {/* {isSuccess ? (
        <div className="uk-alert uk-alert-primary" data-uk-alert>
          <p>{message}</p>
        </div>
      ) : isError ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{message}</p>
        </div>
      ) : null} */}

      {brokerAttachmentError &&
        < div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{brokerAttachmentError}</p>
        </div>
      }

      <div className="form-container">
        <form onSubmit={handleSubmit(handleUpdate, handleError)} className="uk-grid-small form" data-uk-grid id="addform" method="POST" onReset={reset}>
          {user.id !== user_id && (
            <div className={`form-row uk-grid-margin uk-first-column ${classes.role_div} mb-3`}>
              <h4>Role</h4>
              <div className="three-column">
                {
                  dataRoles.map((role, key) =>
                    role.name !== "Guest Agent" && (
                      <Fragment key={key}>
                        <input {...register("user_role_id", { required: true })} id={role.id} type="radio" value={role.id} />
                        <label className="uk-form-label radio" htmlFor={role.id}>{role.name}</label>&nbsp;&nbsp;&nbsp;
                      </Fragment>
                    )
                  )
                }
              </div>
            </div>
          )}

          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">First Name*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.first_name })} type="text" {...register("first_name")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Last Name*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.last_name?.message })} type="text" {...register("last_name")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Title</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.title })} type="text" {...register("title")} />
            </div>
          </div>
          <div className="form-row mb-0 pb-0">
            <div className="one-column pb-0 uk-padding-small">
              <label className="uk-form-label">Email*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.email?.message })} type="email" {...register("email")}
                onChange={() => setIsEmailExists(false)}
                readOnly={user?.UserInRole?.UserRole?.name === "Company Admin" ? false : true} />
              {
                isEmailExists && <p style={{ color: "red" }}>Email already exists.</p>
              }
            </div>
            <div className="one-column pb-0 uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.mobile_phone?.message })} type="hidden" required name="mobile_phone" {...register("mobile_phone")} />
              <PhoneInput
                placeholder='+'
                inputClass={classes.phone_input}
                containerClass={classes.phone_container}
                value={userData.mobile_phone}
                onChange={phone => { setValue('mobile_phone', phone, { shouldValidate: true }) }}
              />
              <p style={{ color: "red" }}>{errors.mobile_phone?.message}</p>
            </div>
            {isParticipant
              ? <div className="one-column pb-0">
                <label className="uk-form-label pl-0">Company Name</label>
                <input
                  className={clsx("uk-input uk-padding-small", {
                    "uk-form-danger": errors.participant_company_name,
                  })}
                  type="text"
                  {...register("participant_company_name")}
                />
              </div>
              : <></>
            }
          </div>
          <div className="form-row d-flex flex-column mb-3 mt-0">
            <label className="mb-0 pb-0 custom-switch-bnt" style={{ marginLeft: '-8px' }}>
              <StyleSwitch
                checked={is_broker_checked}
                onChange={(e) => handleShowAsBrokerToggle()}
              />Show as Broker
            </label>
            <div className="d-flex">
              {is_broker_checked &&
                <Fragment>
                  <div className="d-flex flex-column">
                    <div className='mt-1 pl-4'>
                      <input id="is_deals_office_employing_broker_signs" type="checkbox" name="is_deals_office_employing_broker_signs" checked={is_deals_office_employing_broker_signs} {...register("is_deals_office_employing_broker_signs")} />
                      <label className={`uk-form-label radio m-0 ${classes.show_broker_label}`} for="is_deals_office_employing_broker_signs">Office Employing Broker Signs Deal</label>
                    </div>
                    <div className='mt-1 pl-4'>
                      <input id="is_listing_office_employing_broker_signs" type="checkbox" name="is_listing_office_employing_broker_signs" checked={is_listing_office_employing_broker_signs} {...register("is_listing_office_employing_broker_signs")} />
                      <label className={`uk-form-label radio m-0 ${classes.show_broker_label}`} for="is_listing_office_employing_broker_signs">Office Employing Broker Signs Listing Agreement</label>
                    </div>
                  </div>
                </Fragment>
              }
              <Fragment>
                <div className={`d-flex flex-column pl-1 ${is_broker_checked && "ml-5"}`}>
                  <div className={`mt-1 ${is_broker_checked && "pl-5"}`}>
                    <input id="is_hide_drag_drop_copy" type="checkbox" name="is_hide_drag_drop_copy" checked={is_hide_drag_drop_copy} {...register("is_hide_drag_drop_copy")} />
                    <label className={`uk-form-label radio m-0`} for="is_hide_drag_drop_copy">Hide Drag&Drop/Copy</label>
                  </div>
                  <div className={`mt-1 ${is_broker_checked && "pl-5"}`}>
                    <input id="is_hide_image_text_bar" type="checkbox" name="is_hide_image_text_bar" checked={is_hide_image_text_bar} {...register("is_hide_image_text_bar")} />
                    <label className={`uk-form-label radio m-0`} for="is_hide_image_text_bar">Hide Image/Text Bar</label>
                  </div>
                </div>
                <div className={`mt-1 pl-5 ${is_broker_checked && "ml-5"}`}>
                  <input id="is_hide_add_signature" type="checkbox" name="is_hide_add_signature" checked={is_hide_add_signature} {...register("is_hide_add_signature")} />
                  <label className={`uk-form-label radio m-0`} for="is_hide_add_signature">Hide Add Signatures</label>
                </div>
              </Fragment>
            </div>
          </div>
          {!isParticipant && <>
            <div className="form-row">
              <div className="one-column uk-padding-small">
                <label className="uk-form-label">License #</label>
                <input
                  className={clsx("uk-input", { 'uk-form-danger': errors.license_code?.message })}
                  type="text"
                  {...register("license_code")}
                // value={brokerProfile.license_code ? brokerProfile.license_code : ""}
                />
              </div>
              <div className="one-column uk-padding-small">
                <label className="uk-form-label">License Expiration Date</label>
                <Controller
                  control={control}
                  name="expiration_date"
                  render={({ field }) => (
                    <DatePicker
                      className={clsx("uk-input", { 'uk-form-danger': errors.expiration_date?.message })}
                      style={{ cursor: "pointer", color: "#000" }}
                      format={'MM/DD/YYYY'}
                      value={getValues("expiration_date") && dayjs(getValues("expiration_date"))}
                      onChange={(date, dateString) => {
                        field.onChange(dateString ? dateFormatter(dateString) : null);
                      }}
                    />
                  )}
                />
              </div>
              {
                !isCompanyAdmin &&
                <div className="uk-padding-small" style={{ marginTop: "30px" }}>
                  <input type='radio' id="full_access_type" {...register("access_type")} value='full' />
                  <label className='uk-form-label radio' htmlFor="full_access_type">Full Access</label>
                  <input type='radio' {...register("access_type")} value='edit_only' id="edit_access_type" />
                  <label className='uk-form-label radio' htmlFor="edit_access_type">Edit Only</label>
                </div>
              }
            </div>

            {
              user.UserInRole.UserRole.name == "Company Admin" && mlsObject.credentialsFound &&
              <div className="form-row">
                {
                  mlsOffices && mlsOffices.length > 0 &&
                  <div className="one-column uk-padding-small">
                    {/* <h4>Office</h4> */}
                    <label className="uk-form-label" htmlFor="mls_office_id">
                      MLS Office
                    </label>
                    <select className="uk-select" {...register("mls_office_id")}
                      defaultValue=""
                      onChange={(event) => {
                        setValue("mls_office_id", event.target.value)
                        setMlsUserId(event.target.value)
                        // setAddressFields(event.target.value);
                      }}
                    >
                      <option value="">Select Office</option>
                      {mlsOffices.map((mlsOffice) => {
                        return (
                          <option value={mlsOffice.OfficeId} key={mlsOffice.OfficeId}>
                            {`${mlsOffice.OfficeName}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                }
                {
                  mlsUsersList &&
                  <div className="one-column uk-padding-small">
                    {/* <h4>User</h4> */}
                    <label className="uk-form-label" htmlFor="mls_user_id">
                      User MLS ID
                    </label>
                    <select className="uk-select" {...register("mls_user_id")}
                      defaultValue=""
                      onChange={(event) => {
                        setValue("mls_user_id", event.target.value)
                        // setAddressFields(event.target.value);
                      }}
                    >
                      <option value="">Select Broker</option>
                      {mlsUsersList.map((user) => {
                        return (
                          <option value={user.BrokerID} key={user.BrokerID}>
                            {`${user.BrokerName} | ${user.BrokerID}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                }
              </div>
            }

            <div className="form-row uk-grid-margin uk-first-column">
              {userData.UserInRole?.UserRole?.name == 'Guest Agent'
                ? <>
                  <div className="one-column uk-padding-small">
                    <label className="uk-form-label" htmlFor="mls_user_id">
                      Select Company for Guest Agent
                    </label>
                    <select className="uk-select"
                      value={guestAgentCompany}
                      onChange={(event) => {
                        onGuestAgentCompanyChange(event.target.value);
                      }}
                    >
                      {sortedDataCompanies.map((company) => {
                        return (
                          <option value={company.id} key={company.id}>
                            {`${company.company_name}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="one-column uk-padding-small">
                    <label className="uk-form-label" htmlFor="mls_user_id">
                      Select Office for Guest Agent
                    </label>
                    <select className="uk-select" {...register("company_office_id")}
                      defaultValue=""
                      onChange={(event) => {
                        setValue("company_office_id", event.target.value)
                      }}
                    >
                      <option selected value="">Select Office</option>
                      {dataOffices.map((office) => {
                        return (
                          <option value={office.id} key={office.id}>
                            {`${office.office_name}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
                : <>
                  <h4>Office</h4>
                  <div className="one-column">
                    <select className="uk-select" {...register("company_office_id")}
                      defaultValue=""
                      onChange={(event) => {
                        setValue("company_office_id", event.target.value)
                      }}
                    >
                      <option selected value="">Select Office</option>
                      {dataOffices.map((office) => {
                        return (
                          <option value={office.id} key={office.id}>
                            {`${office.office_name}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              }
            </div>

          </>}
          {user.id !== user_id && (
            <div className="form-row">
              <div className="one-column uk-padding-small">
                <label className="uk-form-label">&nbsp;</label>
                <input name="active" type="radio" checked={active} onChange={() => setValue('active', true)} />
                <label className="uk-form-label radio">Active</label>
                &nbsp;&nbsp;&nbsp;
                <input name="active" type="radio" checked={!active} onChange={() => setValue('active', false)} />
                <label className="uk-form-label radio">In-Active</label>
              </div>
            </div>
          )}


          {isAgent && (
            <div className={`form-row ${classes.access_broker_div}`}>
              <h4>Should this Agent have access to all Brokers</h4>
              <div className='three-column'>
                <Fragment>
                  <input {...register("broker_access")} type='radio' id="all_broker_access" value='all' />
                  <label className='uk-form-label radio' htmlFor="all_broker_access">All</label>
                </Fragment>
                <Fragment>
                  <input {...register("broker_access")} type='radio' value='individual' id="select_broker_access" />
                  <label className='uk-form-label radio' htmlFor="select_broker_access">Select Brokers Individually</label>
                </Fragment>
                <Fragment>
                  <input {...register("broker_access")} type='radio' value='self_broker' id="self_broker_access" />
                  <label className='uk-form-label radio' htmlFor="self_broker_access">Self Only</label>
                </Fragment>
              </div>
            </div>
          )}
          {
            isAgent && accessAllBrokers === 'individual' && (
              <div className="form-row" style={{ marginTop: "25px" }}>
                <div className="form-row" style={{ marginBottom: "-25px" }}>
                  <h4>Office</h4>
                </div>
                {
                  officeDataWithBrokers.map((office, key) => {
                    return (
                      <div key={key} style={{ marginTop: "23px" }} >
                        <div className="form-row" >
                          <input type="checkbox" className="uk-checkbox"
                            onChange={(event) => officeChangeHandler(event)}
                            id={`office_${office.id}`}
                            checked={allOfficeBrokerSelected && allOfficeBrokerSelected?.includes(office.id)}
                            value={office.id} />
                          <label className="uk-form-label radio" htmlFor={`office_${office.id}`}>{office.office_name}</label>
                        </div>
                        {
                          office.company_user && office.company_user.length > 0 &&
                          <>
                            <h4> Brokers</h4>
                            <div className="form-row " style={{ marginLeft: "18px", display: "flex" }}>
                              {
                                office.company_user.map((user, key) => {
                                  return (
                                    <div key={key} style={{ marginBottom: "8px" }}>
                                      <input name="broker_id" type="checkbox" data-office={office.id} onChange={(event) => brokerChangeHandler(event)} className="uk-checkbox" checked={checkBroker(user)} id={`user_${user.id}`} value={user.id} />
                                      <label className="uk-form-label radio" htmlFor={`user_${user.id}`}>{user.last_name} {user.first_name}</label>
                                    </div>
                                  )
                                })
                              }

                            </div>
                          </>
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          }
          <div className="uk-width-1-3@s uk-column-1-1" id="save-section">
            <input type="submit" className="uk-button iy-button-primary" value={"Save"} />
            <Link to={"/users"}><button className="uk-button uk-button-default" id="add-cancel">Cancel</button></Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserForm;
