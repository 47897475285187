import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { retrieveRoles, inviteUser, findOfficeWithBrokers, retrieveOffices, retrieveMlsOffices } from "../../redux/actions/users";
import { setLoadingStatus } from "../../redux/actions/loading";
import Auth from "../../utils/auth";
import RolesHelper from "../common/RolesHelper/RolesHelper";
import UserService from "../../services/UserService";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";
import StyleSwitch from "../common/buttons/StyleSwitch";
import { DatePicker } from "antd";
import { dateFormatter } from "../../utils/common";

const useStyles = makeStyles({
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  is_broker_div: {
    width: "38% !important",
  },
  show_broker_label: {
    marginRight: "9px !important",
  },
  employing_broker_input: {
    marginRight: "6px !important",
  }
});

const InviteForm = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setSuccess] = useState(false);

  const classes = useStyles();

  //This state is used for check whether user is agent oe not.
  const [isAgent, setIsAgent] = useState(false);

  //This state is used for check whether user is participant or not.
  const [isParticipant, setIsParticipant] = useState(false);

  // This state is used to show checked when all brokers is selected
  const [allOfficeBrokerSelected, setAllOfficeBrokerSelected] = useState([])

  //This variable is used to store mls office of user.
  const mlsObject = useSelector(state => state.users.mlsOffices);

  //This variable is used to store all mls offices
  const mlsOffices = mlsObject?.data?.Offices ? mlsObject.data.Offices : []

  mlsOffices.sort((a, b) => a.OfficeName < b.OfficeName ? -1 : 1)

  //This state is used to store a error message for broker attachment list.
  const [brokerAttachmentError, setBrokerAttachmentError] = useState(null);

  //This state is used to show error messsage of email Exists.
  const [isEmailExists, setIsEmailExists] = useState(false);

  //This variable is used to store offices
  const dataOffices = useSelector(state => state.users.offices);

  //This variavle is used to store offices with it's broker.
  const officeDataWithBrokers = useSelector(state => state.users.officeListWithBroker);

  //This variable is used to store a users roles.
  const dataRoles = useSelector(state => state.users.roles);

  //This state is used to store a mls office users
  const [mlsUsersList, setMlsUsersList] = useState([]);

  //To-Do: Replace company Id while beta
  // const companyId = '81b5856d-6a27-4fc6-abfc-7e45a5c3a561';
  const user = Auth.getInstance().getUserData();
  const companyId = user.company_id;
  const companyName = user.company?.company_name;

  useEffect(() => {
    dispatch(retrieveMlsOffices(companyId));
  }, [])

  // Make a schema for user invite.
  const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    mobile_phone: yup
      .string()
      .required('Phone number is required')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid'
      ),
    mls_user_id: yup.string().nullable(true),
    mls_office_id: yup.string().nullable(true),
    title: yup.string().nullable(true),
    is_broker: yup.boolean().nullable(true),
    email: yup.string('Email is required field.').email('Email is not valid').required('Valid email address is required'),
    user_role_id: !isParticipant && yup.string().required('You must choose a user role.').typeError('You must choose a user role.'),
    company_office_id: !isParticipant && yup.string().required("Please select an office"),
    access_type: !isParticipant && yup.string().required('Please select an access type'),
    broker_access: !isParticipant && yup.string().when('user_role_id', {
      is: (user_role_id) => user_role_id === dataRoles.find(role => role.name === 'Agent')?.id,
      then: yup.string().required('You must choose a access of brokers for agent.').typeError('You must choose a access of brokers for agent.'),
      otherwise: yup.string().nullable()
    }),
    participant_company_name: yup.string(),
  });

  /* Form Validation */
  const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors }, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      "is_broker": false,
      "access_type": "full",
      is_hide_drag_drop_copy: false,
      is_hide_image_text_bar: false,
      is_hide_add_signature: false,
      broker_attachment: [],
      offices_without_brokers: []
    },
    shouldFocusError: true
  });

  //On component load fetching all roles.
  useEffect(() => {
    dispatch(retrieveRoles());
    dispatch(retrieveOffices(companyId));
  }, []);

  // Use effect for new user's role.
  useEffect(() => {
    if (dataRoles !== undefined && dataRoles.length > 0) {
      //If agent role is selected then fetch all offices and brokers using company id.
      if (watch('user_role_id') === dataRoles.find(role => role.name === 'Agent')?.id) {
        //Make isAgent state true for displaying all brokers.
        setIsAgent(true);
        setIsParticipant(() => false);
        //Fetch all office data with brokers
        dispatch(findOfficeWithBrokers(companyId));
      } else if (watch('user_role_id') === dataRoles.find(role => role.name === 'Participant')?.id) {

        setIsAgent(false);
        setIsParticipant(() => true);
        dispatch(findOfficeWithBrokers(companyId));
      } else {
        // If new user is not agent then make isAgent state false
        setIsAgent(false);
        setIsParticipant(() => false);
        setValue("user_role_id", dataRoles.find(role => role.name === 'Company Admin')?.id)
      }
    }
  }, [watch('user_role_id'), watch('company_office_id')]);


  // Function to call create user api with selected data
  const handleInvite = (user) => {

    //Remove all error related state.
    setError(false);
    setMessage('');
    setBrokerAttachmentError(null)

    //Set validation message for broker_attachment list.
    if (isAgent && user.broker_access && user.broker_access == "individual" && user.broker_attachment.length === 0 && user.offices_without_brokers.length === 0) {
      setBrokerAttachmentError("Please select at least one broker or office");
      return;
    }

    // Set empty broker_attachment, offices_without_brokers list for broker_access is all
    if (isAgent && user.broker_access && ["all", "self_broker"].includes(user.broker_access)) {
      user = {
        ...user,
        broker_attachment: [],
        offices_without_brokers: []
      }
    }

    //Delete unnecessary fields from user object
    if (user.offices_without_brokers) {
      delete user.offices_without_brokers
    }

    // If newuser role is not agent then delete broker_attachment and broker_access fields.
    if (user.user_role_id != dataRoles.find(role => role.name === 'Agent')?.id) {
      user = {
        ...user,
        broker_attachment: [],
        broker_access: null
      }
    }

    //Make user's email in lower case.
    user = {
      ...user,
      email: user.email?.toLowerCase(),
    };

    // Set broker attachment fields
    if (typeof user.broker_attachment === 'string') {
      user = {
        ...user,
        broker_attachment: [user.broker_attachment]
      };
    } else if (typeof user.broker_attachment === 'boolean') {
      user = {
        ...user,
        broker_attachment: []
      };
    }

    //Show the loader
    dispatch(setLoadingStatus(true));
    if (isParticipant) {
      user = {
        first_name: user.first_name,
        last_name: user.last_name,
        mobile_phone: user.mobile_phone,
        title: user.title,
        participant_company_name: user.participant_company_name,
        email: user.email,
        user_role_id: user.user_role_id,
        user_type: "participant",
        company_id: companyId,
        company_name: companyName,
        is_invited_participant: true
      }
    }

    //Check email from database
    UserService.checkEmail({
      email: user.email, user_type: "participant",
      company_id: companyId
    }).then((res) => {

      //Check whether email is exists or not.
      if (!res.data.isExists) {
        user['is_participant_exists'] = res.data?.isParticipantExists;

        //Call api for invite User
        dispatch(inviteUser(user)).then(res => {

          //Show the success message.
          dispatch(setLoadingStatus(false));
          setMessage('Successfully sent an invite.');
          setSuccess(true);
          setTimeout(() => {
            history.push('/users');
          }, 2000);
        }).catch(err => {

          //If error occurs in api calling then show the error message.
          dispatch(setLoadingStatus(false));
          if (err.data.error && err.data.error.message) {
            setMessage(err.data.error.message);
          } else {
            console.log(err)
            setMessage(`Sorry, but we can't proceed your request right now.`);
          }
          setError(true);
        });
      } else {
        //Set emailExists flag true.
        setIsEmailExists(true);
        dispatch(setLoadingStatus(false));
      }
    }).catch((err) => {
      setIsEmailExists(true);
      dispatch(setLoadingStatus(false));
    })
  }

  // Function to handle form validation errors
  const handleError = (errors) => {
    if (Object.keys(errors)[0]) {
      setError(true);
      setMessage(errors[Object.keys(errors)[0]].message);
      return;
    }
    setError(false);
    setMessage(null);
  };

  //Store the value of broker_access field in variable
  const accessAllBrokers = useMemo(() => getValues('broker_access'), [watch('broker_access')]);

  //Store the value of broker_attachment field in variable
  const selectedBrokerAttachments = useMemo(() => getValues('broker_attachment'), [watch('broker_attachment')]);

  //Store the value of role of user and give full access to the company admin
  const isCompanyAdmin = useMemo(() => {
    const selectedRole = getValues('user_role_id');
    if (selectedRole && selectedRole == dataRoles.find(role => role.name === 'Company Admin')?.id) {
      setValue("access_type", "full")
      return true;
    }
    return false;
  }, [watch('user_role_id')]);

  //Function for set mlsUserId Value.
  const setMlsUserId = (office_id) => {
    if (office_id) {
      const mlsOffice = mlsOffices.find((office) => office.OfficeId == office_id)
      const sortedUser = (mlsOffice.Users && mlsOffice.Users.length) > 0 ? mlsOffice.Users.sort((a, b) => a.BrokerName < b.BrokerName ? -1 : 1) : []
      mlsOffice && setMlsUsersList(sortedUser);
    } else {
      setMlsUsersList([]);
    }
  }

  //Store the list of brokers in variable.
  const brokerArr = selectedBrokerAttachments?.map((broker) => {
    return broker.broker_id
  })

  const checkBroker = (user) => {
    if (selectedBrokerAttachments) {
      const brokerArr = selectedBrokerAttachments?.map((broker) => {
        return broker.broker_id
      })

      return brokerArr.includes(user.id)
    }
  }


  // Function for remove items from array
  const removeArrayElemnt = (array, item) => {
    var index = array.indexOf(item);
    array.splice(index, 1);
  }

  //This function is used to checked office when user select all brokers from broker list.
  const makeCheckedOffice = (officeId) => {

    //Get values of broker_attachment field.
    const broker_attachment = getValues("broker_attachment");

    //Make a selected broker list
    const broker_list = broker_attachment.map((broker) => {
      if (broker.broker_id) {
        return broker.broker_id
      }
    })

    // Get office object from api response of offices.
    const officeObj = officeDataWithBrokers.find((office) => office.id == officeId);

    let office_list = allOfficeBrokerSelected

    if (officeObj.company_user && officeObj.company_user.length > 0) {
      let isBroken = false;

      /**
       * Push in officelist array when user selects all brokers from same office
       * And this officeList will set to the allOfficeBrokerSelected state
       * allOfficeBrokerSelected state is used to show checked when all brokers is selected
       */
      officeObj.company_user.map((user, index, array) => {
        if (isBroken) {
          return
        }
        if (!broker_list.includes(user.id)) {
          isBroken = true
          return
        }
        if (index == (officeObj.company_user.length - 1)) {
          office_list.push(officeObj.id)
        }
      })
    }
    setAllOfficeBrokerSelected(office_list)
  }

  /**
    * Function is used to show checked office as er it's click event
    * If office is checked then we will automatically checked all it's brokers
    * If office is unckhecked then we will automatically unchecked all it's brokers
    * @param {object} event 
    */
  const officeChangeHandler = (event) => {

    //Store the office id and office variable in one variables.
    const officeId = event.target.value
    let officeBrokerArr = officeDataWithBrokers.find((officeObj) => officeObj.id === officeId);

    //If office is checked then find all it's brokers.
    if (event.target.checked) {

      officeBrokerArr.company_user.map((user) => {
        let currentBroker = getValues("broker_attachment")
        const brokersArrObj = currentBroker.filter((broker) => broker.broker_id != user.id)
        setValue("broker_attachment", brokersArrObj)
      })

      //Make an array to checked all it's brokers
      const userArr = officeBrokerArr.company_user.map((user) => {
        return {
          "broker_id": user.id,
          "office_id": officeId
        }
      })

      //Push the value of office id to show checked
      setAllOfficeBrokerSelected((state) => {
        return [...state, officeId]
      })

      //If no user found from office then
      // we have to push in `userArr` array which is used to value of broker attachment fields.
      //And store the value of officeid in `offices_without_brokers` variable.
      if (userArr.length == 0) {
        userArr.push({
          "broker_id": null,
          "office_id": officeId
        })

        const officeArrWithoutBroker = getValues("offices_without_brokers")
        const filteredArr = officeArrWithoutBroker.filter((office_id) => office_id != officeId)
        setValue("offices_without_brokers", [...filteredArr, officeId])
      }

      //Set the value of broker_attachment fields.
      userArr.map((user) => {
        const currentBroker = getValues("broker_attachment")
        setValue("broker_attachment", [...currentBroker, user])
      })

    } else {

      //Remove the officeId from `allOfficeBrokerSelected` variable.
      setAllOfficeBrokerSelected((state) => {
        var index = state.indexOf(officeId);
        state.splice(index, 1)
        return state
      })

      const officeArrWithoutBroker = getValues("offices_without_brokers")
      const filteredArr = officeArrWithoutBroker.filter((office_id) => office_id != officeId)
      setValue("offices_without_brokers", filteredArr)

      let currentBroker = getValues("broker_attachment")
      const brokersArrObj = currentBroker.filter((broker) => broker.office_id != officeId)
      setValue("broker_attachment", brokersArrObj)
    }

  }


  //Get the value of is_broker
  const is_broker_checked = useMemo(() => getValues("is_broker"), [watch('is_broker')])

  /**
    * This function is used for set value of broker and officeID in broker_attachment variable. 
    * @param {Object} event 
    */
  const brokerChangeHandler = (event) => {

    //Set the value of brokerId and officeId
    const brokerId = event.target.value
    const officeId = event.target.getAttribute("data-office")

    //If broker is checked then push the value of broker and officeId in broker_attachment field
    if (event.target.checked) {
      let currentBroker = getValues("broker_attachment")
      let obj = {
        "broker_id": brokerId,
        "office_id": officeId
      }
      setValue("broker_attachment", [...currentBroker, obj]);
      makeCheckedOffice(officeId)
    } else {

      //If broker is unchecked then remove the officeId from `allOfficeBrokerSelected`array
      if (allOfficeBrokerSelected.includes(officeId)) {
        setAllOfficeBrokerSelected((state) => {
          var index = state.indexOf(officeId);
          state.splice(index, 1)
          return state
        })
      }

      //Set the new value of broker_attachment field
      let currentBroker = getValues("broker_attachment")
      const brokersArrObj = currentBroker.filter((broker) => {
        if (broker.broker_id) {
          return broker.broker_id != brokerId
        }
      })
      setValue("broker_attachment", brokersArrObj);
    }

  }

  const handleShowAsBrokerToggle = () => {
    setValue('is_broker', !getValues('is_broker'));
    if (getValues('is_broker') == false) {
      setValue('is_deals_office_employing_broker_signs', false);
      setValue('is_listing_office_employing_broker_signs', false);
    }
  }

  //Get the value of is_employing_broker
  const is_deals_office_employing_broker_signs = useMemo(() => getValues("is_deals_office_employing_broker_signs"), [watch('is_deals_office_employing_broker_signs')]);
  const is_listing_office_employing_broker_signs = useMemo(() => getValues("is_listing_office_employing_broker_signs"), [watch('is_listing_office_employing_broker_signs')]);

  //Get the value of editor optoins hide
  const is_hide_drag_drop_copy = useMemo(() => getValues("is_hide_drag_drop_copy"), [watch('is_hide_drag_drop_copy')]);
  const is_hide_image_text_bar = useMemo(() => getValues("is_hide_image_text_bar"), [watch('is_hide_image_text_bar')]);
  const is_hide_add_signature = useMemo(() => getValues("is_hide_add_signature"), [watch('is_hide_add_signature')]);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li><a href="/users">Users</a></li>
        <li className="uk-disabled">Invite Users</li>
      </ul>

      <h1>Invite Users</h1>

      {isSuccess ? (
        <div className="uk-alert uk-alert-primary" data-uk-alert>
          <p>{message}</p>
        </div>
      ) : isError ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{message}</p>
        </div>
      ) : null}

      {
        !isError && brokerAttachmentError &&
        < div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{brokerAttachmentError}</p>
        </div>
      }

      <div className="form-container">
        <form onSubmit={handleSubmit(handleInvite, handleError)} className="uk-grid-small form" data-uk-grid id="addform" method="POST" onReset={reset}>
          <div className="form-row mb-3">
            <h4>
              Role{" "}
              <span
                data-uk-icon="icon: info"
                data-uk-toggle="target: #modal-roles-helper"
                className="info-icon"
              ></span>
            </h4>
            <RolesHelper />
            <div className="">
              {
                dataRoles.map((role, key) =>
                  role.name !== "Guest Agent" && (
                    <Fragment key={key}>
                      <input {...register("user_role_id", { required: true })} id={role.id} type="radio" value={role.id} />
                      <label htmlFor={role.id} className="uk-form-label radio">{role.name}</label>&nbsp;&nbsp;&nbsp;
                    </Fragment>
                  )
                )
              }
            </div>
          </div>
          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">First Name*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.first_name })} type="text" {...register("first_name")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Last Name*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.last_name?.message })} type="text" {...register("last_name")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Title</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.title })} type="text" {...register("title")} />
            </div>
          </div>
          <div className="form-row">
            < div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Email Address*</label>
              <input {...register("email", { required: true })} onChange={() => setIsEmailExists(false)} className={clsx("uk-input", { 'uk-form-danger': isEmailExists })} type="email" name="email" id="name" />
              {
                isEmailExists && <p style={{ color: "red" }}>Email already exists.</p>
              }
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.mobile_phone?.message })} type="hidden" required name="mobile_phone" {...register("mobile_phone")} />
              <PhoneInput
                placeholder='+'
                inputClass={classes.phone_input}
                country='us'
                containerClass={classes.phone_container}
                onChange={phone => { setValue('mobile_phone', phone, { shouldValidate: true }) }}
              />
              <p style={{ color: "red" }}>{errors.mobile_phone?.message}</p>
            </div>
            {isParticipant ? <div className="one-column">
              <label className="uk-form-label">Company Name</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.participant_company_name,
                })}
                type="text"
                {...register("participant_company_name")}
              />
            </div>
              : <></>
            }
          </div>
          <div className="form-row d-flex flex-column mb-3 mt-0">
            <label className="mb-0 pb-0 custom-switch-bnt" style={{ marginLeft: '-8px' }}>
              <StyleSwitch
                checked={is_broker_checked}
                onChange={(e) => handleShowAsBrokerToggle()}
              />Show as Broker
            </label>
            <div className="d-flex">
              {is_broker_checked &&
                <Fragment>
                  <div className="d-flex flex-column">
                    <div className='mt-1 pl-4'>
                      <input id="is_deals_office_employing_broker_signs" type="checkbox" name="is_deals_office_employing_broker_signs" checked={is_deals_office_employing_broker_signs} {...register("is_deals_office_employing_broker_signs")} />
                      <label className={`uk-form-label radio m-0 ${classes.show_broker_label}`} for="is_deals_office_employing_broker_signs">Office Employing Broker Signs Deal</label>
                    </div>
                    <div className='mt-1 pl-4'>
                      <input id="is_listing_office_employing_broker_signs" type="checkbox" name="is_listing_office_employing_broker_signs" checked={is_listing_office_employing_broker_signs} {...register("is_listing_office_employing_broker_signs")} />
                      <label className={`uk-form-label radio m-0 ${classes.show_broker_label}`} for="is_listing_office_employing_broker_signs">Office Employing Broker Signs Listing Agreement</label>
                    </div>
                  </div>
                </Fragment>
              }
              <Fragment>
                <div className={`d-flex flex-column pl-1 ${is_broker_checked && "ml-5"}`}>
                  <div className={`mt-1 ${is_broker_checked && "pl-5"}`}>
                    <input id="is_hide_drag_drop_copy" type="checkbox" name="is_hide_drag_drop_copy" checked={is_hide_drag_drop_copy} {...register("is_hide_drag_drop_copy")} />
                    <label className={`uk-form-label radio m-0`} for="is_hide_drag_drop_copy">Hide Drag&Drop/Copy</label>
                  </div>
                  <div className={`mt-1 ${is_broker_checked && "pl-5"}`}>
                    <input id="is_hide_image_text_bar" type="checkbox" name="is_hide_image_text_bar" checked={is_hide_image_text_bar} {...register("is_hide_image_text_bar")} />
                    <label className={`uk-form-label radio m-0`} for="is_hide_image_text_bar">Hide Image/Text Bar</label>
                  </div>
                </div>
                <div className={`mt-1 pl-5 ${is_broker_checked && "ml-5"}`}>
                  <input id="is_hide_add_signature" type="checkbox" name="is_hide_add_signature" checked={is_hide_add_signature} {...register("is_hide_add_signature")} />
                  <label className={`uk-form-label radio m-0`} for="is_hide_add_signature">Hide Add Signatures</label>
                </div>
              </Fragment>
            </div>
          </div>
          {!isParticipant && <> <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">License #</label>
              <input
                className={clsx("uk-input", { 'uk-form-danger': errors.license_code?.message })}
                type="text"
                {...register("license_code")}
              // value={brokerProfile.license_code ? brokerProfile.license_code : ""}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">License Expiration Date</label>
              <Controller
                control={control}
                name="expiration_date"
                render={({ field }) => (
                  <DatePicker
                    className={clsx("uk-input", { 'uk-form-danger': errors.expiration_date?.message })}
                    style={{ cursor: "pointer", color: "#000" }}
                    format={'MM/DD/YYYY'}
                    onChange={(date, dateString) => {
                      field.onChange(dateString ? dateFormatter(dateString) : null);
                    }}
                  />
                )}
              />
            </div>
            {
              !(isCompanyAdmin || isParticipant) &&
              <div className="uk-padding-small" style={{ marginTop: "30px" }}>
                <input type='radio' id="full_access_type" {...register("access_type")} value='full' />
                <label className='uk-form-label radio' htmlFor="full_access_type">Full Access</label>
                <input type='radio' {...register("access_type")} value='edit_only' id="edit_access_type" />
                <label className='uk-form-label radio' htmlFor="edit_access_type">Edit Only</label>
              </div>
            }
          </div>
            <div className="form-row">
              {
                mlsOffices && mlsOffices.length > 0 &&
                <div className="one-column uk-padding-small">
                  {/* <h4>Office</h4> */}
                  <label className="uk-form-label" htmlFor="mls_office_id">
                    MLS Office
                  </label>
                  <select className="uk-select" {...register("mls_office_id")}
                    defaultValue=""
                    onChange={(event) => {
                      setValue("mls_office_id", event.target.value)
                      setMlsUserId(event.target.value)
                      // setAddressFields(event.target.value);
                    }}
                  >
                    <option value="">Select Office</option>
                    {mlsOffices.map((mlsOffice) => {
                      return (
                        <option value={mlsOffice.OfficeId} key={mlsOffice.OfficeId}>
                          {`${mlsOffice.OfficeName}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              }
              {
                mlsUsersList &&
                <div className="one-column uk-padding-small">
                  {/* <h4>User</h4> */}
                  <label className="uk-form-label" htmlFor="mls_user_id">
                    User MLS ID
                  </label>
                  <select className="uk-select" {...register("mls_user_id")}
                    defaultValue=""
                    onChange={(event) => {
                      setValue("mls_user_id", event.target.value)
                      // setAddressFields(event.target.value);
                    }}
                  >
                    <option value="">Select Broker</option>
                    {mlsUsersList.map((user) => {
                      return (
                        <option value={user.BrokerID} key={user.BrokerID}>
                          {`${user.BrokerName} | ${user.BrokerID}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              }
            </div>
            <div className="form-row" >
              <div className="one-column" style={{
                marginBottom: "25px",
              }}>
                <label className="uk-form-label">
                  Office*
                </label>
                <select className="uk-select" {...register("company_office_id")}
                  defaultValue=""
                  onChange={(event) => {
                    setValue("company_office_id", event.target.value)
                  }}
                >
                  <option selected value="">Select Office</option>
                  {dataOffices.map((office) => {
                    return (
                      <option value={office.id} key={office.id}>
                        {`${office.office_name}`}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div></>}

          {/* {isParticipant && <div className="form-row">
            <div className="one-column">
              <label className="uk-form-label">Company Name</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.participant_company_name,
                })}
                type="text"
                {...register("participant_company_name")}
              />
            </div>
          </div>} */}

          {/* <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Email Address*</label>
              <input {...register("email", { required: true })} onChange={() => setIsEmailExists(false)} className={clsx("uk-input", { 'uk-form-danger': isEmailExists })} type="email" name="email" id="name" />
              {
                isEmailExists && <p style={{ color: "red" }}>Email already exists.</p>
              }
            </div>
            {
              !isCompanyAdmin &&
              <div className="uk-padding-small" style={{ marginTop: "30px" }}>
                <input type='radio' id="full_access_type" {...register("access_type")} value='full' />
                <label className='uk-form-label radio' htmlFor="full_access_type">Full Access</label>
                <input type='radio' {...register("access_type")} value='edit_only' id="edit_access_type" />
                <label className='uk-form-label radio' htmlFor="edit_access_type">Edit Only</label>
              </div>
            }
          </div> */}

          {isAgent && (
            <div className='form-row'>
              <h4>Should this Agent have access to all Brokers</h4>
              <div className='three-column'>
                <Fragment>
                  <input {...register("broker_access")} type='radio' id="all_broker_access" value='all' />
                  <label className='uk-form-label radio' htmlFor="all_broker_access">All</label>
                </Fragment>
                <Fragment>
                  <input {...register("broker_access")} type='radio' value='individual' id="select_broker_access" />
                  <label className='uk-form-label radio' htmlFor="select_broker_access">Select Brokers Individually</label>
                </Fragment>
                <Fragment>
                  <input {...register("broker_access")} type='radio' value='self_broker' id="self_broker_access" />
                  <label className='uk-form-label radio' htmlFor="self_broker_access">Self Only</label>
                </Fragment>
              </div>
            </div>
          )}
          {
            isAgent && accessAllBrokers === 'individual' && (
              <div className="form-row" style={{ marginTop: "25px" }}>
                <div className="form-row" style={{ marginBottom: "-25px" }}>
                  <h4>Office</h4>
                </div>
                {
                  officeDataWithBrokers.map((office, key) => {
                    return (
                      <div key={key} style={{ marginTop: "23px" }} >
                        <div className="form-row" >
                          <input type="checkbox" className="uk-checkbox"
                            onChange={(event) => officeChangeHandler(event)}
                            id={`office_${office.id}`}
                            name="office_id"
                            checked={allOfficeBrokerSelected.includes(office.id)}
                            value={office.id} />
                          <label className="uk-form-label radio" htmlFor={`office_${office.id}`}>{office.office_name}</label>
                        </div>
                        {
                          office.company_user && office.company_user.length > 0 &&
                          <>
                            <h4> Brokers</h4>
                            <div className="form-row" style={{ marginLeft: "18px", display: "flex" }}>
                              {
                                office.company_user.map((user, key) => {
                                  return (
                                    <div key={key} style={{ marginBottom: "8px" }}>
                                      <input name="broker_id" type="checkbox" data-office={office.id} onChange={(event) => brokerChangeHandler(event)} className="uk-checkbox" checked={checkBroker(user)} id={`user_${user.id}`} value={user.id} />
                                      <label className="uk-form-label radio" htmlFor={`user_${user.id}`}>{user.last_name} {user.first_name}</label>
                                    </div>
                                  )
                                })
                              }

                            </div>
                          </>
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          }
          <div className="uk-column-1-1  uk-margin-small" id="save-section" style={{ marginTop: "23px" }}>
            <button type="submit" className="uk-button iy-button-primary" id="add-deal-save">Invite</button>
            <Link to={"/users"}><button className="uk-button uk-button-default" id="add-cancel">Cancel</button></Link>
          </div>
        </form>
      </div>
    </div >
  )
}

export default InviteForm;